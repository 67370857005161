import store from '../store';
import { getImageProcess } from '../http';

export const auth = async () => {
    const authToken = sessionStorage.getItem('token');
    if(authToken){
        await store.dispatch("setUserCurrent", {tokenAuth: authToken});
    }
    if( store.state.userCurrent && !sessionStorage.getItem(`modalPoint${store.state.userCurrent.id}`)){
        sessionStorage.setItem(`modalPoint${store.state.userCurrent.id}`, 1); 
    }
}
  
export const getDateBrazil = () => {
    const currentDate = new Date();
    const offset = currentDate.getTimezoneOffset();
    const brazilOffset = 3 * 60 + (offset === 180 ? 60 : 0);
    const brazilTime = new Date(currentDate.getTime() - (offset * 60 * 1000) + (brazilOffset * 60 * 1000));
    const dia = brazilTime.getDate();
    const mes = brazilTime.getMonth() + 1;
    const ano = brazilTime.getFullYear();
    return `${ano}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia}`;
}

export const convertDate = (data) => {
    const dataTZ = new Date(data);
    const dia = String(dataTZ.getDate()).padStart(2, "0");
    const mes = String(dataTZ.getMonth() + 1).padStart(2, "0"); // Mês é baseado em zero
    const ano = dataTZ.getFullYear();
    const horas = String(dataTZ.getHours()).padStart(2, "0");
    const minutos = String(dataTZ.getMinutes()).padStart(2, "0");
    const segundos = String(dataTZ.getSeconds()).padStart(2, "0");

    return `${dia}/${mes}/${ano} - ${horas}:${minutos}:${segundos}`;
}

export const getTimeToDate = (date, timezone) => {
    if (!date) return "-";
    
    try {
        const timeString = new Intl.DateTimeFormat('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: timezone
        }).format(new Date(date));
        
        return `${timeString}`;
    } catch {
        return "-";
    }
};

  
export const formatFone = (fone) => {
    return `(${fone?.slice(0, 2)}) ${fone?.slice(2, 7)}-${fone?.slice(7)}`;
}

export const sortProcess = (arr=[]) => {
    if(Array.isArray(arr)){
        return arr?.sort((a, b) => {
        return b.id - a.id;
        });
    }   
  };

export const getImgBase64 = async (process) => {
    const img64 = await getImageProcess(process);
    if(img64){

        const uint8ArrayData = new Uint8Array(img64?.file_data?.data);
        const originalString = new TextDecoder().decode(uint8ArrayData);
        return originalString;
  
    }
   return;
}

export const getMaterialInList = (materials, item, lonlyName=false) => {
    const materialFound = materials?.find( mat => mat?.id === item?.material || 0)

    if(!materialFound?.name){
        return '-'
    }

    if(lonlyName){
        return materialFound?.name
    }

    return `${materialFound?.name || '-'}${materialFound?.name?' ':''}${item?.amount > 1 ? (item?.amount  + 'X ' || '-') : ''}${item?.height || '-'}${item?.height?'X':''}${item?.width || '-'}`; 

}

export const getNameObject = (obj, id) => {
    try{
        const objFound = obj?.find( item => item?.id === id);

        if(objFound?.length === 0){
            return '-';
        }

        return objFound?.name;
    } catch(err){
        return err;
    }
}

export const typeUser = (type) => {
    switch (type) {
      case "D":
        return "Designer";
      case "I":
        return "Impressor";
      case "C":
        return "Conferente";
      case "A":
        return "Administrador";
      case "AA":
        return "Administrador";
    }
};

export const gerarUUID = (num) => {
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });

    return uuid + `-ft01c${num}`;
}

export const getUUID = (uuid) => {
    let uuidFind = uuid.split('-');
    return (uuidFind[uuidFind.length - 1])?.replace('ft01c', '');
}

export const ordeName = (arr) => {
    arr.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    return arr;
}

export const formatDate = (date) => {
    const dateArr = date.split("-")
    return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
  }