<template>
    <div class="container mt-5" v-if="$_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D'">    

        <div class="card-header bg-transparent border-0 ms-2">
            <div>
              <img
                src="../../assets/logo.png"
                alt="Logomarca"
                class="img-fluid mx-auto d-block mt-4"
                width="120"
              />
            </div>
            <h4 class="text-center my-3">
                <font-awesome-icon icon="users" />
                Clientes Registrados
            </h4>
            <hr />
        </div>
    
      <div v-if="!filteredClients.length" class="alert alert-light">
        <h5 class="custom-mt text-center mb-5">
          <font-awesome-icon icon="circle-exclamation" />
          Atualmente, não há registros disponiveís.
        </h5>
      </div>
  
      <button
        v-if="filteredClients.length"
        class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb-2 me-3"
        @click="opeBlockFilter"
      >
        <font-awesome-icon
          icon="arrow-up-wide-short"
          class="me-2"
          v-if="openFilter"
        />
        <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
        Filtrar
      </button>
      <!-- Filtros de pesquisa -->
      <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
        
        <div class="col-md-3">
          <label for="filtroNome" class="form-label filtros"
            >Nome:</label
          >
          <input
            id="filtroNome"
            class="form-control"
            placeholder="Digite o cliente"
            v-model="nameClient"
          />
        </div>
        
        <div class="col-md-3">
          <label for="filtroNome" class="form-label filtros"
            >Telefone:</label
          >
          <input
            id="filtroNome"
            class="form-control"
            placeholder="(00) 00000-0000"
            v-model="foneClient"
            v-mask="'(##) #####-####'"
          />
        </div>
        
      </div>
  
      <table
        className=" text-center table table-dark table-striped mb-5 custom-table"
        v-if="filteredClients.length > 0"
      >
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">fone</th>
            <th scope="col">Criação</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in filteredClients" :key="item.id">
            <tr
              class="align-items-center clickable"
              @click="viewClient(item.id)"
            >
              <td>{{ item.name }}</td>
              <td>{{ formatFone(item.fone) }}</td>
              <td>{{ convertDate(item.createdAt) }}</td>
            </tr>
          </template>
        </tbody>
      </table>

      <div>
        <h6
          v-if="
            !filteredClients.length &&
            (nameProcess || statusProcess || foneProcess || stageProcess)
          "
          class="text-secondary custom-mt text-center"
        >
          Desculpe, não encontramos resultados correspondentes à sua pesquisa.
          <br />
          Tente usar termos diferentes ou ajuste os filtros para obter melhores
          resultados.
        </h6>
      </div>

    </div>

  </template>
  
  <script>
  import { computed, onBeforeMount, ref } from "vue";
  import store from "../../store";
  import router from "@/router";
  import { mask } from "vue-the-mask";
  import { formatFone, convertDate } from "../../utils";
  // import { getReponseClients } from "../../socket";
  export default {
    name: "ListClient",
    props: {},
    directives: { mask },
    setup() {

      const clientList = computed(() => store.state.clientsList );

      const nameClient = ref("");
      const foneClient = ref("");
  
      const filteredClients = computed(() => {
  
        const _nameClient = nameClient.value.toLowerCase();
        const _foneClient = foneClient.value.replace().replace(/[()\- ]/g, "");

        return clientList.value
          ?.filter(
            (client) => 
                client.name?.toLowerCase().includes(_nameClient) &&
                client.fone?.includes(_foneClient)
          )
      });
  
      const viewClient = (id) => {
        router.push("/view-client/" + id);
      };
  
      const openFilter = ref(false);
      const opeBlockFilter = () => {
        openFilter.value = openFilter.value ? false : true;
      };
  
      onBeforeMount(async () => {
        await store.dispatch("setClientListCurrent");
        // getReponseClients()
      });
  
      return {
        opeBlockFilter,
        openFilter,
        filteredClients,
        nameClient,
        foneClient,
        convertDate,
        viewClient,
        formatFone
      };
    },
  };
  </script>
  <style scoped>
  .custom-mt {
    margin-top: 70px;
  }
  
  .custom-table {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 80px;
  }
  
  .barra-pesquisa {
    margin-top: 100px;
  }
  
  .filtros {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
  }
  
  .clickable {
    cursor: pointer;
    background-color: aquamarine;
  }
  </style>
  