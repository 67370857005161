<template>

   <!-- Modal -->
   <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered overlay-background2">
      <div class="modal-content overlay-background">
        <div class="m-2 mt-2 d-flex align-items-center justify-content-end flex-wrap">
          <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mb-5 ">
          <img height="auto" width="auto"  :src="selectedImg" class="text-white enlarged-image" alt="ops.. sem imagem" >
        </div>
      </div>
    </div>
  </div>
  
  <div class="container mt-4 mb-4" v-if="!$_store.state.openEprocess">
    <button class="btn btn-outline-danger col-2 mt-4 mb-3" @click="backHome">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-bar-left"
        viewBox="0 0 16 16"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-bar-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
          />
        </svg>
      </svg>
      Voltar
    </button>
    <div class="card mx-auto border-0 shadow-lg"  style="margin-bottom: 80px;">
      <div class="text-center card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120"
          />
        </div>
        <h4 class="mt-4 clickable">
          <font-awesome-icon
            data-bs-toggle="modal" 
            data-bs-target="#staticBackdrop"
            icon="eye" 
            class="me-1 clickable"
            @click="renderImg(process.id)"
          /> 
          Informações do serviço: <b>N° {{ +processId + 1000 }}</b> 
          <span>
            <div v-if="!client?.id" class="spinner-border ms-3 text-secondary" role="status" style="width: 1.5rem; height: 1.5rem;"></div>
            <font-awesome-icon icon="copy" class="ms-3" @click="copyToken()" v-else/>
          </span>
          <small v-if="timeCopy" 
            class="badge badge-sm rounded-pill ms-2" 
            style="font-size: 12px;background-color:#08cf66 ;"
          >
            copiado!
          </small>
        </h4>
        <hr />
      </div>

      <div class="card-body" v-if="!openConfirm">
        <div class="container">
          <div class="row">
            <div id="div1" class="col-6">
              <div class="mb-3 ps-2 pe-2">
                <label
                  class="form-label badge bg-dark text-wrap"
                  style="width: 8.3rem"
                  ><b>Responsáveis:</b></label
                >
                <div class="card">
                  <span style="font-size: 14px; margin-top: 5px;"
                    ><b>Designer: </b>{{ getNameUser(process.designer) }}</span
                  >
                  <span style="font-size: 14px"
                    ><b>Impressor: </b
                    >{{
                      process.printer ? getNameUser(process.printer) : "-"
                    }}</span
                  >
                  <span style="font-size: 14px"
                    ><b>Conferente: </b
                    >{{
                      process.lecturer ? getNameUser(process.lecturer) : "-"
                    }}</span>
                  <span style="font-size: 14px; margin-bottom: 5px;"><b>Acabamento: </b> {{  process.finishing || "-" }}</span>
                </div>
              </div>

              <div class="mb-3 ps-2 pe-2">
                <label
                  class="form-label badge bg-dark text-wrap"
                  style="width: 12.5rem"
                  ><b>Informação do Cliente:</b></label
                >
                <div class="card">
                  <span style="font-size: 14px"
                    ><b>Nome: </b>{{ client.name }}</span
                  >
                  <span style="font-size: 14px"
                    ><b>Telefone: </b>{{ user.type_user === "A" ? formatFone(client.fone) : '-' }}</span
                  >
                </div>
              </div>

              <div class="mb-3 ps-2 pe-2">
                <label
                  class="form-label badge bg-dark text-wrap"
                  style="width: 9.4rem"
                  ><b>Data do Serviço:</b></label
                >
                <div class="card">
                  <span style="font-size: 14px"
                    ><b>Criado em: </b
                    >{{ convertDate(process.createdAt) }}</span
                  >
                  <span style="font-size: 14px"
                    ><b>Ultima atualização em: </b
                    >{{ convertDate(process.updatedAt) }}</span
                  >
                </div>
              </div>
            </div>

            <div id="div2" class="col-6">
              <div class="ps-2 pe-2">

                <div class="container">
                  <div class="row">

                    <div id="div3" class="col-6">
                      <label
                        class="form-label badge bg-dark text-wrap"
                        style="width: 15em"
                        ><b>Descrição do Material:</b></label
                      >

                      <div class="mb-4">
                        <span style="font-size: 15px">{{ getMaterial(process) }}</span>
                        <br />
                        <span style="font-size: 14px"
                          ><b>Status:</b> {{ typeStatus(process.status) }}</span
                        ><br />
                        <span style="font-size: 14px"
                          ><b>Etapa atual:</b> {{ stateFormat(process) }}</span
                        ><br />
                        <span style="font-size: 14px"
                          ><b>Máquina usada: </b
                          >{{
                            machine ? machine.name : "-"
                          }}</span
                        >
                      </div>
                    </div>

                    <div id="div4" class="col-6" v-if="delivery?.id">
                      <label
                        class="form-label badge bg-dark text-wrap"
                        style="width: 15em"
                        ><b>Informação da entrega:</b></label
                      >
                      <div class="mb-4">
                        <span style="font-size: 14px"
                          ><b>Responsável: </b> {{ getNameUser(delivery?.delivery) }}</span
                        ><br />
                        <span style="font-size: 14px">
                          <b>Tipo da entrega:</b> {{ typeDelivery(delivery?.type_delivery) }}</span>
                        <br />
                        <span style="font-size: 14px">
                          <b>Observação da entrega: </b><div class="card" v-if="delivery.observer"><span class="m-2">{{ delivery.observer }}</span></div><span v-else>-</span>
                       </span>
                        <br />
                        <span style="font-size: 14px">
                          <b>Data de entrega: </b><br>{{ convertDate(delivery.createdAt) }}
                       </span>
                      </div>
                    </div>

                  </div>
                </div> 

                
              </div>

              <span class="fw-normal">Observações:</span>
              <div class="card">
                <div class="card-body" v-if="comments.length > 0">
                  <template v-for="comment in comments" :key="comment.id">
                    <span>
                      <b>{{ getNameUser(comment.user) }}</b> :
                      {{ comment.comment }}
                    </span>
                    <br />
                  </template>
                </div>
                <div v-else class="card-body">
                  <p style="font-size: 14px" class="text-secondary">
                    Nenhuma observação no momento!
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-end" v-if="$_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D'">
                <button
                  class="btn btn-sm btn-primary ms-2 mt-3"
                  @click="openEdit"
                >
                  <font-awesome-icon icon="pencil" /> Editar
                </button>
                <button
                  v-if="$_store.state.userCurrent.type_user === 'A'"
                  class="btn btn-sm btn-danger ms-2 mt-3"
                  @click="openConfirmDelete"
                >
                  <font-awesome-icon icon="trash-can" /> Apagar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- alerta -->
      <div
        class="card text-center mt-2 m-2"
        v-if="openConfirm"
        style="height: 180px"
      >
        <span class="mt-2">Tem certeza que deseja apagar este registro?</span>
        <hr />

        <div class="mt-4">
          <button class="btn btn-danger btn-dm me-2" @click="openConfirmDelete">
            Não
          </button>

          <button
            type="button"
            class="btn btn-primary btn-dm me-2"
            @click="deleteRegister"
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  </div>
  <EditProcess v-if="$_store.state.openEprocess" />
</template>

<script>
import { computed, onBeforeMount, ref } from "vue";
import store from "../../store";
import { useRoute } from "vue-router";
import router from "@/router";
import {
  getProcess,
  getClient,
  getCommentsProcess,
  deleteProcess,
  getMaterialFind,
  getMachineFind,
  getDeliveryFind
} from "../../http";
import { convertDate, formatFone, getImgBase64, getMaterialInList, gerarUUID } from "../../utils";
import EditProcess from "./EditProcess.vue";
import { sendEventProcess } from "../../socket";

export default {
  name: "ViewProcess",
  props: {},
  setup() {
    const route = useRoute();
    const processId = ref(route.params.id || null);
    const user = computed(() => store.state.userCurrent);
    const users = computed(() => store.state.users);
    const material = ref({});
    const machine = ref({});
    const client = ref({});
    const process = ref({});
    const comments = ref([]);
    const delivery = ref([]);

    const getNameUser = (id) => {
      const foundUser = users.value.find((user) => user.id == id);
      return foundUser?.name || "";
    };

    const stateFormat = ({ stage, finished }) => {
      if (finished == 1) {
        return "Cocluído";
      }

      switch (stage) {
        case "D":
          return "Entrada";
        case "I":
          return "Conferente";
        case "C":
          return "Concluído";
        case "P":
          return "Produção";
        case "A":
          return "Acabamento";
      }
    };

    const typeStatus = (type) => {
      switch (type) {
        case "U":
          return "Urgente";
        case "N":
          return "Normal";
      }
    };

    const typeDelivery = (type) => {
      switch (type) {
        case "U":
          return "Uber";
        case "M":
          return "Motoboy";
        case "R":
          return "Rodoviária";
        case "C":
          return "Cliente";
      }
    }

    const backHome = () => {
      router.push("/");
    };

    const openConfirm = ref(false);

    const openConfirmDelete = () => {
      openConfirm.value = openConfirm.value ? false : true;
    };

    const deleteRegister = async () => {
      await deleteProcess(process.value.id)
        .then(() => {
          process.value.deleted = true;
          sendEventProcess("graphic", process.value);

          const directPath = `${"/"}`;
          router.push(directPath);
      });
    };

    const openEdit = () => {
      store.commit("setOpenEditProcess");
    };

    const selectedImg = ref('');
    const renderImg = async (id) => {
      selectedImg.value = '';
      selectedImg.value = await getImgBase64(id);
    }
    const getMaterial = (item) => {
      return getMaterialInList([material.value], item);
    }
    const timeCopy = ref(false);
    const copyToken = () => {
      const url = window.location;
      let baseUrl = url.protocol + "//" + url.hostname + (url.port ? ":" + url.port : "");
      let data = `${baseUrl}/view-orders/${gerarUUID(client.value.id)}`
      const input = document.createElement('input');
      input.value = data;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);

      timeCopy.value = true;
      setTimeout(() => {
        timeCopy.value = false;
      }, 3000);

    };

    onBeforeMount(async () => {
      await getProcess(processId.value).then(async (data) => {
        process.value = data;
        if(process.value){
          material.value = await getMaterialFind(process.value?.material);
          machine.value = await getMachineFind(process.value?.machine);
          delivery.value = await getDeliveryFind(process.value?.delivery);
        }
      });

      await store.dispatch("getUsers");

      await getClient(process.value.client).then((data) => {
        client.value = data;
      });

      await getCommentsProcess(processId.value).then((data) => {
        comments.value = data;
      });
    });

    return {
      typeDelivery,
      delivery,
      machine,
      getMaterial,
      selectedImg,
      renderImg,
      formatFone,
      stateFormat,
      convertDate,
      user,
      processId,
      backHome,
      process,
      getNameUser,
      users,
      typeStatus,
      client,
      comments,
      openConfirm,
      openConfirmDelete,
      deleteRegister,
      openEdit,
      timeCopy,
      copyToken
    };
  },
  components: {
    EditProcess,
  },
};
</script>
<style scoped>
div {
  font-family: Arial, Helvetica, sans-serif;
}

label {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.9rem;
}

h4 {
  font-family: Arial, Helvetica, sans-serif;
}

.overlay-background {
  background-color: rgba(0, 0, 0, 0.5); 
}
.overlay-background2 {
  background-color: rgba(0, 0, 0, 0); 
  
}

.clickable {
  cursor: pointer;
}

.clickable :hover {
  opacity:  0.75;
}

/* CSS para ajustar a largura e altura da imagem dentro do modal */
.modal-dialog {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.enlarged-image {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}
</style>
