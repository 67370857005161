<template>

    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered overlay-background2">
        <div class="modal-content overlay-background">
          <div class="m-2 mt-2 d-flex align-items-center justify-content-end flex-wrap">
            <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body mb-5 ">
            <img v-if="selectedImgDesign" height="400" width="400"  :src="selectedImgDesign" class="text-white" alt="ops.. sem imagem">
          </div>
        </div>
      </div>
    </div>
  
    <div class="container">
  
      <div v-if="!$_store.state.existProcess" class="alert alert-light mt-3">
        <h5 class="custom-mt text-center mb-5" v-show="!loading">
          <font-awesome-icon icon="circle-exclamation" />
          Atualmente, não há registros criado por você.
        </h5>
      </div>
  
      <button
        v-if="$_store.state.existProcess"
        class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb-2 me-3"
        @click="opeBlockFilter"
      >
        <font-awesome-icon
          icon="arrow-up-wide-short"
          class="me-2"
          v-if="openFilter"
        />
        <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
        Filtrar
      </button>
      <!-- Filtros de pesquisa -->
      <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
        <div class="col-md-2">
          <label for="filtroNome" class="form-label filtros"
            >Nome do cliente:</label
          >
          <input
            id="filtroNome"
            class="form-control"
            placeholder="Digite o cliente"
            v-model="nameProcess"
          />
        </div>
        <div class="col-md-2" v-if="user?.type_user === 'A'">
          <label for="filtroTelefone" class="form-label filtros">Telefone:</label>
          <input
            id="filtroTelefone"
            class="form-control"
            placeholder="(00) 00000-0000"
            v-mask="'(##) #####-####'"
            v-model="foneProcess"
          />
        </div>
        <div class="col-md-2">
          <label for="filtroData" class="form-label filtros">Data:</label>
          <input
            id="filtroData"
            type="date"
            class="form-control"
            value="2024-01-01"
            v-model="dateProcess"
          />
        </div>
        <div class="col-md-2">
          <label for="filtroStatus" class="form-label filtros">Status:</label>
          <select id="filtroStatus" class="form-select" v-model="statusProcess">
            <option value="" selected>Todos</option>
            <option value="N">Normal</option>
            <option value="U">Urgente</option>
          </select>
        </div>
  
        <div class="col-md-2">
          <label for="filtroEtapa" class="form-label filtros">Etapa:</label>
          <select id="filtroEtapa" class="form-select" v-model="stageProcess">
            <option value="" selected>Todos</option>
            <option value="D">Entrada</option>
            <!-- <option value="I">Conferente</option> -->
            <option value="P">Produção</option>
            <option value="A">Acabamento</option>
            <option value="C">Concluído</option>
          </select>
        </div>
      </div>
      <LoadingObjet class="text-center" v-show="loading" />
      <div class="table-responsive mb-5" v-if="!loading">
        <table
          className="table table-dark table-striped custom-table"
          v-if="processList?.length > 0"
        >
          <thead>
            <tr>
              <th scope="col">N°</th>
              <th scope="col ml-3">Preview</th>
              <th scope="col">{{ user.type_user === 'A' ? 'Cliente/telefone' : 'Cliente'}}</th>
              <th scope="col">Material</th>
              <th scope="col">Status</th>
              <th scope="col">Etapa</th>
              <th scope="col">Criação</th>
              <th scope="col">Utima Atualização</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in sortProcess(filteredProcess)" :key="item.id">
              <tr class="align-items-center">
  
                <td class="col-auto clickable" @click="viewProcesss(item.id)">{{ item.id + 1000 }}</td>
                <td class="col clickable">
                  <font-awesome-icon 
                    @click="renderImgDesign(item.id)" 
                    data-bs-toggle="modal" 
                    data-bs-target="#staticBackdrop2"
                    icon="eye" 
                    class="text-white"
                  />
                </td>
                <td class="col clickable" @click="viewProcesss(item.id)">{{ getClientName(item.client) }}</td>
                <td class="col clickable" @click="viewProcesss(item.id)">{{ getMaterial(item) }}</td>
                <td class="col clickable" @click="viewProcesss(item.id)">{{ typeStatus(item.status) }}</td>
                <td class="col clickable" @click="viewProcesss(item.id)">{{ stateFormat(item) }}</td>
                <td class="col clickable" @click="viewProcesss(item.id)">{{ convertDate(item.createdAt) }}</td>
                <td class="col clickable" @click="viewProcesss(item.id)">{{ convertDate(item.updatedAt) }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  
    <div v-show="!loading">
      <h6
        v-if="!filteredProcess?.length && (nameProcess || statusProcess || stageProcess || dateProcess != dateAt)"
        class="text-secondary custom-mt text-center"
      >
      Desculpe, não encontramos resultados correspondentes à sua pesquisa.
      <br />
      Tente usar termos diferentes ou ajuste os filtros para obter melhores
      resultados.
      </h6>
      <h6
      v-if="$_store.state.existProcess && !filteredProcess?.length && dateProcess == dateAt && !(nameProcess || statusProcess || foneProcess || stageProcess)"
        class="text-secondary custom-mt text-center"
      >
        Desculpe, não encontramos resultados correspondentes à essa data {{ formatLabelDate(dateProcess) }}.
        <br />
        Tente ajustar os filtros para obter melhores resultados.
      </h6>
    </div>
  </template>
  
  <script>
  import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
  import store from "../../store";
  import router from "@/router";
  import { mask } from "vue-the-mask";
  import { getDateBrazil, convertDate, getImgBase64, getMaterialInList } from "../../utils";
  import { getReponseProcess, getReponseStageProcess } from "../../socket";
  import LoadingObjet from '@/components/LoadingObjet';
  
  export default {
    name: "ListProcess",
    props: {},
    directives: { mask },
    setup() {
      const processList = computed(() =>
        store.state.processList?.slice().sort((a, b) => b.id - a.id)
      );
      const clients = computed(() => store.state.clientsList);
      const user = computed(() => store.state.userCurrent);
      const materials = computed(() => store.state.materialListView);
      const loading = ref(false);
      const sortProcess = (arr = []) => {
        return arr?.sort((a, b) => {
          if (a.finished === 1 && b.finished !== 1) {
            return 1;
          }
          if (a.finished !== 1 && b.finished === 1) {
            return -1;
          }
  
          return b.id - a.id;
        });
      };
  
      const mergeClientData = (processes, clients) => {
        return processes?.map((item) => {
          const foundClient = clients.find((c) => c.id === item.client);
          const mergedData = { ...item, ...foundClient };
  
          mergedData.id = item.id;
          mergedData.createdAt = item.createdAt;
          mergedData.updatedAt = item.updatedAt;
  
          return mergedData;
        });
      };
  
      const dataProcesses = computed(() => {
        return mergeClientData(processList.value, clients.value);
      });
  
      const stateFormat = ({ stage, finished }) => {
        if (finished == 1) {
          return "Concluído";
        }
  
        switch (stage) {
          case "D":
            return "Entrada";
          case "I":
            return "Conferente";
          case "C":
            return "Concluído";
          case "P":
            return "Produção";
          case "A":
            return "Acabamento";
        }
      };
  
      const typeStatus = (type) => {
        switch (type) {
          case "U":
            return "Urgente";
          case "N":
            return "Normal";
        }
      };
  
      const getClientName = (clientId) => {
        const foundClient = clients.value.find(
          (client) => client.id === clientId
        );
  
        const formatFone = (fone) => {
          return `(${fone.slice(0, 2)}) ${fone.slice(2, 7)}-${fone.slice(7)}`;
        };
        return foundClient
          ? foundClient.name + (user?.value?.type_user === 'A' ? " - " + formatFone(foundClient.fone) : '') : "-";
      };

      const dateAt = ref(getDateBrazil());
      const nameProcess = ref("");
      const dateProcess = ref(dateAt.value);
      const foneProcess = ref("");
      const statusProcess = ref("");
      const stageProcess = ref("");
  
      const formatLabelDate = (date) => {
        const arrDate = date.split("-");
        return date ? arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0] : "";
      };
  
      const filteredProcess = computed(() => {
        const nameClient = nameProcess.value.toLowerCase();
        const foneClient = foneProcess.value.replace().replace(/[()\- ]/g, "");
        const _statusProcess = statusProcess.value;
        const _stageProcess = stageProcess.value;
        const _dateProcess = formatLabelDate(dateProcess.value);
  
        return dataProcesses.value
          ?.filter(
            (process) =>
              // process.id?.toString().includes(term) ||
              process.name?.toLowerCase().includes(nameClient) &&
              process.fone?.includes(foneClient) &&
              convertDate(process.createdAt).includes(_dateProcess) &&
              process.status?.includes(_statusProcess) &&
              process.stage?.includes(_stageProcess)
          )
      });
  
      const viewProcesss = (id) => {
        router.push("/view-process/" + id);
      };
  
      const openFilter = ref(false);
      const opeBlockFilter = () => {
        openFilter.value = openFilter.value ? false : true;
      };
  
      const selectedImgDesign = ref('');
      const renderImgDesign = async (id) => {
        selectedImgDesign.value = '';
        selectedImgDesign.value = await getImgBase64(id);
      }

      const getMaterial = (item) => {
        return getMaterialInList(materials.value, item);
      }
      
      watch( dateProcess , async (newVal, oldVal) => {

        if (oldVal !== newVal) {
          loading.value = true;
          const listProcesses = await store.dispatch("setProcessListCurrent", newVal);

          if(!listProcesses?.message){
            const processIds = listProcesses.map((item)=> item.material) || [];   
            await store.dispatch('getMaterialListView', processIds);
          }

        }
        loading.value = false;
      });
        
      onBeforeMount(async () => {
        loading.value = true;
        await store.dispatch("getExistProcess", true);

        const listProcesses = await store.dispatch("setProcessListCurrentDesigner", getDateBrazil());
        
        if(!listProcesses?.message){
          const processIds = listProcesses.map((item)=> item.material) || [];   
          await store.dispatch('getMaterialListView', processIds);
        }

        await store.dispatch("setClientListCurrent");
        loading.value = false;
      });
  
      onMounted(() => {
        getReponseProcess();
        getReponseStageProcess();
      })
  
      return {
        dateAt,
        getMaterial,
        user,
        selectedImgDesign,
        renderImgDesign,
        opeBlockFilter,
        openFilter,
        sortProcess,
        filteredProcess,
        nameProcess,
        dateProcess,
        foneProcess,
        statusProcess,
        processList,
        convertDate,
        typeStatus,
        stateFormat,
        clients,
        getClientName,
        dataProcesses,
        viewProcesss,
        stageProcess,
        formatLabelDate,
      };
    },
    components: {
      LoadingObjet,
    }
  };
  </script>
  <style scoped>
  .custom-mt {
    margin-top: 70px;
  }
  
  .custom-table {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 80px;
  }
  
  .barra-pesquisa {
    margin-top: 100px;
  }
  
  .filtros {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .string{
    z-index: 1000;
  }
  .overlay-background {
    background-color: rgba(0, 0, 0, 0.5); 
  }
  .overlay-background2 {
    background-color: rgba(0, 0, 0, 0); 
    
  }
  
  </style>
