<template>
  <div id="app" class="container-fluid bg mt-4 font" style="width:800px; opacity: 0.9;">
    
    <div class="row" :style="`margin-left: 0px;height: ${heightMonitorCalc(monitorHeight )}px !important;`">
      <!-- Lista de Chat -->
      <div class="col-3 d-flex flex-column border list-user overflow-custom" style="padding-right: 0px; border-top-left-radius: 10px;border-bottom-left-radius: 10px;">
        <ul class="list-group list-item" v-for="item in rooms" :key="item.id">
          <li
            class="list-group-item item" 
            @click="selectedRoom(item.id)"
            :style="item?.selected ? 'background-color:#2A2A2A; color: #fff' : '' " 
          >
            <span @click="selectedRoom(item.id)" style="display: inline-block; width: calc(100% - 100px);" class="fw-bold fs-6">{{item.name}}</span>
            <small @click="selectedRoom(item.id)" 
              class="fw-light" 
              style="display: inline-block; width: 100px; font-size: 13px;" 
              :style="`margin-left: -${formatTimestampList(item.lastUpdated)?.length == 5 ? 129 : 163}px;`">
                {{formatTimestampList(item.lastUpdated)}}
              </small><br>
            <small @click="selectedRoom(item.id)" style="display: inline-block; width: calc(100% - 100px);" class="mt-2 fw-light">{{truncateMessage(item.lastMessage)}}</small>
            <div  @click="selectedRoom(item.id)" v-if="item.countMessage > 0"
              style="display: inline-block; width: 100px; margin-left: -129px;">
              <span @click="selectedRoom(item.id)" class="badge rounded text-bg-danger">
                {{item.countMessage}}
              </span>
            </div>

          </li>
        </ul>
      </div>

      <!-- Área de Mensagens -->
      <div 
        class="col-9 box border d-flex flex-column" 
        style="padding-right: 0px;border-top-right-radius: 10px;border-bottom-right-radius: 10px;" 
        :style="`height: ${heightMonitorCalc(monitorHeight)}px !important;`"
      >

        <div 
          ref="messageBox" 
          id="box-message" 
          class="messages overflow-custom border border-3 border-seccondary" 
          :style="'overflow-y:auto; height: 100%;border-top-right-radius: 10px;' + (!currentRoom?.id ? 'border-bottom-right-radius: 10px;' : '')"
        >
          <LoadingObjet class="text-center my-5" v-show="loading" />
          <div 
            class="message" 
            v-for="msg in messages" :key="msg.id" 
            :class="{ 'my-message': msg.userId === currentUserId, 'other-message': msg.userId !== currentUserId }"
          >
            <div class="message-content" v-for="line in splitMessage(msg.content)" :key="line">{{ line }}</div>
            <small style="font-size: 12px; text-align: right; display: block">{{ formatTimestamp(msg.timestamp)}}</small> <!-- Exibe o timestamp -->
          </div>
        </div>
        <!-- Área de digitação -->
        <div class="input-group digitacao" v-if="roomSelected">
          <input 
              class="form-control border border-2 border-dark" 
              placeholder="Digite sua mensagem..." 
              v-model="message" 
              @keydown.enter="sendMessage"
              style="padding: 12px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"/>
          <div class="input-group-append">
            <button 
              class="btn btn-dark border-1 border-dark" 
              @click="sendMessage" 
              style="padding: 12px;border-top-right-radius: 0px" 

            >
              Enviar <font-awesome-icon icon="paper-plane" />
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createMessage, setMessageView } from '@/http';
import { getReponseChat, sendEventChat, setChannel, closeChannel, sendEventNotificationMessage } from '@/socket';
import store from '@/store';
import { computed, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import LoadingObjet from '@/components/LoadingObjet';
export default {
  setup() {
    
    //   { id: 1, name: 'Marcos', countMessage: 4, lastMessage: 'olá', lastUpdated: '2024-03-07T12:00:00Z' },
    const rooms = computed( () => store.state.rooms);
    const messages = computed( () => store.state.messagesCurrent || []);
    // { id: 1, sender: 'Marcos', content: 'Olá,como vai?', userId: 'user456', timestamp: new Date() }

    const heightMonitorCalc = () => {
      let height = window.innerHeight;

      if(height <= 600){
        return height - 150
      }

      if(height <= 700){
        return height - 180
      }

      if(height <= 800){
        return height - 150
      }

      if(height <= 1000){
        return height - 150
      }

      if(height >= 1100){
        return height - 150
      }

    };

    const message = ref('');
    const roomSelected = ref(null);
    const currentUserId = ref(store.state.userCurrent.id);
    const currentRoom = ref({});
    const loading = ref(false);

    const sendMessage = async () => {  
      if(!message.value) return;
      
      await createMessage({content: message.value, userId: currentUserId.value, roomId:roomSelected.value})
      .then( (data) => {
        if(data.id){
          messages.value.push({
            id: data.id,
            content: data.content,
            userId: data.userId,
            timestamp: new Date(data.createdAt) 
          });
          store.dispatch('upadatedInfoRoom', data);
          sendEventChat(currentRoom.value?.channel, data);
          sendEventChat('graphic', {newMessage: true, messageId: data.id, message: data, roomId:roomSelected.value});
          const room = rooms.value?.find(room => room.id == roomSelected.value);

          if(room){
            const userOutherId = room?.members?.filter(valor => valor !== currentUserId.value)[0];
            sendEventNotificationMessage('graphic', {userId: userOutherId});
          }
          
        }
      });
      message.value = '';
      messageBox.value.scrollTop = messageBox.value?.scrollHeight;
    }
    
    const selectedRoom = (id) => {
      const newRoom = [];
      for (const room of rooms.value){
        if (room.id == id) {
          room.selected = true;
          newRoom.push(room);
          roomSelected.value = room.id;
        }else{
          room.selected = false;
          newRoom.push(room);
        }
      }

      setTimeout(() => {
          const element = document.getElementById('box-message');
          element.scrollTop = element?.scrollHeight;
      }, 10);
    }

    const isToday = (date) => {
          const today = new Date();
          return date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear();
    }

    const formatTimestamp = (timestamp) => { 
      return timestamp ? ( isToday(new Date(timestamp)) ? extractHourMinute(new Date(timestamp)) : new Date(timestamp).toLocaleString()) : '';
    }
    const formatTimestampList = (timestamp) => { 
      return timestamp ? ( isToday(new Date(timestamp)) ? extractHourMinute(new Date(timestamp)) : new Date(timestamp).toLocaleString()).slice(0,10) : '';
    }

    const splitMessage = (content) => {
      if (content?.length > 0) {
        const lines = [];
        const maxLineLength = 45;
        let line = '';

        for (const word of content?.split(' ')) {
          if (word.length > maxLineLength) {
            // Quebra a palavra longa em partes menores
            let startIndex = 0;
            while (startIndex < word.length) {
              lines.push(word.slice(startIndex, startIndex + maxLineLength));
              startIndex += maxLineLength;
            }
          } else if ((line + word).length > maxLineLength) {
            lines.push(line.trim());
            line = word + ' ';
          } else {
            line += word + ' ';
          }
        }

        if (line.trim().length > 0) {
          lines.push(line.trim());
        }

        return lines;
      }
    }


    const extractHourMinute = (dateTimeString) =>{
      if(!dateTimeString) return ''
      const date = new Date(dateTimeString);
      const hour = date.getHours();
      const minute = date.getMinutes();
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    }

    const monitorHeight = ref(window.innerHeight);

    const updateMonitorHeight = () => {
      monitorHeight.value = window.innerHeight;
    };

    const viewMessage = async (message) => {
      let viewsExist = message?.view;
      if(!viewsExist?.includes(currentUserId.value)){
        viewsExist.push(currentUserId.value);
        await setMessageView(message.id, {view: JSON.stringify(viewsExist)})
          .then( (data) => {
            if(data.status){
              store.commit('updateViewMessage', {roomId: message.roomId, messageId: message.id, view: viewsExist})
            }
          });
      }
      
    }
    const transfomationMessages = async () => {
      let messagesMod = [];
      if(messages.value?.length > 0){
        for(let message of messages.value){
          message.timestamp = message.createdAt;
          messagesMod.push(message);
          await viewMessage(message);
        }
      }
      return messagesMod
    }

    const messageBox = ref(null);

    watch(roomSelected, async (newValue, oldValue) => {
      loading.value = true;
      if(oldValue !== newValue){
        currentRoom.value = rooms.value?.find(room => room.id == roomSelected.value);
        if(!newValue) return;
        await store.dispatch('setMessageChat', newValue);
        setChannel(currentRoom.value?.channel);
        store.commit('setRoomChannelSelected', currentRoom.value?.channel);
        messages.value = await transfomationMessages();
      }
      loading.value = false;
    });

    watch(currentRoom, (newValue, oldValue) => {
      if(newValue != oldValue){
        closeChannel(oldValue?.channel);
      }
    })

    onBeforeMount(async () => {
      await store.dispatch('getRooms');
      await store.dispatch('getInfoRooms');

      getReponseChat();
      messageBox.value = document.getElementById('box-message');

    });
    
    onMounted(() => {
      window.addEventListener('resize', updateMonitorHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateMonitorHeight);
      store.commit('clearMessagesCurrent');
      closeChannel(currentRoom.value?.channel);  
      message.value =  '';
      roomSelected.value = null;
      currentRoom.value =  {};

      const newRoom = [];
      for (const room of rooms.value){
        room.selected =  false;
        newRoom.push(room);
      }
      rooms.value = newRoom;
    });

    function truncateMessage(message) {
      if (message && message.length > 20) {
        return message.slice(0, 20) + '...';
      } else if (message) {
        return message;
      } else {
        return '';
      }
    }

    return {
      messageBox,
      heightMonitorCalc,
      monitorHeight ,
      selectedRoom,
      extractHourMinute,
      rooms,
      message,
      messages,
      sendMessage,
      currentUserId,
      formatTimestamp,
      splitMessage,
      roomSelected,
      loading,
      currentRoom,
      truncateMessage,
      formatTimestampList,
    };
},
components: {
    LoadingObjet,
}
};
</script>

<style scoped>
.font {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
.messages {
  padding: 10px;
  background-color: #fff;
  margin-bottom: px;
  overflow: auto; 
}

.message {
  margin-bottom: 5px;
  padding: 4px;
  border-radius: 5px;
  clear: both;
}

.my-message {
  background-color: #2A2A2A;
  color: #fff;
  float: right;
  text-align: center;
  padding: 8px;
  box-shadow: 0 0 12px 0.5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.other-message {
  background-color: #ffffff;
  float: left;
  text-align: center;
  padding: 8px;
  box-shadow: 0 0 22px 0.5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}


.input-group {
  align-items: center;
}

.input-group-append {
  display: flex;
  align-items: center;
}

.input-group-append button {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.box {
  padding-left: 0px !important;
}

.list-user{
  height: 100%;
  overflow-y: auto;
  padding-left: 0px !important;
}

.list-item{
  width: 400px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.item {
  padding: 10px;
  cursor: pointer;
  margin-right: 1px;
}

.item:hover{
  background-color: #2A2A2A;
  color: #fff;
}

.itemSelect{
  background-color: #2A2A2A;
  color: #fff;
}

.message-content {
  text-align: left; 
}

.overflow-custom::-webkit-scrollbar {
width: 4px; 
height: 4px;
}

.overflow-custom::-webkit-scrollbar-thumb {
background-color: #2A2A2A; 
border-radius: 4px; 
}

.overflow-custom::-webkit-scrollbar-track {
background-color: #2A2A2A; 
}

.list-group-item:last-child {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.list-group-item {
    transition: transform 0.3s ease-out; /* Adiciona uma transição suave */
  }

.list-group-item:hover {
    transform: scale(1.02); /* Aumenta ligeiramente o tamanho do item ao passar o mouse sobre ele */
  }
</style>