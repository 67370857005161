<template>

    <div class="modal fade" 
        id="exampleModalToggle" 
        aria-hidden="true" 
        aria-labelledby="exampleModalToggleLabel" 
        tabindex="-1"
        style="max-height: 600px; margin-top: 40px;"
    >
        <div :class="'modal-dialog modal-dialog-centered ' + ($_store.state.typeEventMachine.type === 'H' ? 'modal-xl' : '')">
          <div class="modal-content">
            <div class="modal-header">
              <h5 
                class="modal-title" 
                id="exampleModalToggleLabel">
                {{titleModal($_store.state.typeEventMachine.type,
                             $_store.state.typeEventMachine.machine, 
                             $_store.state.typeEventMachine.nameMachine)}}
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <EditDeleteMachine v-if="$_store.state.typeEventMachine.type === 'E' || $_store.state.typeEventMachine.type === 'D'"/>
              <MachineCreate v-if="$_store.state.typeEventMachine.type === 'A'"/>
              <listHistoryProcessMachine v-if="$_store.state.typeEventMachine.type === 'H'"/>
            </div>
          </div>
        </div>
    </div>
    
    <div class="container mt-5" >    
        <div class="card-header bg-transparent border-0 ms-2">
            <div>
                <img
                    src="../../assets/logo.png"
                    alt="Logomarca"
                    class="img-fluid mx-auto d-block mt-4"
                    width="120"
                />
            </div>
            <h4 class="text-center my-3">
              <font-awesome-icon icon="print" />
                Maquinas Registradas
            </h4>
            <hr />
        </div>
        
        <div v-if="machines?.length === 0" class="alert alert-light text-center" role="alert">
            <h5 class="custom-mt text-center mb-5" >
                <font-awesome-icon icon="circle-exclamation" />
                Atualmente, não há maquina disponíveis.
            </h5>
        </div>
        
        <div class="">
          <button
            class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb me-3"
            @click="opeBlockFilter"
            v-if="machines?.length > 0"
          >
              <font-awesome-icon
              icon="arrow-up-wide-short"
              class="me-2"
              v-if="openFilter"
              />
              <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
              Filtrar
          </button>

          <div class="d-flex align-items-center mb-1">
            <router-link to="/printer" class="btn btn-outline-danger btn-sm me-2">
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </svg>
              Ir para Impressão
            </router-link>
            <a
              class="custom-link col-lg-auto col-sm-auto col-md-auto"
              @click="setOpenAdd"
              data-bs-toggle="modal" 
              href="#exampleModalToggle" 
              role="button"
              v-if="$_store.state.userCurrent.type_user.includes('A')"
            >
              + Cadastre uma nova maquina
            </a>
          </div>          
          
        </div>  

          <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
            <div class="col-md-3">
                <label for="filtroNomeMachine" class="form-label filtros">Nome da machine:</label>
                <input
                    id="filtroNomeMachine"
                    class="form-control"
                    placeholder="Digite o machine"
                    v-model="nameMachine"
                />
            </div>
          </div>
  
          <table
               v-if="machines?.length > 0"
              class="text-center table table-dark table-striped mb-5 custom-table"
          >
              <thead>
                  <tr>
                      <th>Código do Máquina</th>
                      <th>Nome do Máquina</th>
                      <th>Data de Criação</th>
                      <th v-if="$_store.state.userCurrent.type_user === 'A'">Editar</th>
                      <th v-if="$_store.state.userCurrent.type_user === 'A'">Remover</th>
                      <th>histórico</th>
                  </tr>
              </thead>
              <tbody>

                  <template v-for="item in ordeName(filteredMachines)" :key="item.id">
                      <tr class="align-items-center">
                            <td>{{ '00' + item.id }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ convertDate(item.createdAt) }}</td>
                            <td class="clickable text-primary" v-if="$_store.state.userCurrent.type_user === 'A'">
                                <font-awesome-icon 
                                    icon="pencil-alt"
                                    data-bs-toggle="modal" 
                                    href="#exampleModalToggle" 
                                    role="button"
                                    @click="setOpenEdit(item)"
                                />
                            </td>
                            <td class="clickable text-danger" v-if="$_store.state.userCurrent.type_user === 'A'">
                                <font-awesome-icon
                                    icon="trash-can" 
                                    data-bs-toggle="modal" 
                                    href="#exampleModalToggle"
                                    role="button" 
                                    @click="setOpenRemove(item.id)"
                                />
                            </td>
                            <td class="clickable">
                                <font-awesome-icon
                                    icon="clock-rotate-left"
                                    data-bs-toggle="modal" 
                                    href="#exampleModalToggle"
                                    role="button" 
                                    @click="setOpenhistory(item)"
                                />
                            </td>
                      </tr>
                  </template>
              </tbody>
          </table>
  
          <div>
              <h6 class="text-secondary custom-mt text-center"
                  v-if="!filteredMachines.length && nameMachine"
              >
                  Desculpe, não encontramos resultados correspondentes à sua pesquisa.
                  <br />
                  Tente usar termos diferentes ou ajustar os filtros para obter melhores resultados.
              </h6>
          </div>
    </div>
  </template>
  
  
<script>
import { computed, onBeforeMount, ref } from 'vue';
import store from "../../store";
import { convertDate, getDateBrazil } from "../../utils";
import EditDeleteMachine from './EditDeleteMachine';
import MachineCreate from "./CreateMachine.vue";
import listHistoryProcessMachine from  './listHistoryProcessMachine.vue';

export default {
    name: 'ListMachine',
    setup(){
      const ordeName = (arr) => {
          arr.sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();

              if (nameA < nameB) {
                  return -1;
              }
              if (nameA > nameB) {
                  return 1;
              }
              return 0;
          });

          return arr;
      }
      const machines = computed(() => store.state.machineList);
      const nameMachine = ref('');
  
      const filteredMachines = computed(() => {
        const _nameMachine = nameMachine.value.toLowerCase();
  
        return machines.value?.filter(
          (machine) =>
            machine.name?.toLowerCase().includes(_nameMachine)
        );
      });

      const setOpenAdd = () => {
        store.commit('setTypeEventMachine', {type: 'A'});
      }

      const setOpenRemove = (id) => {
        store.commit('setTypeEventMachine', {type: 'D', machine: id});
      }

      const setOpenEdit = ({id, name}) => {
        store.commit('setTypeEventMachine', {type: 'E', machine: id, nameMachine: name});
      }

      const setOpenhistory = async ({id, name}) => {
        const listProcesses = await store.dispatch("setMachineListHistory", {date: getDateBrazil(), machine: id} );
  
        if(!listProcesses?.message){
          const processIds = listProcesses?.map((item)=> item.material) || [];   
          await store.dispatch('getMaterialListView', processIds);
        }

        store.commit('setTypeEventMachine', {type: 'H', machine: id, nameMachine: name});

      }

      const openFilter = ref(false);
      const opeBlockFilter = () => {
        openFilter.value = openFilter.value ? false : true;
      };
      
      const titleModal = (type, id=0, name='') => {
        switch(type){
          case 'A':
            return 'Cadastrar Máquina';
          case 'E':
            return 'Editar Máquina #' + id;
          case 'D': 
            return 'Remover Máquina  #' + id;
          case 'H': 
            return 'Histórico da Máquina  # ' + name;
        }
      }
      onBeforeMount(async () =>{
          await store.dispatch('setMachineListCurrent');
      });
  
      return {
        ordeName,
        titleModal,
        setOpenAdd,
        setOpenRemove,
        setOpenEdit,
        machines,
        opeBlockFilter,
        openFilter,
        filteredMachines,
        convertDate,
        setOpenhistory,
        nameMachine
      }
    },
    components: {
        EditDeleteMachine,
        MachineCreate,
        listHistoryProcessMachine,
    }
  }
  </script>
  <style scoped>
  .custom-mt {
    margin-top: 70px;
  }
  
  .custom-table {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 80px;
  }
  
  .barra-pesquisa {
    margin-top: 100px;
  }
  
  .filtros {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .custom-link {
    cursor: pointer;
    font-size: 15px;
    color: gray;
    font-weight: bold;
    text-decoration: none; /* Remove sublinhado */
    transition: color 0.3s, background-color 0.3s; /* Transição de cor e fundo */
  }
  
  .custom-link:hover {
    color: #020202; 
    font-weight: bold;
    border-bottom: #020202 solid 2px; 
    border-radius: 8px;
  }

  .clickable {
    cursor: pointer;
  }
  .clickable :hover {
    width: 23px;
    height: 23px;
    color: #fff;
  }
  </style>