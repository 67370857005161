<template>
  <div>
    <div class="card-header bg-transparent border-0 ms-2 mt-5">
      <div>
        <img
          src="../../assets/logo.png"
          alt="Logomarca"
          class="img-fluid mx-auto d-block mt-4"
          width="120"
        />
      </div>
      <h4 class="text-center my-3">
        <font-awesome-icon icon="clipboard-list" />
        Serviços Registrados
      </h4>
      <hr />
    </div>
    <ListProcess />
  </div>
</template>

<script>
import ListProcess from "../processes/ListProcess.vue";
export default {
  name: "HomeListGeral",
  props: {},
  setup() {},
  components: {
    ListProcess,
  },
};
</script>
<style scoped></style>
