import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { setChannel } from "./socket";
import { auth } from './utils';
import VueTheMask from 'vue-the-mask';
import PrimeVue from 'primevue/config';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import './style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "primevue/resources/primevue.min.css";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCircleXmark,
    faMagnifyingGlass,
    faArrowDownWideShort,
    faArrowUpWideShort,
    faTrashCan,
    faCircleExclamation,
    faKey,
    faUsers,
    faClipboardList,
    faPencil,
    faGears,
    faPaintBrush,
    faEye,
    faEyeSlash,
    faCopy,
    faPrint,
    faClockRotateLeft,
    faPaperPlane,
    faTruckFast,
    faTableList,
    faFolderOpen,
    faArrowTrendUp,
    faHandPointer,
    faCommentDots,
    faDatabase
}
    from '@fortawesome/free-solid-svg-icons';

library.add(
    faCircleXmark,
    faMagnifyingGlass,
    faArrowDownWideShort,
    faArrowUpWideShort,
    faTrashCan,
    faCircleExclamation,
    faKey,
    faUsers,
    faClipboardList,
    faPencil,
    faGears,
    faPaintBrush,
    faEye,
    faEyeSlash,
    faCopy,
    faPrint,
    faClockRotateLeft,
    faPaperPlane,
    faTruckFast,
    faTableList,
    faFolderOpen,
    faArrowTrendUp,
    faHandPointer,
    faCommentDots,
    faDatabase
);

router.beforeEach(async (to, from, next) => {
    if (!store.getters?.getUser?.id) {
        await auth();
    }
    next()
});

setChannel('graphic');
const app = createApp(App);

app.config.globalProperties.$_store = store;

app.use(router);
app.use(VueTheMask);
app.use(PrimeVue);
app.component('InputGroup', InputGroup);
app.component('P-Button', Button);
app.component('InputText', InputText);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
