<template>

  <div class="modal fade " id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1" >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content" v-if="!$_store.state.typeEventStock.type">
          <div class="modal-header">

            <h5 class="modal-title" :class="addOrRemove == 1 ? 'text-success' : 'text-danger'" id="exampleModalToggleLabel"> 
              <b>{{addOrRemove == 1 ? "+ Entrada" : "- Saída"}} de Material</b>
            </h5>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <!-- Medidas do material -->
            <div class="mb-2 ps-2 pe-2 container-fluid">
              <label for="largura" class="form-label">Material <small class="text-secondary">({{metric == 'M' ? 'Largura X Altura':'Quantidade'}})</small>:</label>
              <a
                class="custom-link mb-2 fs-6 me-2"
                @click="chageMetric"
              >
              Mudar para {{metric == 'Q' ? 'medida':'quantidade'}}
              </a>
              <div class="d-flex" v-if="metric == 'M'">
                <div class="row col me-1">
                  <div class="col-12">
                    <input
                      v-model="stockPayload.newWidth"
                      type="number"
                      class="form-control mb-2"
                      id="largura"
                      placeholder="Largura (metros)"
                      style=""
                    />
                    <p class="p-error-label"></p>
                  </div>
                </div>
                <div class="row col">
                  <div class="col-12">
                    <input
                      v-model="stockPayload.newHeight"
                      type="number"
                      class="form-control"
                      id="altura"
                      placeholder="Altura (metros)"
                      style=""
                    />
                    <p class="p-error-label"></p>
                  </div>
                </div>
              </div>

              <div class="d-flex" v-else>
                <div class="row col me-1">
                  <div class="col-12">
                    <input
                      v-model="stockPayload.newQuantity"
                      type="number"
                      class="form-control mb-2"
                      id="Quantidade"
                      placeholder="quantidade"
                      style=""
                    />
                    <p class="p-error-label"></p>
                  </div>
                </div>
              </div>

            </div>
            
            <!-- Material -->
            <div class="mb-2 ps-2 pe-2 container-fluid">
              <InputSelect  v-if="materials" :objetcSelect="materials" :setTitle="'Material'" @objectId="materialValue"/>
            </div>

            <!-- Butões -->
            <div class="d-flex justify-content-center mb-2 mt-4">
                <button 
                    class="btn btn-outline-dark me-1 col-6"
                    @click="sendStock"
                    :disabled="!stockPayload.material"
                >
                  Concluir
                </button>
                <button
                  class="btn btn-outline-danger ms-1 col-6" 
                  id="closeButtonAdd"
                  data-bs-dismiss="modal" 
                  aria-label="Close"
                >
                  Cancelar
                </button>
            </div> 

          </div>
        </div>

        <div class="modal-content" style="width: 1000px" v-else >

          <div class="modal-header">

            <h5 class="modal-title" id="exampleModalToggleLabel"> 
              <b>{{$_store.state.typeEventStock.type == "D" ? "Remover" : "Historico"}} Stock #{{$_store.state.typeEventStock.stock}}</b>
            </h5>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <div v-if="$_store.state.typeEventStock.type == 'D'">
              <div class="d-flex justify-content-center mb-2">
                <button 
                    class="btn btn-dark me-1 col-6"
                    @click="sendDeleteStock"
                >
                Confirmar
                </button>
                <button 
                    id="closeButton" 
                    class="btn btn-outline-danger ms-1 col-6" 
                    data-bs-dismiss="modal" 
                    aria-label="Close">
                    Cancelar
                </button>
            </div> 
            </div>

            <div v-else>
              <ListHistoryStock/>
            </div>

          </div>

        </div>

      </div>

  </div>
  
  <div class="container mt-5" >    
      <div class="card-header bg-transparent border-0 ms-2">
          <div>
              <img
                  src="../../assets/logo.png"
                  alt="Logomarca"
                  class="img-fluid mx-auto d-block mt-4"
                  width="120"
              />
          </div>
          <h4 class="text-center my-3">
            <font-awesome-icon icon="fa-arrow-trend-up" />
              Materiais em Estoque
          </h4>
          <hr />
          <p class="text-center alert alert-danger" v-if="timeStock">{{msgOfStock}}</p>
      </div>
      
      <div v-if="materials?.length === 0" class="alert alert-light text-center" role="alert">
          <h5 class="custom-mt text-center mb-5" >
              <font-awesome-icon icon="circle-exclamation" />
              Atualmente, não há materiais disponíveis.
          </h5>
      </div>
      
      <div class="">
        <button
          class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb me-3"
          @click="opeBlockFilter"
          v-if="materials?.length > 0"
        >
            <font-awesome-icon
            icon="arrow-up-wide-short"
            class="me-2"
            v-if="openFilter"
            />
            <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
            Filtrar
        </button>
      <div style="display: flex; justify-content:space-around;" class="mt-2">
        <a
          class="custom-link d-flex justify-content-start mb-2 fs-6"
          @click="setOpen('E')"
          data-bs-toggle="modal" 
          href="#exampleModalToggle" 
          role="button"
        >
          + Entrada de material
        </a>
        <a
          class="custom-link d-flex justify-content-start mb-2 fs-6"
          @click="setOpen('S')"
          data-bs-toggle="modal" 
          href="#exampleModalToggle" 
          role="button"
        >
         - Saída de material
        </a>
    </div>
      </div>  
      <!-- Filtros de pesquisa -->
        <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
          <div class="col-md-3">
              <label for="filtroNomeMaterial" class="form-label filtros">Nome do material:</label>
              <input
                  id="filtroNomeMaterial"
                  class="form-control"
                  placeholder="Digite o material"
                  v-model="nameMaterial"
              />
          </div>
          <!-- Adicione mais filtros aqui, se necessário -->
        </div>

        <table
             v-if="materials?.length > 0"
            class="text-center table table-dark table-striped mb-5 custom-table"
        >
            <thead>
                <tr>
                    <th>Código do Material</th>
                    <th>Qtd/Medidas</th>
                    <th>Nome do Material</th>
                    <th>Data de Criação</th>
                    <th>Última atualização</th>
                    <th>Remover</th>
                    <th>Histórico</th>
                </tr>
            </thead>
            <tbody>
                  <template v-for="item in ordeName(filteredStocks || [])" :key="item.id">
                      <tr class="align-items-center">
                            <td>{{ '00' + item.material }}</td>
                            <td>{{ item.typeStock == "M" ? (item.width + " X " + item.height + "m²") : item.quantity }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ convertDate(item.createdAt) }}</td>
                            <td>{{ convertDate(item.updatedAt) }}</td>
                            <td class="clickable text-danger" v-if="$_store.state.userCurrent.type_user === 'A'">
                                <font-awesome-icon
                                    icon="trash-can" 
                                    data-bs-toggle="modal" 
                                    href="#exampleModalToggle"
                                    role="button" 
                                    @click="setOpenRemove(item.id)"
                                />
                            </td>
                            <td class="clickable">
                                <font-awesome-icon
                                    icon="clock-rotate-left"
                                    data-bs-toggle="modal" 
                                    href="#exampleModalToggle"
                                    role="button" 
                                    @click="setOpenhistory(item)"
                                />
                            </td>
                      </tr>
                  </template>
              </tbody>
        </table>

        <div>
            <h6 class="text-secondary custom-mt text-center"
                v-if="nameMaterial"
            >
                Desculpe, não encontramos resultados correspondentes à sua pesquisa.
                <br />
                Tente usar termos diferentes ou ajustar os filtros para obter melhores resultados.
            </h6>
        </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue';
import store from "../../store";
import { convertDate } from "../../utils";
import InputSelect from "@/components/InputSelect.vue";
import { getReponseMaterial } from '@/socket';
import { createStock, deleteStock, editStock } from '@/http';
import { getNameObject, ordeName } from '@/utils';
import ListHistoryStock from './ListHistoryStock.vue';

export default {
  name: 'ListStock',
  setup(){

    const materials = computed(() => store.state.materialList);
    const stocks = computed(() => store.state.stockList)

    const stockPayload = ref({});

    const addOrRemove = ref(1); // 1 - add | 0 - remove 
    const metric = ref('M'); // M - medida | Q - quantidade

    const timeStock = ref(false);
    const msgOfStock = ref('');

    const chageMetric = () => {
      metric.value = metric.value === 'M' ? 'Q' : 'M';
    }

    const materialValue = (event) => {
      stockPayload.value.material = event;
    }

    const sendStock = async () => {
      try{

        stockPayload.value.name = getNameObject(materials.value, stockPayload.value.material);
        stockPayload.value.height = stockPayload.value.newHeight;
        stockPayload.value.width = stockPayload.value.newWidth;
        stockPayload.value.quantity = stockPayload.value.newQuantity;
        stockPayload.value.typeStock = metric.value;
// payload.height =  req.body.type == "E" ? (req.body.height + req.body.newHeight) : (req.body.height - req.body.newHeight);

        const requestStock = await  createStock(stockPayload.value);

        if(requestStock?.exist){

          stockPayload.value.newHeight = stockPayload.value.height;
          stockPayload.value.newWidth =  stockPayload.value.width;
          stockPayload.value.newQuatity = stockPayload.value.quantity;

          stockPayload.value.height = requestStock?.stock.height;
          stockPayload.value.width = requestStock?.stock.width;
          stockPayload.value.quantity = requestStock?.stock.quantity;

          if(addOrRemove.value == 1){
            stockPayload.value.type = "E";
          }else{
            stockPayload.value.type = "S";
          }

          if(requestStock?.stock.typeStock == stockPayload.value.typeStock){

            if( 
                stockPayload.value.type == "S" && 
                (stockPayload.value.newHeight > stockPayload.value.height || 
                stockPayload.value.newWidth > stockPayload.value.width ||
                stockPayload.value.newQuantity > stockPayload.value.quantity)
              ){
                msgOfStock.value = "Você não pode remover mais do quê existe desse estoque!";
                timeStock.value = true;
              setTimeout(() => {
                timeStock.value = false;
              }, 5000);
              document.getElementById("closeButtonAdd").click();
              fieldsOriginal();
              return;
            }

            await addOrRemoveStockExisting(requestStock?.stock.id, stockPayload.value);
          }else{
            msgOfStock.value = "Tipo de estoque para esse material está errado!";
            timeStock.value = true;
            setTimeout(() => {
              timeStock.value = false;
            }, 5000);
            document.getElementById("closeButtonAdd").click();
            fieldsOriginal();
            return;
          }

        }

        fieldsOriginal();
        await store.dispatch('setStockListCurrent')
        document.getElementById("closeButtonAdd").click();

      }catch(err){
        return err;
      }

    }

    const addOrRemoveStockExisting = async (stock, payload) => {
      await editStock(stock, payload);
    }

    const fieldsOriginal = () => {
      stockPayload.value = {
        material: 0,
        name: "",
        width : 0,
        height: 0,
        newWidth : 0,
        newHeight: 0,
        quantity: 0,
        newQuantity: 0,
        type: "E",
        typeStock: "M"
      }
    }

    const setOpen = (type) => {
      store.commit('setTypeEventStock', {type: null, stock: null});
      addOrRemove.value = type == "E" ? 1 : 0;
    }

    const openFilter = ref(false);
    const opeBlockFilter = () => {
      openFilter.value = openFilter.value ? false : true;
    };
    
    const nameMaterial = ref('');
    const filteredStocks = computed(() => {
        const _nameMaterial = nameMaterial.value.toLowerCase();
  
        return stocks.value?.filter(
          (stock) =>
            stock.name?.toLowerCase().includes(_nameMaterial)
        );
    });

    const setOpenRemove = (id) => {
      store.commit('setTypeEventStock', {type: 'D', stock: id});
    }

    const setOpenhistory = async ({id, name}) => {
      store.commit('setTypeEventStock', {type: 'H', stock: id, nameStock: name});
    }

    const sendDeleteStock = async () => {
      await deleteStock(store.state.typeEventStock.stock)
        .then(async () => {
          store.commit('setTypeEventStock', {type: null, stock: null});
          await store.dispatch('setStockListCurrent');
          document.getElementById("closeButtonAdd").click();
      });
      
    }

    onBeforeMount(async () =>{
        fieldsOriginal()
        await store.dispatch('setMaterialListCurrent');
        await store.dispatch('setStockListCurrent');
        getReponseMaterial();
    });

    return { 
      sendDeleteStock,
      setOpenRemove,
      setOpenhistory,
      nameMaterial,
      openFilter,
      opeBlockFilter,
      materials,
      materialValue,
      sendStock,
      stockPayload,
      addOrRemove,
      setOpen,
      stocks,
      filteredStocks,
      convertDate,
      ordeName,
      metric,
      chageMetric,
      msgOfStock,
      timeStock
    }
  },
  components:{
    InputSelect,
    ListHistoryStock
  }
}
</script>
<style scoped>
.custom-mt {
  margin-top: 70px;
}

.custom-table {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 80px;
}

.barra-pesquisa {
  margin-top: 100px;
}

.filtros {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.8rem;
}

.custom-link {
  cursor: pointer;
  font-size: 15px;
  color: gray;
  font-weight: bold;
  text-decoration: none; /* Remove sublinhado */
  transition: color 0.3s, background-color 0.3s; /* Transição de cor e fundo */
}

.custom-link:hover {
  color: #020202; 
  font-weight: bold;
  border-bottom: #020202 solid 2px; 
  border-radius: 8px;
}

.clickable {
  cursor: pointer;
}
.clickable :hover {
  width: 23px;
  height: 23px;
  color: #fff;
}
</style>