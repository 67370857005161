<template>
  <!-- sempre inicie uma div para colocar os codigos -->
  <div class="container custom-mt">
    <div class="card mx-auto border-0 shadow-lg" style="max-width: 500px">
      <div class="card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120
            "
          />
        </div>
      </div>
      <div class="card-body">
        <h4 class="text-center"><b>Cadastre o Cliente</b></h4>

        <div class="text-white error-label text-center" v-if="timeError">
          <p class="">{{ errorLabel.messageError }}</p>
        </div>

        <div class="mb-3 ps-2 pe-2">
          <label for="nome" class="form-label">Cliente</label>
          <input
            v-model="payloadClient.name"
            type="text"
            class="form-control"
            id="nome"
            required
            placeholder="Nome do cliente"
          />
          <p class="p-error-label">{{ errorLabel.name }}</p>
        </div>
        <div class="mb-3 ps-2 pe-2">
          <label for="telefone" class="form-label">Telefone</label>
          <input
            v-mask="'(##) #####-####'"
            v-model="payloadClient.fone"
            type="text"
            class="form-control"
            id="telefone"
            required
            placeholder="(00) 00000-0000"
          />
          <p class="p-error-label">{{ errorLabel.fone }}</p>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-dark ms-2 me-2" @click="registerClient">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-person-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
              />
              <path
                d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"
              />
            </svg>
            Cadastrar cliente
          </button>
          <button
            class="btn btn-outline-danger mb-4 ms-2 me-2"
            @click="backHome"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
            Voltar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { createClient } from "../../http";
import { mask } from "vue-the-mask";
import { sendEventClients } from "../../socket";

export default {
  name: "RegisterClient",
  props: {},
  directives: { mask },
  setup() {
    const router = useRouter();

    const backHome = () => {
      router.push("/");
    };

    const payloadClient = ref({
      name: null,
      fone: null,
    });

    const timeError = ref(false);

    const errorLabel = ref({
      name: null,
      fone: null,
      messageError: null,
    });

    const validateForm = () => {
      errorLabel.value.name = null;
      errorLabel.value.fone = null;
      errorLabel.value.messageError = null;

      if (!payloadClient.value?.name) {
        errorLabel.value.name = "Campo obrigatório.";
      }

      if (!payloadClient.value?.fone) {
        errorLabel.value.fone = "Campo obrigatório.";
      }

      if (errorLabel.value.name || errorLabel.value.fone) {
        return true;
      }
      return false;
    };

    const registerClient = async () => {
      if (validateForm()) {
        return;
      }

      const responseClient = await createClient(payloadClient.value);

      if (responseClient?.message) {
        errorLabel.value.messageError = responseClient?.message;
        timeError.value = true;
        setTimeout(() => {
          timeError.value = false;
        }, 5000);
        return;
      }else{
        sendEventClients('graphic', responseClient);
      }

      sessionStorage.setItem("reload", 1);
      router.push({ name: "home" });
    };

    return {
      backHome,
      payloadClient,
      registerClient,
      errorLabel,
      timeError,
    };
  },
};
</script>
<style scoped>
h1 {
  font-weight: bold;
}
h1,
label,
button {
  font-family: Arial, Helvetica, sans-serif;
}

.custom-mt {
  margin-top: 150px;
  /* Ajuste a margem superior conforme necessário */
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-label {
  background-color: #be3f3f;
  border-radius: 5px;
  overflow: hidden;
}

.p-error-label {
  font-size: 14px;
  color: red;
}
</style>
../socket