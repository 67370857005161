<template>
    <div class="container-fluid  text">
        <div class="ps-2 pe-2 mb-3">
            <label for="name" class="form-label"
              >Nome do Máquina</label
            >
            <input
              v-model="machine"
              type="text"
              class="form-control"
              id="name"
              required
              placeholder="Nome do máquina"
            />
            <p class="p-error-label">{{ errorLabel?.machine || errorLabel?.error }}</p>
        </div>

        <div class="d-flex justify-content-center mb-2">
            <button 
                class="btn btn-outline-dark me-1 col-6" 
                @click="registerMachine"
                :disabled="!machine ? true : false"
            >
              Cadastrar
            </button>
            <button 
              class="btn btn-outline-danger ms-1 col-6" 
              @click="openCreateMachine"
              id="closeButtonAdd"
              data-bs-dismiss="modal" 
              aria-label="Close"
            >
              Cancelar
            </button>
        </div> 
          
    </div>
</template>

<script>
import { ref } from 'vue';
import store from "../../store";
import { createMachine } from "../../http"
import { sendEventMachine } from "../../socket";

export default {
setup() {

  const  machine = ref('');
  const errorLabel = ref({
    machine: null,
    error: null
  })
  const openCreateMachine = () => {
    store.commit('setOpenCreateMachine');
  }

  const registerMachine = async () => {
    if(machine.value){
      await createMachine({name: machine.value})
        .then((data)=>{
          if(data?.message){
            errorLabel.value.error = data?.message;
            return;
          }
          store.dispatch('setMachineList', data);
          sendEventMachine('graphic', data);
          store.commit('setOpenCreateMachine');
          machine.value = '';
          document.getElementById("closeButtonAdd").click();
        });
    }else{
      errorLabel.value.machine = "Campo obrigatório.";
    }
  }

  return {
      openCreateMachine,
      machine,
      registerMachine
  };
}
};
</script>

<style>
.p-error-label {
  font-size: 14px;
  color: red;
}
</style>
