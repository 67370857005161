<template>
  <div class="container mt-5">
    <div class="card-header bg-transparent border-0 ms-2">
      <div>
        <img
          src="../../assets/logo.png"
          alt="Logomarca"
          class="img-fluid mx-auto d-block mt-4"
          width="120"
        />
      </div>
      <h4 class="text-center my-3">
        <font-awesome-icon icon="users" />
        Usuários Registrados
      </h4>
      <hr />
    </div>

    <div v-if="!filteredUsers.length" class="alert alert-light">
      <h5 class="custom-mt text-center mb-5">
        <font-awesome-icon icon="circle-exclamation" />
        Atualmente, não há registros disponiveís.
      </h5>
    </div>

    <button
      v-if="filteredUsers.length"
      class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb-2 me-3"
      @click="opeBlockFilter"
    >
      <font-awesome-icon
        icon="arrow-up-wide-short"
        class="me-2"
        v-if="openFilter"
      />
      <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
      Filtrar
    </button>
    <!-- Filtros de pesquisa -->
    <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
      <div class="col-md-2">
        <label for="filtroNome" class="form-label filtros">Nome:</label>
        <input
          id="filtroNome"
          class="form-control"
          placeholder="Digite o cliente"
          v-model="nameUser"
        />
      </div>

      <div class="col-md-2">
        <label for="filtroEtapa" class="form-label filtros">Função:</label>
        <select id="filtroEtapa" class="form-select" v-model="typeuserUser">
          <option value="" selected>Todos</option>
          <option value="D">Designer</option>
          <option value="I">Impressor</option>
          <option value="C">Conferente</option>
          <option value="A">Administrador</option>
        </select>
      </div>
    </div>

    <table
      className=" text-center table table-dark table-striped mb-5 custom-table"
      v-if="filteredUsers.length > 0"
    >
      <thead>
        <tr>
          <th scope="col">Nome</th>
          <th scope="col">Email</th>
          <th scope="col">Função</th>
          <th scope="col">Criação</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in filteredUsers" :key="item.id">
          <tr class="align-items-center clickable" @click="viewUser(item.id)">
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ typeUser(item.type_user) }}</td>
            <td>{{ convertDate(item.createdAt) }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <div>
    <h6
      v-if="
        !filteredUsers.length &&
        (nameProcess || statusProcess || foneProcess || stageProcess)
      "
      class="text-secondary custom-mt text-center"
    >
      Desculpe, não encontramos resultados correspondentes à sua pesquisa.
      <br />
      Tente usar termos diferentes ou ajuste os filtros para obter melhores
      resultados.
    </h6>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "vue";
import store from "../../store";
import router from "@/router";
import { mask } from "vue-the-mask";
import { convertDate, typeUser} from "../../utils";
import { getReponseUsers } from "../../socket";

export default {
  name: "ListUsers",
  props: {},
  directives: { mask },
  setup() {
    const usersList = computed(() => store.state.users);

    const nameUser = ref("");
    const typeuserUser = ref("");

    const filteredUsers = computed(() => {
      const _nameUser = nameUser.value.toLowerCase();

      return usersList.value?.filter(
        (user) =>
          user.name?.toLowerCase().includes(_nameUser) &&
          user.type_user?.includes(typeuserUser.value)
      );
    });

    const viewUser = (id) => {
      router.push("/view-user/" + id);
    };

    const openFilter = ref(false);
    const opeBlockFilter = () => {
      openFilter.value = openFilter.value ? false : true;
    };

    onBeforeMount(async () => {
      await store.dispatch("getUsers");
      getReponseUsers();
    });

    return {
      opeBlockFilter,
      openFilter,
      filteredUsers,
      nameUser,
      typeuserUser,
      convertDate,
      typeUser,
      viewUser,
    };
  },
};
</script>
<style scoped>
.custom-mt {
  margin-top: 70px;
}

.custom-table {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 80px;
}

.barra-pesquisa {
  margin-top: 100px;
}

.filtros {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.8rem;
}

.clickable {
  cursor: pointer;
}
</style>
