<template>
  <CheckTokenUser v-if="!tokenRegister.permission" />
  <div class="container custom-mt" v-if="tokenRegister.permission">
    <div class="card mx-auto border-0 shadow-lg" style="max-width: 500px">
      <div class="card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120
            "
          />
        </div>
      </div>

      <div class="card-body">
        <!-- <form> -->
        <div class="mb-3 ps-2 pe-2">
          <label for="nome" class="form-label">Nome</label>
          <input
            v-model="user.name"
            type="text"
            class="form-control"
            id="nome"
            required
            placeholder="Digite seu nome"
          />
          <p class="errorLabel">{{ errorLabel.name }}</p>
        </div>
        <div class="mb-3 ps-2 pe-2">
          <label for="funcao" class="form-label">Função</label>
          <input
            v-model="user.type_user"
            type="text"
            class="form-control"
            id="funcao"
            readonly
          />
        </div>
        <div class="mb-3 ps-2 pe-2">
          <label for="email" class="form-label">E-mail</label>
          <input
            v-model="user.email"
            type="email"
            class="form-control"
            id="email"
            required
            placeholder="Digite seu e-mail"
          />
          <p class="errorLabel">{{ errorLabel.email }}</p>
        </div>
        
        <div class="mb-3 ps-2 pe-2  icon-container">
          <label for="senha" class="form-label">Senha</label>
          <input
            v-model="user.password"
            :type="typeInput"
            class="form-control"
            id="senha"
            required
            minlength="6"
            maxlength="18"
            :placeholder="typeInput !== 'text' ? '********' : ''"
          />
          <span  class="hidder-eye-icon" @click="changeTypeInputPassword" v-if="typeInput === 'text'">
            <font-awesome-icon icon="eye-slash" />
          </span>
          <span  class="hidder-eye-icon" @click="changeTypeInputText" v-if="typeInput === 'password'">
            <font-awesome-icon icon="eye" />
          </span>
          <p class="errorLabel">{{ errorLabel.password }}</p>
        </div>
        <div class="mb-4 ps-2 pe-2  icon-container">
          <label for="confirmarSenha" class="form-label">Repita a Senha</label>
          <input
            :type="typeInput"
            v-model="user.confirmPassword"
            class="form-control"
            id="confirmarSenha"
            minlength="6"
            maxlength="18"
            required
            :placeholder="typeInput !== 'text' ? '********' : ''"
          />
          <span  class="hidder-eye-icon" @click="changeTypeInputPassword" v-if="typeInput === 'text'">
            <font-awesome-icon icon="eye-slash" />
          </span>
          <span  class="hidder-eye-icon" @click="changeTypeInputText" v-if="typeInput === 'password'">
            <font-awesome-icon icon="eye" />
          </span>
          <p class="errorLabel">{{ errorLabel.confirmPassword }}</p>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-dark ms-2 me-2" @click="registerUser">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"
              />
            </svg>
            Cadastrar
          </button>
          <button
            class="btn btn-outline-danger mb-4 ms-2 me-2"
            @click="backLogin"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
            Voltar ao Login
          </button>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { createUser, finishedToken } from "../../http";
import store from "../../store";
import CheckTokenUser from "../auth/CheckTokenUser.vue";
import { sendEventUsers } from "../../socket";

export default {
  name: "RegisterUser", // nome da pagina ou componente
  props: {}, // não vai usar no momento
  setup() {
    const router = useRouter();
    const tokenRegister = computed(() => store.state.register);

    const user = ref({
      name: null,
      email: null,
      password: null,
      confirmPassword: null,
      type_user: null,
    });

    const errorLabel = ref({
      name: null,
      email: null,
      password: null,
      confirmPassword: null,
    });

    const backLogin = () => {
      router.push("/");
    };

    const  validateForm = () => {
      errorLabel.value.name = null
      errorLabel.value.email  = null
      errorLabel.value.password  = null
      errorLabel.value.confirmPassword = null
      
      if(!user.value?.name || user.value?.name.length < 3 && user.value?.name.length < 100){
        errorLabel.value.name = "O nome, podem ter no minimo 3 caractere e no máximo 100";
      }

      if(!user.value?.email){
        errorLabel.value.email = 'Campo obrigatório.';
      }

      if(!user.value?.password || user.value?.password.length < 6 || user.value?.password.length > 18){
        errorLabel.value.password = "A senha, podem ter no minimo 6 caractere e no máximo 18";
        errorLabel.value.confirmPassword = "A senha podem ter no minimo 6 caractere e no máximo 18";
      }

      if (user.value?.password !== user.value?.confirmPassword) {
        errorLabel.value.confirmPassword = "As senhas estão com diferença";
      }

      if(errorLabel.value.name||
         errorLabel.value.email ||
         errorLabel.value.password ||
         errorLabel.value.confirmPassword
      ){
        return true;
      }
      return false;
    }
    const registerUser = async () => {

      if(validateForm()){
        return;
      }

      user.value.type_user = tokenRegister.value?.type_register;
      const request = await createUser(user.value);

      if(request?.message){
        errorLabel.value.email = request?.message;
        return;
      }

      if (request) {
        await store.dispatch("setUserCurrent", {
          email: request?.email,
          password: user.value?.password,
        })
        .then((data) => {
          sendEventUsers('graphic', data);
        });

        await finishedToken(tokenRegister.value?.id);
        sessionStorage.setItem("reload", 1);
        router.push({ name: "home" });
      }
    };

    const typeInput = ref('password');

    const changeTypeInputText = () => {
      typeInput.value = 'text';   
    }

    const changeTypeInputPassword = () => {
      typeInput.value = 'password';   
    }

    watch(tokenRegister, (newVal, oldVal) => {
      const typeUser = {
        A: 'Administrador',
        D: "Design",
        I: "Impressor",
        C: "Conferente",
      };
      if (oldVal !== newVal) {
        user.value.type_user = typeUser[tokenRegister.value.type_register];
      }
    });

    return {
      changeTypeInputText,
      changeTypeInputPassword,
      typeInput,
      backLogin,
      user,
      registerUser,
      errorLabel,
      tokenRegister,
    };
  },
  components: {
    CheckTokenUser,
  },
};
</script>
<style scoped>
/*colocar o estilo da página ou componente*/
h1 {
  font-weight: bold;
}
h1,
label,
button {
  font-family: Arial, Helvetica, sans-serif;
}

.custom-mt {
  margin-top: 40px;
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.errorLabel {
  font-size: 14px;
  color: red;
}

.hidder-eye-icon {
  position: absolute;
  right: 16px;
  top: 72%;
  cursor: pointer;
  transform: translateY(-50%);
  color: #000000; /* Cor do ícone (pode ser personalizado) */
}

.icon-container {
  position: relative;
}
</style>
../socket