<template>
    <div class="" v-if="setData(objetcSelect)">
        <label for="nome" class="form-label">{{setTitle}}</label>

        <div>
          <div class="search-container">
            <span
              class="search-icon"
              v-if="!resultSelected && !searchTerm"
            >
              <font-awesome-icon icon="magnifying-glass" />
            </span>
            <input
              v-model="searchTerm"
              @input="filterObject"
              :placeholder="getName(resultSelected)"
              class="form-control"
            />
            <span
              class="clear-icon"
              @click="clearSearch"
              v-if="resultSelected && !disabledClose"
            >
              <font-awesome-icon icon="circle-xmark" />
            </span>
          </div>
          <select
            class="form-control"
            v-model="resultSelected"
            size="3"
            v-if="searchTerm"
          >
            <option
              v-for="item in filteredObject"
              :key="item.id"
              :value="item.id"
            >
              {{ `${item.name}` }}
            </option>
          </select>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue';

export default {
        props: {
          objetcSelect: {
            type: Array,
            required: true,
          },
          setTitle:{
            type: String,
            required: true,
          },
          setResult: {
            type: Number,
            required: false
          },
          itemId: {
            type: Number,
            required: false
          },
          disabledClose: {
            type: Boolean,
            required: false,
          }
        },
        setup(props, { emit }){
            const objetcSelect = ref([]);
            const title = ref(props.setTitle);
            const itemId = ref(props.itemId)
            const searchTerm = ref('');
            const resultSelected = ref(props.setResult || '');

            const clearSearch = () => {
                searchTerm.value = "";
                resultSelected.value = null;
                emitValue(resultSelected.value)
            };

            const filteredObject = computed(() => { 
                const term = searchTerm.value?.toLowerCase();
                return objetcSelect.value?.filter(
                    (obj) =>
                    obj?.name?.toLowerCase().includes(term)
                );
            });

            const getName = (id) => {

                if (!searchTerm.value && !resultSelected.value) {
                    return `    Pesquisar ${title.value}...`;
                }

                const foundObject = objetcSelect.value?.find(
                    (obj) => obj.id === id
                );

                if (foundObject) {
                    searchTerm.value = "";
                    emitValue(resultSelected.value)
                    return foundObject ? foundObject.name : "";
                }
            };

            const filterObject = () => {};

            const setData = (data) => {
                objetcSelect.value = data;
                return data ? true : false;
            }

            const emitValue = (id) => {
              const data = itemId.value ? {id: id, item: itemId.value} : id
              emit('objectId', data);
            }

            // watch(() => props.setResult, (newValue, oldValue) => {
            //   resultSelected.value = +newValue  || +oldValue;
            // });

            return {
              emitValue,
              setData,
              getName,
              filterObject,
              clearSearch,
              resultSelected,
              searchTerm,
              filteredObject,
            };
        }
    }
</script>

<style scoped>
.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  color: #888; /* Cor do ícone de pesquisa (pode ser personalizado) */
}

.clear-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  color: #888; /* Cor do ícone (pode ser personalizado) */
}
</style>