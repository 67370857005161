<template>
  <div class="container mt-4 mb-5">
    <div class="card mx-auto border-0 shadow-lg" style="max-width: 500px">
      <div class="card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120
              "
          />
        </div>
      </div>
      <div class="card-body">
        <div class="text-white error-label text-center" v-if="timeError">
          <p class="">{{ errorLabel.errorGeral }}</p>
        </div>
        <!-- <form> -->
        <div class="mb-2 ps-2 pe-2">
          <label for="nome" class="form-label">Nome</label>
          <input
            v-model="user.name"
            type="text"
            class="form-control"
            id="nome"
            required
          />
          <p class="p-error-label">{{ errorLabel.name }}</p>
        </div>
        <div class="mb-2 ps-2 pe-2">
          <label for="funcao" class="form-label">Função</label>
          <select
            v-model="user.type_user"
            class="form-select"
            aria-label="Default select example"
          >
            <option
              value="D"
              v-if="
                user.type_user === 'D' ||
                $_store.state.userCurrent.type_user === 'A'
              "
            >
              Designer
            </option>

            <option
              value="I"
              v-if="
                user.type_user === 'I' ||
                $_store.state.userCurrent.type_user === 'A'
              "
            >
              Impressor
            </option>

            <option
              value="C"
              v-if="
                user.type_user === 'C' ||
                $_store.state.userCurrent.type_user === 'A'
              "
            >
              Conferente
            </option>

            <option
              value="A"
              v-if="$_store.state.userCurrent.type_user === 'A'"
            >
              Administrador
            </option>
          </select>
        </div>

        <div class="mb-2 ps-2 pe-2">
          <label for="email" class="form-label">E-mail</label>
          <input
            v-model="user.email"
            type="email"
            class="form-control"
            id="email"
            required
          />
          <p class="p-error-label">{{ errorLabel.email }}</p>
        </div>

        <button
          :class="`btn btn-sm btn-outline-${
            !openNewPass ? 'dark' : 'danger'
          } mb-2 ms-2 me-2`"
          @click="openNewPassword"
        > 
          <font-awesome-icon icon="key" v-if="!openNewPass"/>
          {{ !openNewPass ? "criar nova senha" : "Cancelar" }}
        </button>
        
        <div class="card mb-2" v-if="openNewPass">
          <div class="mb-3 ps-2 pe-2 icon-container">
            <label for="senha" class="form-label">Nova Senha</label>
            <input
              v-model="password.newPassword"
              :type="typeInput"
              class="form-control"
              id="senha"
              required
              minlength="6"
              maxlength="18"
              :placeholder="typeInput !== 'text' ? '********' : ''"
            />
            <span  class="hidder-eye-icon" @click="changeTypeInputPassword" v-if="typeInput === 'text'">
              <font-awesome-icon icon="eye-slash" />
            </span>
            <span  class="hidder-eye-icon" @click="changeTypeInputText" v-if="typeInput === 'password'">
              <font-awesome-icon icon="eye" />
            </span>
            <p class="p-error-label">{{ errorLabel.newPassword }}</p>
          </div>
          <div class="mb-4 ps-2 pe-2 icon-container">
            <label for="confirmarSenha" class="form-label"
              >Repita a Senha</label
            >
            <input
              :type="typeInput"
              v-model="password.confirmPassword"
              class="form-control"
              id="confirmarSenha"
              minlength="6"
              maxlength="18"
              required
              :placeholder="typeInput !== 'text' ? '********' : ''"
            />
            <span  class="hidder-eye-icon" @click="changeTypeInputPassword" v-if="typeInput === 'text'">
              <font-awesome-icon icon="eye-slash" />
            </span>
            <span  class="hidder-eye-icon" @click="changeTypeInputText" v-if="typeInput === 'password'">
              <font-awesome-icon icon="eye" />
            </span>
            <p class="p-error-label">{{ errorLabel.confirmPassword }}</p>
          </div>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary ms-2 me-2" @click="editUse">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"
              />
            </svg>
            Confirmar
          </button>
          <button class="btn btn-outline-danger mb-4 ms-2 me-2" @click="back">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
            Voltar
          </button>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { editUser } from "../../http";
import store from "../../store";

export default {
  name: "EditUser",
  props: {},
  setup() {
    const route = useRoute();
    const userId = ref(route.params.id || null);
    const tokenRegister = computed(() => store.state.register);
    const user = computed(() =>
      userId.value ? store.state.userViewEdit : store.state.userCurrent
    );

    const password = ref({
      newPassword: null,
      confirmNewPassword: null,
    });

    const errorLabel = ref({
      name: null,
      email: null,
      newPassword: null,
      confirmPassword: null,
      errorGeral: null,
    });

    const back = () => {
      store.commit("setOpenEdit");
    };

    const openNewPass = ref(false);
    const timeError = ref(false);

    const openNewPassword = () => {
      openNewPass.value = openNewPass.value ? false : true;
    };

    const validateForm = () => {
      errorLabel.value.name = null;
      errorLabel.value.email = null;
      errorLabel.value.newPassword = null;
      errorLabel.value.confirmPassword = null;
      errorLabel.value.errorGeral = null;

      if (
        !user.value?.name ||
        (user.value?.name.length < 3 && user.value?.name.length < 100)
      ) {
        errorLabel.value.name =
          "O nome, tem que ter no minimo 3 caractere e no máximo 100";
      }

      if (!user.value?.email) {
        errorLabel.value.email = "Campo obrigatório.";
      }

      if (openNewPass.value) {
        if (
          !password.value?.newPassword ||
          password.value?.newPassword.length < 6 ||
          password.value?.newPassword.length > 18
        ) {
          errorLabel.value.newPassword =
            "A senha, podem ter no minimo 6 caractere e no máximo 18";
          errorLabel.value.confirmPassword =
            "A senha podem ter no minimo 6 caractere e no máximo 18";
        }

        if (password.value?.newPassword !== password.value?.confirmPassword) {
          errorLabel.value.confirmPassword = "As senhas estão com diferença";
        }
      }
      if (
        errorLabel.value.name ||
        errorLabel.value.email ||
        errorLabel.value.newPassword ||
        errorLabel.value.confirmPassword
      ) {
        return true;
      }
      return false;
    };

    const editUse = async () => {
      if (validateForm()) {
        return;
      }

      const payloadEditUser = {
        ...user.value,
        ...{ password: password.value.newPassword },
      };

      const requestEdit = await editUser(
        user.value.id,
        password.value.password ? user.value : payloadEditUser
      );

      if (!requestEdit?.status) {
        errorLabel.value.errorGeral = requestEdit?.message;
        timeError.value = true;
        setTimeout(() => {
          timeError.value = false;
        }, 5000);
        return;
      }

      location.reload();
    };

    const typeInput = ref('password');

    const changeTypeInputText = () => {
      typeInput.value = 'text';   
    }

    const changeTypeInputPassword = () => {
      typeInput.value = 'password';   
    }

    return {
      changeTypeInputText,
      changeTypeInputPassword,
      typeInput,
      back,
      user,
      editUse,
      errorLabel,
      tokenRegister,
      password,
      openNewPassword,
      openNewPass,
      timeError,
    };
  },
};
</script>
<style scoped>
/*colocar o estilo da página ou componente*/
h1 {
  font-weight: bold;
}
h1,
label,
button {
  font-family: Arial, Helvetica, sans-serif;
}

.custom-mt {
  margin-top: 40px;
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-label {
  background-color: #be3f3f;
  border-radius: 5px;
  overflow: hidden;
}

.p-error-label {
  font-size: 14px;
  color: red;
}

.hidder-eye-icon {
  position: absolute;
  right: 16px;
  top: 60%;
  cursor: pointer;
  transform: translateY(-50%);
  color: #000000; /* Cor do ícone (pode ser personalizado) */
}

.icon-container {
  position: relative;
}
</style>
