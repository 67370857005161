<template>

  <div class="modal fade" id="staticBack" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackLabel" aria-hidden="true" v-if="user?.id">
      <div class="modal-dialog modal-sm modal-dialog-centered overlay-background2" style="max-width:75%; border: none !important;">
          <div class="modal-content overlay-background" style="background-color: transparent; border: none !important;">
              <div class="modal-body mb-5 rounded" style="position: relative; margin-right: 32px;padding: 0;">
                  <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close" style="position: absolute; top: 0; right: 0; z-index: 1050;" @click="closeRoom($_store.state.roomChannelSelected)"></button>
                  <chat class="rounded" />
              </div>
          </div>
      </div>
  </div>

  <AlertVue v-if="showAlert" ref="alertRef" :minutes="minutesRef" />

  <div>
    
    <NavBar/>
    <FooterBar/>
    <FloatingBall/>
    <audio v-if="user?.id" id="meuAudio" :src="'notification.mp3'" preload="auto"></audio>
    <audio v-if="user?.id" id="meuAudioMessage" :src="'new-message.mp3'" preload="auto"></audio>

    <button v-if="user?.id" id="botaoOculto" style="display: none;"></button>
    <button v-if="user?.id" id="botaoOcultoMessage" style="display: none;"></button>

  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterBar from './components/FooterBar.vue';
import AlertVue from './components/Alert.vue';

import Chat from './pages/chat/HomeChat.vue';
import { onBeforeMount, computed, onMounted, onUnmounted, ref } from 'vue';
import store from './store';
import { 
  getReponseClients, 
  getReponseStageProcess, 
  getReponseUsers, 
  getReponseProcess,
  getReponseNotification,
  getReponseMaterial,
  closeChannel,
} from "./socket";

export default {
  name: "App",
  components: {
    NavBar,
    FooterBar,
    Chat,
    AlertVue
  },
  setup(){

    const user = computed(() => store.state.userCurrent);

    const closeRoom = (channel) => {
      closeChannel(channel);
    }

    const minutesRef = ref(0);
    const showAlert = ref(false);
    const intervalId = ref(null);
    const alertRef = ref(null);

    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
  
      if (hours === 11  && minutes === 50) {
        minutesRef.value = 10;
        showAlert.value = true;
        if (alertRef.value) {
          alertRef.value.show();
        }
      }
      if (hours === 11 && minutes === 55) {
        minutesRef.value = 5;
        showAlert.value = true;
        if (alertRef.value) {
          alertRef.value.show();
        }
      }

      if (hours === 17 && minutes === 50) {
        minutesRef.value = 10;
        showAlert.value = true;
        if (alertRef.value) {
          alertRef.value.show();
        }
      }

      if (hours === 17 && minutes === 55) {
        minutesRef.value = 5;
        showAlert.value = true;
        if (alertRef.value) {
          alertRef.value.show();
        }
      }

    };

    onBeforeMount( async () => {
      getReponseClients();
      getReponseProcess();
      getReponseUsers();
      getReponseStageProcess();
      getReponseNotification();
      getReponseMaterial();
    });

    onMounted(() => {
      checkTime();
      intervalId.value = setInterval(checkTime, 60000);


    });

    onUnmounted(() => {
      clearInterval(intervalId.value);
    });

    return {
      user,
      closeRoom,
      showAlert, 
      alertRef ,
      minutesRef

    };
  }
};
</script>

<style>

</style>
