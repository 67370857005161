<template>

  <div class="modal fade" 
    id="exampleModalToggle" 
    aria-hidden="true" 
    aria-labelledby="exampleModalToggleLabel" 
    tabindex="-1"
    style="max-height: 600px; margin-top: 40px;"
  >
    <div :class="'modal-dialog modal-dialog-centered '">
      <div class="modal-content">
        <div class="modal-header">
          <h5 
            class="modal-title" 
            id="exampleModalToggleLabel">
            Finalizar entrega do serviço - N° {{ 1000 + process?.id }}
          </h5>
          <button 
            type="button" 
            class="btn-close" 
            data-bs-dismiss="modal" 
            aria-label="Close"
            @click="closeDelivery"
          >
          </button>
        </div>
        <div class="modal-body">
 
          <div class="ps-2 pe-2 mb-3">
            <label for="name" class="form-label">Responsável</label>
            <input
              :v-model="payload.delivery=$_store.state.userCurrent.id"
              type="text"
              class="form-control"
              id="name"
              :placeholder="$_store.state.userCurrent.name"
              readonly
            />
          </div>

          <div class="ps-2 pe-2 mb-3">
            <label for="type" class="form-label"
              >Tipo da entrega</label
            >
            <select id="type" v-model="payload.type_delivery" class="form-control">
              <option value="" selected>Selecione o tipo de Entrega...</option>
              <option value="U">Uber</option>
              <option value="M">Motoboy</option>
              <option value="R">Rodoviária</option>
              <option value="C">Cliente</option>
            </select>
          </div>

          <div class="ps-2 pe-2 mb-3">
            <label for="comment">Observação <small>(opcional)</small></label>
            <textarea
              v-model="payload.observer"
              class="form-control"
              id="comment"
              rows="3"
            >
            </textarea>
          </div>
          <div class="d-flex justify-content-center mb-2">
            <button 
                class="btn btn-outline-dark me-1 col-6" 
                @click="sendDelivery"
                :disabled="!payload.type_delivery ? true : false"
            >
              Finalizar
            </button>
            <button 
              class="btn btn-outline-danger ms-1 col-6" 
              @click="closeDelivery"
              id="closeButtonDelivery"
              data-bs-dismiss="modal" 
              aria-label="Close"
            >
              Cancelar
            </button>
          </div> 

        </div>
      </div>
    </div>
  </div>

    <div 
        class="container mt-5"
        style="margin-bottom: 100px;"
        v-if="$_store.state.userCurrent.type_user.includes('A')"
    >    
        <div class="card-header bg-transparent border-0 ms-2">
            <div>
              <img
                src="@/assets/logo.png"
                alt="Logomarca"
                class="img-fluid mx-auto d-block mt-4"
                width="120"
              />
            </div>
            <h4 class="text-center my-3">
                <font-awesome-icon icon="users" />
                Aguardando Entrega
            </h4>
            <hr />
        </div>
    
      <div v-if="!filteredDelivery?.length" class="alert alert-light">
        <h5 class="custom-mt text-center mb-5">
          <font-awesome-icon icon="circle-exclamation" />
          Atualmente, não há registros disponiveís.
        </h5>
      </div>
  
      <button
        v-if="filteredDelivery?.length"
        class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb-2 me-3"
        @click="opeBlockFilter"
      >
        <font-awesome-icon
          icon="arrow-up-wide-short"
          class="me-2"
          v-if="openFilter"
        />
        <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
        Filtrar
      </button>
      <!-- Filtros de pesquisa -->
      <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
        
        <div class="col-md-3">
          <label for="filtroNome" class="form-label filtros"
            >Nome:</label
          >
          <input
            id="filtroNome"
            class="form-control"
            placeholder="Digite o cliente"
            v-model="nameClient"
          />
        </div>
        
        <div class="col-md-3">
          <label for="filtroNome" class="form-label filtros"
            >Telefone:</label
          >
          <input
            id="filtroNome"
            class="form-control"
            placeholder="(00) 00000-0000"
            v-model="foneClient"
            v-mask="'(##) #####-####'"
          />
        </div>

        </div>

        <LoadingObjet class="text-center" v-show="loading" />

        <div v-if="!loading">
          <template v-for="item in filteredDelivery || []" :key="item.id">
              <div 
                  class="card mb-2 border " 
                  :class="contForDelivery(item.processes) == item?.processes?.length  ? ' border-3 border-success overflow-custom' : 'overflow-custom'"
                  style="height: 200px;"
                  :style="(actionServicePendding[item.id] && item?.processes?.length > 0) ? 'overflow-y: auto;' : ''"
              >

              <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item">
                        <a 
                            class="nav-link clickable text-dark" 
                            :class=" !actionServicePendding[item.id] ? ' active' : ''"
                            @click="clickServicePendding(item.id, false)">
                            Cliente
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link clickable text-dark" 
                            :class="actionServicePendding[item.id] ? ' active' : ''"
                            @click="clickServicePendding(item.id, true)">
                            Serviços pendentes
                        </a>
                    </li>
                    <div 
                      class="d-flex justify-content-end mt-3 ms-2"
                      v-if="contForDelivery(item.processes) == item?.processes?.length "
                    >
                      <div 
                        :class="'spinner-grow spinner-grow-sm bg-' + backgroundColorStage(processSelected[item.id]?.stage)" 
                        role="status"
                        style=""
                      ></div> 
                    </div>
                </ul>
                <!-- Div para alinhar ao final -->
                
            </div>
                  <div class="card-body" v-if="!actionServicePendding[item.id]">
                      <div class="row">

                          <div class="col-6">
                              <h5 class="card-title mb-3 ms-5">{{ item.name }}</h5>
                              <h6 class="card-title mb-3 ms-5">Total de serviço pendente: {{ contForDelivery(item.processes, true)}}</h6>
                              <h6 class="card-title mb-3 ms-5">Serviços para entrega: {{ contForDelivery(item.processes) }}</h6>
                          </div>
                      
                          <div :class="'card col-6 text-center border-'+ backgroundColorStage(processSelected[item.id]?.stage)" style="max-width: 500px; max-height: 130px;">
                              <div class="col-12">
                                  <div class="card-body" style="padding: 0;">
                                  <h5 class="" style="margin-bottom: 0;">
                                      <span class="text-secondary">Sendo preparado no momento: </span>
                                      <!-- <div :class="'spinner-grow spinner-grow-sm bg-' + backgroundColorStage(processSelected[item.id]?.stage)" role="status"></div>  -->
                                  </h5>
                                  <p class="" style="margin-bottom: 0;"><b>{{getMaterial(processSelected[item.id])}}</b></p>
                                  <p class="mt-2" style="margin-bottom: 0;"><b>Etapa Atual: </b>{{stateFormat(processSelected[item.id])}}</p>
                                  <p class=""><small class="text-muted">Ultima Atualização: {{convertDate(processSelected[item.id]?.updatedAt)}}</small></p>
          
                              </div>
                              </div>
                          </div>
          
                      </div>    
                  
                  </div>
                  <div class="card-body text-center container-fluid" v-else>
                      <table className="table table-dark table-striped custom-table">
                          <thead>
                          <tr>
                              <th scope="col">N°</th>
                              <th scope="col">Cliente</th>
                              <th scope="col">Material</th>
                              <th scope="col">Status</th>
                              <th scope="col">Etapa</th>
                              <th scope="col">Criação</th>
                              <th scope="col">Ultima Atualização</th>
                              <th scope="col">Ver Detalhes</th>
                              <th scope="col">Entregar</th>

                          </tr>
                          </thead>
                          <tbody>
                          <template
                              v-for="process in item?.processes || []"
                              :key="process.id"
                          >
                              <tr class="align-items-center" style="height: 10px;">
                                  <td class="col-auto clickable">
                                      {{ process.id + 1000 }}
                                  </td>
                                  <td class="col clickable">
                                      {{ item.name }}
                                  </td>
                                  <td class="col clickable">
                                      {{ getMaterial(process) }}
                                  </td>
                                  <td class="col clickable">
                                      {{ typeStatus(process.status) }}
                                  </td>
                                  <td class="col clickable">
                                      {{ stateFormat(process) }}
                                  </td>
                                  <td class="col clickable">
                                      {{ convertDate(process.createdAt) }}
                                  </td>
                                  <td class="col clickable">
                                      {{ convertDate(process.updatedAt) }}
                                  </td>
                                  <td class="col clickable"  @click="viewProcesss(process.id)">
                                    <font-awesome-icon icon="eye" class="text-white"/>
                                  </td>
                                  
                                  <td 
                                    v-if="process.stage == 'E'"
                                    class="col clickable"
                                    data-bs-toggle="modal" 
                                    href="#exampleModalToggle" 
                                    role="button"
                                    @click="setProcess(process)"
                                  >
                                    <font-awesome-icon icon="truck-fast" class="text-success" />
                                  </td>
                                  <td 
                                    class="col"
                                    v-else
                                  >
                                    <font-awesome-icon icon="truck-fast" class="text-secondary" />
                                  </td>
                              </tr>
                          </template>
                          </tbody>
                      </table>
                  </div>
              </div>
          </template>
        </div>

      <div v-show="!loading">
        <h6
          v-if="
            !filteredDelivery?.length &&
            (nameProcess || statusProcess || foneProcess || stageProcess)
          "
          class="text-secondary custom-mt text-center"
        >
          Desculpe, não encontramos resultados correspondentes à sua pesquisa.
          <br />
          Tente usar termos diferentes ou ajuste os filtros para obter melhores
          resultados.
        </h6>
      </div>

    </div>

  </template>
  
  <script>
  import { computed, onBeforeMount, ref, watch } from "vue";
  import store from "@/store";
  import router from "@/router";
  import { mask } from "vue-the-mask";
  import { formatFone, convertDate, getMaterialInList } from "@/utils";

  import { 
    getReponseClients, 
    getReponseMaterial, 
    getReponseNotification, 
    getReponseStageProcess, 
    getReponseProcess,
    sendEventStageProcess
  } from "@/socket";

  import { getProcessFinished, createDelivery, deleteDelivery } from "@/http";
  import LoadingObjet from '@/components/LoadingObjet';

  export default {
    name: "ListClient",
    props: {},
    directives: { mask },
    setup() {

      const processesDelivery = computed(() => store.state.executeProcessesDelivery || [] );
      const materials = computed(() => store.state.materialListView);
      const nameClient = ref("");
      const foneClient = ref("");
      const processSelected = ref({});  
      const loading = ref(false);
      const payload = ref({
        type_delivery: "",
        delivery: "",
        observer: ""
      });

      const process = ref(null);
      const setProcess = (item) => {
        process.value =  item;
      }
      
      const closeDelivery = () => {
        process.value = null;
        payload.value = {
          type_delivery: "",
          delivery: "",
          observer: ""
        };
      }

      const sendDelivery = async () => {
        if(process.value?.id){
          const requestDelivery = await createDelivery(payload.value);
      
          if (requestDelivery?.id){
            await getProcessFinished(process.value?.id, {delivery: requestDelivery?.id})
              .then(async (data)=> {
                if(data.status){
                  process.value['stage'] = "C"
                  sendEventStageProcess("graphic", process.value);
                  closeDelivery();
                  document.getElementById("closeButtonDelivery").click();
                  await startInit();
                }else{
                  await deleteDelivery(requestDelivery?.id)
                }
            });
          }

        }
      }
      
      const filteredDelivery = computed(() => {
          const _nameClient = nameClient.value.toLowerCase();
          const _foneClient = foneClient.value.replace().replace(/[()\- ]/g, "");
          
          if (Array.isArray(processesDelivery.value) && processesDelivery.value?.length > 0) {
              return processesDelivery.value.filter((item) => 
                  item.name?.toLowerCase().includes(_nameClient) &&
                  item.fone?.includes(_foneClient)
              );
          } else {
              return [];
          }
      });

  
      const viewClient = (id) => {
        router.push("/view-client/" + id);
      };
      const viewProcesss = (id) => {
        router.push("/view-process/" + id);
       };
      const openFilter = ref(false);
      const opeBlockFilter = () => {
        openFilter.value = openFilter.value ? false : true;
      };
      
    const chooseProcessByPriority = (processes) => {
        const priorityOrder = {'D': 5, 'P': 4, 'C': 2, 'I': 3, 'A': 1, 'E': 6};

        processes.sort((a, b) => {
            const priorityA = priorityOrder[a.stage];
            const priorityB = priorityOrder[b.stage];

            if (priorityA === priorityB) {
                return new Date(a.createdAt) - new Date(b.createdAt);
            }

            return priorityA - priorityB;
        });

        return processes[0];
    }

    const backgroundColorStage = (stage) => {
        if (stage == 'D' )  return 'dark';
        if (stage == 'P' || stage == 'C')  return 'warning';
        if (stage == 'I' || stage == 'A') return 'primary';
        if (stage == 'E' )  return 'success';
    }

    const getMaterial = (item) => {
      return getMaterialInList(materials.value, item)
    }

    const stateFormat = (item) => {

      if (item?.finished == 1) {
        return "Concluído";
      }

      switch (item?.stage) {
        case "D":
          return "Entrada";
        case "I":
          return "Conferente";
        case "C":
          return "Concluído";
        case "P":
          return "Produção";
        case "A":
          return "Acabamento";
        case "E":
          return "Aguardando Entrega";
      }
    };

    const typeStatus = (type) => {
      switch (type) {
        case "U":
          return "Urgente";
        case "N":
          return "Normal";
      }
    };

    const contForDelivery = (processes, geral=false) =>{
        if(geral){
            const prorcessFilter = processes?.filter( item =>  item.stage !== 'E');
            return prorcessFilter?.length;
        }

        const prorcessFilter = processes?.filter( item =>  item.stage === 'E');
        return prorcessFilter?.length;
    }

    const actionServicePendding = ref({});
    const clickServicePendding = (id , status) =>{
        actionServicePendding.value[id] = status
    }

    const startInit = async () => {
      await store.dispatch("getProcessesDelivery");
      const materialIds = [];

      if (Array.isArray(processesDelivery.value) && processesDelivery.value?.length > 0) {
        for (const item of processesDelivery.value ){
            processSelected.value[item.id] = chooseProcessByPriority(item?.processes);
            for (const process of item?.processes){
                materialIds.push(process?.material);
            }
        }

        const countEProcesses = (client) =>{
          let countE = 0;
          client.processes.forEach(process => {
              if (process.stage === 'E') {
                  countE++;
              }
          });
          return countE;
        }
        
        processesDelivery.value?.sort((a, b) => {
            const countDiff = countEProcesses(b) - countEProcesses(a);
            if (countDiff !== 0) {
                return countDiff;
            } else {
                if (countEProcesses(a) === a.processes?.length) return -1;
                else if (countEProcesses(b) === b.processes?.length) return 1;
                else return 0;
            }
        });
      }
      await store.dispatch('getMaterialListView', materialIds);
    }

    watch(processesDelivery, async (newVal, oldVal)=> {
      if(newVal?.length > oldVal?.length || 
        newVal?.length == 1 && newVal[0].new ||
        newVal?.reflash ){
        await startInit();
      }
    });

    onBeforeMount(async () => {
        loading.value = true;
        await startInit();
        getReponseClients();
        getReponseMaterial();
        getReponseNotification();
        getReponseStageProcess();
        getReponseProcess();
        loading.value = false;
    });

      return {
        loading,
        process,
        setProcess,
        closeDelivery,
        sendDelivery,
        payload,
        viewProcesss,
        typeStatus,
        clickServicePendding,
        actionServicePendding,
        contForDelivery,
        stateFormat,
        getMaterial,
        materials,
        backgroundColorStage,
        processSelected,
        chooseProcessByPriority,
        opeBlockFilter,
        openFilter,
        filteredDelivery,
        nameClient,
        foneClient,
        convertDate,
        viewClient,
        formatFone
      };
    },
    components:{
      LoadingObjet,
    }
  };
  </script>
  <style scoped>
  .custom-mt {
    margin-top: 70px;
  }
  
  .custom-table {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 80px;
  }
  
  .barra-pesquisa {
    margin-top: 100px;
  }
  
  .filtros {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
  }
  
  .clickable {
    cursor: pointer;
  }
  

.overflow-custom::-webkit-scrollbar {
  width: 4px; 
  height: 4px;
}

.overflow-custom::-webkit-scrollbar-thumb {
  background-color: #060606; 
  border-radius: 4px; 
}

.overflow-custom::-webkit-scrollbar-track {
  background-color: #8c8a8a; 
}

  </style>
  
  