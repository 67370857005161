<template>
    <div>
        <div v-if="$_store.state.typeEventPoint.type == 'E'">
            <div class="input-group input-group-sm" v-if="points.point1Exist" >
                <h6 class="col-12">Entrada Manhã:</h6>
                <input id="hour1" type="number" class="form-control" v-model="points.point1.hour" min="0" max="23" />
                <input id="minute1" type="number" class="form-control" v-model="points.point1.minute" min="0" max="59" />
                <input id="second1" type="number" class="form-control" v-model="points.point1.second" min="0" max="59" /> hrs
            </div>
            <div class="input-group input-group-sm"  v-if="points.point2Exist" >
                <h6 class="col-12">Saída Manhã:</h6>
                <input id="hour2" type="number" class="form-control" v-model="points.point2.hour" min="0" max="23" />
                <input id="minute2" type="number" class="form-control" v-model="points.point2.minute" min="0" max="59" />
                <input id="second2" type="number" class="form-control" v-model="points.point2.second" min="0" max="59" /> hrs
            </div>
            <div class="input-group input-group-sm" v-if="points.point3Exist">
                <h6 class="col-12">Entrada Tarde:</h6>
                <input id="hour3" type="number" class="form-control" v-model="points.point3.hour" min="0" max="23" />
                <input id="minute3" type="number" class="form-control" v-model="points.point3.minute" min="0" max="59" />
                <input id="second3" type="number" class="form-control" v-model="points.point3.second" min="0" max="59" /> hrs
            </div>
            <div class="input-group input-group-sm" v-if="points.point4Exist">
                <h6 class="col-12">Saída Tarde:</h6>
                <input id="hour4" type="number" class="form-control" v-model="points.point4.hour" min="0" max="23" />
                <input id="minute4" type="number" class="form-control" v-model="points.point4.minute" min="0" max="59" />
                <input id="second4" type="number" class="form-control" v-model="points.point4.second" min="0" max="59" /> hrs
            </div>
            <div class="d-flex justify-content-center mt-4">
                    <button 
                        class="btn btn-success me-1 col-6"
                        @click="sendUpdatePoint"
                    >
                        Concluir
                    </button>
                    <button 
                        id="closeButton"
                        class="btn btn-outline-danger ms-1 col-6"
                        data-bs-dismiss="modal" 
                        aria-label="Close">
                        Cancelar
                    </button>
                </div> 
        </div>
        <div v-else>
            <div>
                <div class="d-flex justify-content-center mb-2">
                    <button 
                        class="btn btn-dark me-1 col-6"
                        @click="sendDeletePoint"
                    >
                    Confirmar
                    </button>
                    <button 
                        id="closeButton" 
                        class="btn btn-outline-danger ms-1 col-6" 
                        data-bs-dismiss="modal" 
                        aria-label="Close">
                        Cancelar
                    </button>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import store from "../../store";
import {  getDateBrazil } from '@/utils';
import {  EditPoint, deletePoint} from "@/http";
export default {

  setup() {

    const pointRequest = computed(() => store.state.typeEventPoint.point || {})
    const points = ref({
      point1: { hour: 0, minute: 0, second: 0 },
      point2: { hour: 0, minute: 0, second: 0 },
      point3: { hour: 0, minute: 0, second: 0 },
      point4: { hour: 0, minute: 0, second: 0 },
      point1Exist: false,
      point2Exist: false,
      point3Exist: false,
      point4Exist: false,
    });

    const shapePoint = (datePoint, timePoint) => {
        let timeOfPoint = `${String(timePoint.hour).padStart(2, '0')}:${String(timePoint.minute).padStart(2, '0')}:${String(timePoint.second).padStart(2, '0')}`;
        return (timeOfPoint) || "";
    }

    const sendUpdatePoint = async () => {

        const payloadPoint = {
            firstTime : points.value.point1Exist ? shapePoint(pointRequest.value.firstTime, points.value.point1) : null,
            secondTime : points.value.point2Exist ? shapePoint(pointRequest.value.secondTime, points.value.point2) : null,
            thirdTime  : points.value.point3Exist ? shapePoint(pointRequest.value.thirdTime, points.value.point3) : null,
            fourthTime : points.value.point4Exist ? shapePoint(pointRequest.value.fourthTime, points.value.point4) : null,
        }

        await EditPoint(pointRequest.value.id, payloadPoint)
            .then(async () => {
                store.commit('setEditOrDeletePoint', {type: null, point: {}});
                await store.dispatch("setPointAllCurrent", getDateBrazil());
                document.getElementById("closeButton").click();
        });
    }

    const sendDeletePoint = async () => {
        await deletePoint(pointRequest.value.id)
            .then(async () => {
                store.commit('setEditOrDeletePoint', {type: null, point: {}});
                await store.dispatch("setPointAllCurrent", getDateBrazil());
                document.getElementById("closeButton").click();
            });
    }

    watch(pointRequest, (newValue) => {
        points.value = {
            point1: { hour: 0, minute: 0, second: 0 },
            point2: { hour: 0, minute: 0, second: 0 },
            point3: { hour: 0, minute: 0, second: 0 },
            point4: { hour: 0, minute: 0, second: 0 },
            point1Exist: false,
            point2Exist: false,
            point3Exist: false,
            point4Exist: false
        };
        if(newValue){
            let point1 = newValue.firstTime;
            let point2 = newValue.secondTime;
            let point3 = newValue.thirdTime;
            let point4 = newValue.fourthTime;

            if(point1){
                let point1Arr = point1.split(':');
                points.value.point1.hour = point1Arr[0];
                points.value.point1.minute = point1Arr[1];
                points.value.point1.second = point1Arr[2];
                points.value.point1Exist = true;
            }

            if(point2){
                let point2Arr = point2.split(':');
                points.value.point2.hour = point2Arr[0];
                points.value.point2.minute = point2Arr[1];
                points.value.point2.second = point2Arr[2];
                points.value.point2Exist = true;
            }

            if(point3){
                let point3Arr = point3.split(':');
                points.value.point3.hour = point3Arr[0];
                points.value.point3.minute = point3Arr[1];
                points.value.point3.second = point3Arr[2];
                points.value.point3Exist = true;
            }

            if(point4){
                let point4Arr = point4.split(':');
                points.value.point4.hour = point4Arr[0];
                points.value.point4.minute = point4Arr[1];
                points.value.point4.second = point4Arr[2];
                points.value.point4Exist = true;
            }

        }
    })
    return {
      points,
      sendDeletePoint,
      sendUpdatePoint
    };
  }
};
</script>

<style scoped>

</style>
