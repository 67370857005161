import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import Auth from './pages/auth/Auth.vue';
import RegisterUser from './pages/user/RegisterUser.vue';
import Home from './pages/Home.vue';
import ViewUserVue from './pages/user/ViewUser.vue';
import RegisterClient from './pages/client/RegisterClient.vue';
import CreateProcesses from './pages/processes/execution/DesignerProcess.vue';
import ViewProcess from './pages/processes/ViewProcess.vue';
import NotFound from './pages/NotFound.vue';
import GenerateToken from './pages/auth/GenerateToken.vue';
import ListUsers from './pages/user/ListUsers.vue';
import ListClient from './pages/client/ListClient.vue';
import ViewClient from './pages/client/ViewClient.vue';
import ListMaterial from './pages/material/ListMaterial.vue';
import ListMachineVue from './pages/machine/ListMachine.vue';
import ListEstoqueVue from './pages/estoque/ListEstoque.vue';
import PrinterProcess from '@/pages/processes/execution/PrinterProcess';
import LecturerProcess from '@/pages/processes/execution/LecturerProcess.vue';
import Chat from "@/pages/chat/HomeChat";
import Ponto from "@/pages//point/ListPoint.vue";
import DataManipulation from "@/pages/dataManipulation/DataManipulation.vue";
import DeliveryProcess from './pages/processes/execution/DeliveryProcess.vue';
import DashBoard from '@/pages/admin/DashBoard.vue'
import ViewOrders from './pages/client/ViewOrders.vue';

import {
  authMiddleware,
  authAdminDesigner,
  authAdminLecuter,
  authAdmin,
  authAdminPrinter
}
  from "./middleware";

const routes = [
  {
    path: '/:catchAll(.*)',
    component: NotFound
  },
  {
    path: '/',
    name: 'home',
    component: Auth,
    beforeEnter: (to, from, next) => {
      if (store.getters.getUser.id) {
        next('/home');
      } else {
        next();
      }
    }
  },
  {
    path: '/home',
    name: 'inicio',
    component: Home,
    meta: {
      middleware: [authMiddleware]
    }
  },
  // {
  //   path: '/auth',
  //   name: 'auth',
  //   component: Auth,
  //   beforeEnter: (to, from, next) => {
  //     if (store.getters.getUser.id) {
  //       next('/home');
  //     } else {
  //       next();
  //     }
  //   }
  // },
  {
    path: '/register-user',
    name: 'register-user',
    component: RegisterUser
  },
  {
    path: '/view-user',
    name: 'view-user',
    component: ViewUserVue,
    meta: {
      middleware: [authMiddleware, authAdmin]
    }
  },
  {
    path: '/view-user/:id',
    name: 'view-user2',
    component: ViewUserVue,
    meta: {
      middleware: [authAdmin]
    }
  },
  {
    path: '/register-client',
    name: 'register-client',
    component: RegisterClient,
    meta: {
      middleware: [authAdmin]
    }
  },
  {
    path: '/view-client/:id',
    name: 'view-client',
    component: ViewClient,
    meta: {
      middleware: [authAdmin]
    }
  },
  {
    path: '/register-process',
    name: 'register-process',
    component: CreateProcesses,
    meta: {
      middleware: [authMiddleware, authAdminDesigner]
    }
  },
  {
    path: '/printer',
    name: 'printer',
    component: PrinterProcess,
    meta: {
      middleware: [authAdminPrinter]
    }
  },
  {
    path: '/lecturer',
    name: 'lecturer',
    component: LecturerProcess,
    meta: {
      middleware: [authAdminLecuter]
    }
  },
  {
    path: '/view-process/:id',
    name: 'view-process',
    component: ViewProcess,
    meta: {
      middleware: [authMiddleware]
    }
  },
  {
    path: '/generate-token',
    name: 'generate-token',
    component: GenerateToken,
    meta: {
      middleware: [authAdmin]
    }
  },
  {
    path: '/list-users',
    name: 'list-users',
    component: ListUsers,
    meta: {
      middleware: [authAdmin],
      previousRoute: null
    },
    beforeEnter(to, from, next) {
      store.commit("setPagePrevious", to.path)
      next();
    }
  },
  {
    path: '/list-clients',
    name: 'list-clients',
    component: ListClient,
    meta: {
      middleware: [authAdmin],
      previousRoute: null
    },
    beforeEnter(to, from, next) {
      store.commit("setPagePrevious", to.path)
      next();
    }
  },
  {
    path: '/material',
    name: 'material',
    component: ListMaterial,
    meta: {
      middleware: [authAdmin]
    }
  },
  {
    path: '/machine',
    name: 'machine',
    component: ListMachineVue,
    meta: {
      middleware: [authAdminPrinter]
    }
  },
  {
    path: '/estoque',
    name: 'estoque',
    component: ListEstoqueVue,
    meta: {
      middleware: [authAdminPrinter]
    }
  },

  {
    path: '/ponto',
    name: 'ponto',
    component: Ponto,
    meta: {
      middleware: [authMiddleware]
    }
  },

  {
    path: '/data_manipulation',
    name: 'data_manipulation',
    component: DataManipulation,
    meta: {
      middleware: [authMiddleware]
    }
  },

  {
    path: '/chat',
    name: 'chat',
    component: Chat,
    meta: {
      middleware: [authMiddleware]
    }
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: DeliveryProcess,
    meta: {
      middleware: [authAdmin]
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashBoard,
    meta: {
      middleware: [authAdmin]
    }
  },
  {
    path: '/view-orders/:client',
    name: 'view-orders',
    component: ViewOrders,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  if (to.meta.middleware) {
    const middleware = to.meta.middleware;
    middleware.forEach((mw) => mw(to, from, next));
  } else {
    next();
  }
});

export default router;
