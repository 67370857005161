import { baseURL } from "../http";
import io from "socket.io-client";
import store from "../store";
import { getterENV } from "../environment";
import { getDateBrazil } from "../utils";

const SET_CHANNEL = getterENV.socket.channel.SET_CHANNEL;
const GET_PROCESS = getterENV.socket.process.GET_PROCESS;
const SEND_PROCESS = getterENV.socket.process.SEND_PROCESS;
const GET_PROCESS_STAGE = getterENV.socket.process.GET_PROCESS_STAGE;
const SEND_PROCESS_STAGE = getterENV.socket.process.SEND_PROCESS_STAGE;
const GET_USER = getterENV.socket.user.GET_USER;
const SET_USER = getterENV.socket.user.SET_USER;
const GET_CLIENT = getterENV.socket.client.GET_CLIENT;
const SET_CLIENT = getterENV.socket.client.SET_CLIENT;
const GET_NOTIFICATION = getterENV.socket.notification.GET_NOTIFICATION;
const SET_NOTIFICATION = getterENV.socket.notification.SET_NOTIFICATION;
const GET_MATERIAL = getterENV.socket.material.GET_MATERIAL;
const SET_MATERIAL = getterENV.socket.material.SET_MATERIAL;
const GET_MACHINE = getterENV.socket.machine.GET_MACHINE;
const SET_MACHINE = getterENV.socket.machine.SET_MACHINE;
const GET_CHAT = getterENV.socket.chat.GET_CHAT;
const SET_CHAT = getterENV.socket.chat.SET_CHAT;
const GET_NOTIFICATION_MESSAGE = getterENV.socket.chat.GET_NOTIFICATION 
const SET_NOTIFICATION_MESSAGE = getterENV.socket.chat.SET_NOTIFICATION

export const socket = io( baseURL , { transports: ["websocket"] });

export const setChannel = (nameChannel) => {
  socket.emit( SET_CHANNEL , nameChannel);
}

export const closeChannel = (nameChannel) => {
  socket.emit( 'close_channel' , nameChannel);
}

export const getReponseProcess =  () => {
   return socket.on( GET_PROCESS , async (process) => {
    store.dispatch("setNewProcess", process);
    await store.dispatch("getProcessesDelivery");
    return process;
  });
}

export const sendEventProcess = (channel, data) => {
    data.delivery = true;
    socket.emit( SEND_PROCESS , { channel: channel, data: data });
}

export const getReponseStageProcess = () => {
   return socket.on( GET_PROCESS_STAGE , async (processStage) => {

    if(store.state.userCurrent.type_user == "C" || store.state.userCurrent.type_user !== "A"){
      store.dispatch("setUpdatedProcess", processStage)
      await store.dispatch("setProcessListLecturerPrinterCurrent", {data: {stage: ["I", "A"]}, status: 'A', date: getDateBrazil()});
    }else{
      store.dispatch("setUpdatedProcess", processStage)
    }

    return processStage;
  });
}

export const sendEventStageProcess = (channel, data) => {
    data.delivery = true;
    socket.emit( SEND_PROCESS_STAGE , { channel: channel, data: data });
}

export const getReponseUsers = () => {
  return socket.on( GET_USER , async (user) => {

   if(store.state.userCurrent.type_user == "A" && user){
    await store.dispatch("getUsers");
   }

   return user;
 });
}

export const sendEventUsers = (channel, data) => {
  socket.emit( SET_USER , { channel: channel, data: data });
}

export const getReponseClients = () => {
  return socket.on( GET_CLIENT , async (client) => {
   if((store.state.userCurrent.type_user == "A" || store.state.userCurrent.type_user == "D") && client){
    await store.dispatch("setClientListCurrent");
   }

   return client;
 });
}

export const sendEventClients = (channel, data) => {
  socket.emit( SET_CLIENT , { channel: channel, data: data });
}

export const getReponseNotification = () => {
  
  socket.on(GET_NOTIFICATION, async (notification) => {
    document.getElementById('botaoOculto').addEventListener('click', async () => {
      try {
          const meuAudio = document.getElementById('meuAudio');
          await meuAudio.play();
      } catch (error) {
        return error;
      }
  });
  document.getElementById('botaoOculto').click();

  return notification;
  });
}

export const sendEventNotification = (channel, data) => {
  socket.emit( SET_NOTIFICATION , { channel: channel, data: data });
}

export const getReponseMaterial = () => {
  return socket.on( GET_MATERIAL , async (material) => {
    store.dispatch('setMaterialList', material);
    return material;
 });
}

export const sendEventMaterial = (channel, data) => {
  socket.emit( SET_MATERIAL, { channel: channel, data: data });
}

export const getReponseMachine = () => {
  return socket.on( GET_MACHINE , async (material) => {
    store.dispatch('setMachineList', material);
    return material;
 });
}

export const sendEventMachine = (channel, data) => {
  socket.emit( SET_MACHINE, { channel: channel, data: data });
}

let isListenerAdded = false;

export const getReponseChat = () => {
  if (!isListenerAdded) {
    socket.on(GET_CHAT, async (message) => {
      if (message?.newMessage) {
        store.commit('notifyMessage', message);
        store.dispatch('upadatedInfoRoom', message.message);
      }

      if (message?.id) {
        await store.dispatch('setUpdatedChat', message);
        store.dispatch('upadatedInfoRoom', message);
      }
      return message;
    });

    isListenerAdded = true;
  }
};


export const sendEventChat = (channel, data) => {
  socket.emit( SET_CHAT, { channel: channel, data: data });
}

export const getReponseNotificationMessage = () => {
  socket.on(GET_NOTIFICATION_MESSAGE, async (notification) => {
    const botaoOculto = document.getElementById('botaoOcultoMessage');
    if (botaoOculto && !botaoOculto.hasEventListener) {
      botaoOculto.addEventListener('click', async () => {
        try {
          const meuAudio = document.getElementById('meuAudioMessage');
          await meuAudio.play();
        } catch (error) {
          return error;
        }
      });
      botaoOculto.hasEventListener = true;
    }

    if (botaoOculto) {
      botaoOculto.click();
    }

    return notification;
  });
};


export const sendEventNotificationMessage = (channel, data) => {
  socket.emit( SET_NOTIFICATION_MESSAGE , { channel: channel, data: data });
}