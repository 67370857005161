<template>
  
    <div class="container">
      <!-- <div v-if="!stockListHistory?.length" class="alert alert-light mt-3">
        <h5 class="custom-mt text-center mb-5">
          <font-awesome-icon icon="circle-exclamation" />
          Atualmente, não há registros disponiveís.
        </h5>
      </div> -->
      <div class="d-flex flex-row justify-content-between align-items-center">
        <p class="d-flex align-items-center ms-2 mb-2">
          {{filteredStockHistory?.length > 0 ? filteredStockHistory?.length  + ' resultado encontrado.' : ''}}
        </p>
        <button
            class="btn btn-sm btn-outline-dark d-flex align-items-center mb-2 me-3"
            @click="opeBlockFilter"
        >
            <font-awesome-icon
                icon="arrow-up-wide-short"
                class="me-2"
                v-if="openFilter"
            />
            <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
            Filtrar
        </button>
    </div>
    
      <!-- Filtros de pesquisa -->
      <div class="row mb-4 d-flex justify-content-end" v-if="openFilter">
        <div class="col-md-3">
          <label for="filtroStatus" class="form-label filtros">Status:</label>
          <select id="filtroStatus" class="form-select" v-model="typeStock">
            <option value="" selected>Todos</option>
            <option value="E">Entrada</option>
            <option value="S">Saída</option>
          </select>
        </div>
        <div class="col-md-3">
          <label for="filtroData" class="form-label filtros">Data Inicial:</label>
          <input
            id="filtroData"
            type="date"
            class="form-control"
            value="2024-01-01"
            v-model="dateStart"
          />
        </div>
        <div class="col-md-3">
          <label for="filtroData" class="form-label filtros">Data Final:</label>
          <input
            id="filtroData"
            type="date"
            class="form-control"
            value="2024-01-01"
            v-model="dateEnd"
          />
        </div>
      </div>
  
      <div class="table-responsive mb-5">
        <template
              v-for="item in (stockListHistory || [])"
              :key="item.id"
            >
        <p><span :class="item.type == 'E' ? 'text-success fw-bold fs-4' : 'text-danger fw-bold fs-4'">{{ typeStatus(item.type) }}: </span> <span class="text-dark fw-light fs-5"> Qtd/Medidas: </span><span :class="item.type == 'E' ? 'text-dark fst-italic fw-bold fs-5' : 'text-dark fst-italic fw-bold fs-5'"> {{ item.measure }} - </span> <span class="text-dark fw-light fs-5"> Data de registro: </span><span class="text-dark fst-italic fw-bold fs-5"> {{ convertDate(item.createdAt) }} </span></p>
        </template>
      </div>
    </div>
  
    <div>
      <h6
        v-if="!stockListHistory?.length && (dateStart || dateEnd)"
        class="text-secondary custom-mt text-center"
      >
        Desculpe, não encontramos resultados correspondentes à essa data .
        <br/>
        Tente ajustar os filtros para obter melhores resultados.
      </h6>
    </div>
  </template>
  
<script>
  import { computed, onBeforeMount, ref, watch } from "vue";
  import store from "../../store";
  import { mask } from "vue-the-mask";
  import { 
    // getDateBrazil, 
    convertDate} from "../../utils";
import { getStockHistoryForDate } from '@/http';

  export default {
    props: {},
    directives: { mask },
    setup() {
      const stockListHistory = computed(() => store.state.stockListHistory?.slice().sort((a, b) => b.id - a.id) || []);
  
      const typeStatus = (type) => {
        switch (type) {
          case "E":
            return "Entrada";
          case "S":
            return "Saída";
        }
      };

      const dateStart = ref('');
      const dateEnd = ref('');
      const typeStock = ref('');
  
      const formatLabelDate = (date) => {
        const arrDate = date.split("-");
        return date ? arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0] : "";
      };
  
      const openFilter = ref(false);
      const opeBlockFilter = () => {
        openFilter.value = openFilter.value ? false : true;
      };
  
      onBeforeMount(async () => {
        await store.dispatch("setStockListHistoryCurrent");
      });

      watch(dateStart, async (newValue) => {
        if(newValue && dateEnd.value){
            let requestStockHistory = await getStockHistoryForDate(newValue, dateEnd.value, store.state.typeEventStock.stock);
            store.commit('setStockHistory', requestStockHistory);
        }else{
          await store.dispatch("setStockListHistoryCurrent");
        }
      });

      watch(dateEnd, async (newValue) => {
        if(newValue && dateStart.value){
            let requestStockHistory = await getStockHistoryForDate(dateStart.value, newValue, store.state.typeEventStock.stock);
            store.commit('setStockHistory', requestStockHistory);
        }else{
          await store.dispatch("setStockListHistoryCurrent");
        }
      });

      watch(typeStock, async (newValue) => {
        if(newValue.length > 0 && !dateEnd.value && !dateStart.value){ 
          await store.dispatch("setStockListHistoryCurrent") 
          let historyFilted = store.state.stockListHistory.filter((stock) => stock.type == newValue);
          store.commit('setStockHistory', historyFilted);
        }else if(newValue.length > 0 && (dateEnd.value || dateStart.value)){
          let requestStockHistory = await getStockHistoryForDate(dateStart.value, dateEnd.value, store.state.typeEventStock.stock);
          let historyFilted = requestStockHistory.filter((stock) => stock.type == newValue);
          store.commit('setStockHistory', historyFilted);
        }else if(!newValue.length && (dateEnd.value && dateStart.value)){
          let requestStockHistory = await getStockHistoryForDate(dateStart.value, dateEnd.value, store.state.typeEventStock.stock);
          store.commit('setStockHistory', requestStockHistory);
        }else{
          await store.dispatch("setStockListHistoryCurrent")
        }
      });
  
      return {
        stockListHistory,
        opeBlockFilter,
        openFilter,
        convertDate,
        typeStatus,
        formatLabelDate,
        dateStart,
        dateEnd,
        typeStock,
      };
    },
  };
</script>
<style scoped>
  .custom-mt {
    margin-top: 70px;
  }
  
  .custom-table {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 80px;
  }
  
  .filtros {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .overlay-background {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .overlay-background2 {
  
    background-color: rgba(0, 0, 0, 0); 
  }
  
  /* CSS para ajustar a largura e altura da imagem dentro do modal */
  .modal-dialog {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
  }
  
  .modal-body {
    padding: 0;
  }
  
  .enlarged-image {
    width: 80%;
    height: 80%;
    object-fit: contain;
  
  }
  </style>
  