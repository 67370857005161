import axios from "axios";
import { getConfig } from "../environment";

const getConfigHttp = getConfig();
const endPoit = getConfigHttp.end_point;
export const baseURL = getConfigHttp.http.baseURL;
// export const baseURL = 'http://localhost:7000';

const httpClient = axios.create({
    baseURL,
    headers: {
        'Accept': 'application/json',
        'Content': 'application/json',
    }
})

//user
export const login = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.login, payload)
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getAuths = async (date) => {
    try {
        const response = await httpClient.get(endPoit.auth + '?date=' + date)
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getUsers = async () => {
    try {
        const response = await httpClient.get(endPoit.user);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getUser = async (id) => {
    try {
        const response = await httpClient.get(endPoit.user + "/" + id);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createUser = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.user, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const editUser = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.user}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const deleteUser = async (id) => {
    try {
        const response = await httpClient.delete(`${endPoit.user}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

// client
export const getClients = async () => {
    try {
        const response = await httpClient.get(endPoit.client);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getClient = async (id) => {
    try {
        const response = await httpClient.get(`${endPoit.client}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createClient = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.client, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const editClient = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.client}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const deleteClient = async (id) => {
    try {
        const response = await httpClient.delete(`${endPoit.client}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

// process
export const getProcesses = async (date) => {
    try {
        if (date) {
            const response = await httpClient.get(`${endPoit.process.process}?date=${date}`);
            return response.data;
        }
        return;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessesForDate = async (dateStart, dateEnd) => {
    try {
        
        const response = await httpClient.get(`${endPoit.process.processInBetween}?dateStart=${dateStart}&dateEnd=${dateEnd}`);
        return response.data;
        
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessesMonth = async () => {
    try {
        const response = await httpClient.get(endPoit.process.processMonth);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessesMachine = async (date, machine) => {
    try {
        if (date) {
            const response = await httpClient.get(`${endPoit.process.processMachine}?date=${date}&machine=${machine}`);
            return response.data;
        }
        return;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessesClient = async (client) => {
    try {
        if (client) {
            const response = await httpClient.get(`${endPoit.process.processClient}?clientId=${client}`);
            return response.data;
        }
        return;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcess = async (id) => {
    try {
        const response = await httpClient.get(`${endPoit.process.process}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessDesign = async (id, date) => {
    try {
        const response = await httpClient.get(`${endPoit.process.processDesign}/${id}?date=${date}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessAllFind = async (payload, status, date) => {
    try {
        const response = await httpClient.post(`${endPoit.process.process}-all?status=${status}&date=${date}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessPrinter = async (id = 0) => {
    try {
        let httpEndPoint = endPoit.process.processPrinter;
        if (id != 0) {
            httpEndPoint += '/' + id;
        }
        const response = await httpClient.get(httpEndPoint);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessExist = async (designer) => {
    try {
        let httpEndPoint = endPoit.process.processExist;
        if (designer) {
            httpEndPoint += '?id=' + designer;
        }

        const response = await httpClient.get(httpEndPoint);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessPendding = async () => {
    try {
        const response = await httpClient.get(endPoit.process.processPendding);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessFinished = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.process.processFinished}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getProcessLecturer = async (id = 0) => {
    try {
        let httpEndPoint = endPoit.process.processLecturer;
        if (id != 0) {
            httpEndPoint += '/' + id;
        }
        const response = await httpClient.get(httpEndPoint);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createProcessDesign = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.process.processDesign, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const sendProcessComment = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.process.processComment, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createProcessPrinter = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.process.processPrinter}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createProcessLecturer = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.process.processLecturer}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const editProcess = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.process.process}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const stageProcess = async (id, stage, machine = null, member) => {
    try {
        let payload = { stage: stage, member: member }
        if (machine) {
            payload['machine'] = machine
        }
        const response = await httpClient.put(`${endPoit.process.processStage}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const finishingProcess = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.process.processFinishing}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}


export const deleteProcess = async (id) => {
    try {
        const response = await httpClient.delete(`${endPoit.process.process}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getCommentsProcess = async (process) => {
    try {
        const response = await httpClient.get(`${endPoit.process.comment}/${process}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getImageProcess = async (process) => {
    try {
        const response = await httpClient.get(`${endPoit.process.image}/${process}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

//token
export const getToken = async (token) => {
    try {
        const response = await httpClient.post(endPoit.token, { token: token });
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}
export const createToken = async (type_register) => {
    try {
        const response = await httpClient.post(endPoit.token + '-new', { type_register: type_register });
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const finishedToken = async (id) => {
    try {
        const response = await httpClient.put(`${endPoit.token}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

// Material

export const getMaterials = async () => {
    try {
        const response = await httpClient.get(endPoit.material);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getMaterialFind = async (id) => {
    try {
        const response = await httpClient.get(`${endPoit.material}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getListMaterials = async (materials) => {
    try {
        const response = await httpClient.post(`${endPoit.material}s`, { materials: materials });
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createMaterial = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.material, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const editMaterial = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.material}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const deleteMaterial = async (id) => {
    try {
        const response = await httpClient.delete(`${endPoit.material}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

// Machine

export const getMachines = async () => {
    try {
        const response = await httpClient.get(endPoit.machine);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getMachineFind = async (id) => {
    try {
        const response = await httpClient.get(`${endPoit.machine}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getListMachines = async (machines) => {
    try {
        const response = await httpClient.post(`${endPoit.machine}s`, { machines: machines });
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createMachine = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.machine, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const editMachine = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.machine}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const deleteMachine = async (id) => {
    try {
        const response = await httpClient.delete(`${endPoit.machine}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}
// Delivery

export const getDeliverys = async () => {
    try {
        const response = await httpClient.get(endPoit.delivery);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getDeliveryFind = async (id) => {
    try {
        const response = await httpClient.get(`${endPoit.delivery}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getListDeliverys = async (deliverys) => {
    try {
        const response = await httpClient.post(`${endPoit.delivery}s`, { deliverys: deliverys });
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createDelivery = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.delivery, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const editDelivery = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.delivery}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const deleteDelivery = async (id) => {
    try {
        const response = await httpClient.delete(`${endPoit.delivery}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

// rooms

export const getRooms = async (userId) => {
    try {
        const response = await httpClient.get(endPoit.room + '?userId=' + userId);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getRoomFind = async (id) => {
    try {
        const response = await httpClient.get(`${endPoit.room}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createRoom = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.room, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

// messages

export const getMessages = async (roomId) => {
    try {
        const response = await httpClient.get(endPoit.message.message + '?roomId=' + roomId);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getMessageNotViewAndLast = async (roomId, userId) => {
    try {
        const response = await httpClient.get(endPoit.message.messageViewLast + `?roomId=${roomId}&userId=${userId}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getMessageFind = async (id) => {
    try {
        const response = await httpClient.get(`${endPoit.message.message}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createMessage = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.message.message, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const setMessageView = async (id, payload) => {
    try {
        const response = await httpClient.put(endPoit.message.messageView + `/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

//stock
export const getStock = async () => {
    try {
        const response = await httpClient.get(endPoit.stock.$)
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getStockFind = async (id) => {
    try {
        const response = await httpClient.get(endPoit.stock.$ + `/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getStockHistory = async (idStock) => {
    try {
        const response = await httpClient.get(endPoit.stock.history + `/${idStock}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getStockHistoryForDate = async (startDate, endDate, IdStock) => {
    try {
        const response = await httpClient.get(endPoit.stock.historyInBetween + `?dateStart=${startDate}&dateEnd=${endDate}&stock=${IdStock}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createStock = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.stock.$, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const editStock = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.stock.$}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const deleteStock= async (id) => {
    try {
        const response = await httpClient.delete(`${endPoit.stock.$}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

// HitPoint
export const getPointFind = async (id) => {
    try {
        const response = await httpClient.get(endPoit.hitPoint.$ + `/${id}`)
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getPointAllFindUser = async (id) => {
    try {
        const response = await httpClient.get(endPoit.hitPoint.inUser + `/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getPointAmongsDate = async (startDate, endDate, IdUser) => {
    try {
        const response = await httpClient.get(endPoit.hitPoint.inBetween + `?dateStart=${startDate}&dateEnd=${endDate}&user=${IdUser}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getPointForDate = async (date) => {
    try {
        const response = await httpClient.get(endPoit.hitPoint.inDate + `?date=${date}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getPointVerifitedTodayUser = async (user, date) => {
    try {
        const response = await httpClient.get(endPoit.hitPoint.inVerifitedTodayUser + `?date=${date}&user=${user}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createPoint = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.hitPoint.$, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const EditPoint = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.hitPoint.$}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const deletePoint = async (id) => {
    try {
        const response = await httpClient.delete(`${endPoit.hitPoint.$}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}
 
//lack-material  
export const getLackMaterialFind = async (id) => { 
    try {
        const response = await httpClient.get(endPoit.lackMaterial.$ + `/${id}`)
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const getLackMaterialForDate = async (startDate, endDate) => {
    try {
        const response = await httpClient.get(endPoit.lackMaterial.inBetween + `?dateStart=${startDate}&dateEnd=${endDate}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const createLackMaterial = async (payload) => {
    try {
        const response = await httpClient.post(endPoit.lackMaterial.$, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const editLackMaterial = async (id, payload) => {
    try {
        const response = await httpClient.put(`${endPoit.lackMaterial.$}/${id}`, payload);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}

export const deleteLackMaterial = async (id) => {
    try {
        const response = await httpClient.delete(`${endPoit.lackMaterial.$}/${id}`);
        return response.data;
    } catch (err) {
        return err?.response?.data;
    }
}
