<template>
  <footer class="bg-dark text-center text-lg-start footer container-fluid">
    <div class="text-center text-light p-3">
      © 2024 Copyright
      <b>developed by</b> -
      <!-- <a
        target="_blank"
        class="no-underline"
        href="https://github.com/mateus3690"
      > -->
      <a
        class="no-underline"
        href="http://www.copycenterdigital.com.br"
      >
        <!-- <i>Mateus Santos</i> -->
        <i>CopyCenter</i>
      </a>

      <!-- <span class="space"></span> -->

      <!-- <a
        target="_blank"
        class="no-underline"
        href="https://github.com/ThiagoTenorio"
      >
        <i>Thiago Tenorio</i>
      </a> -->
      <FloatingBall style="top: 135px;" />
    </div>
  </footer>
</template>

<script>
import FloatingBall from "@/components/FloatingBall.vue";
export default {
  name: "FooterVue",
  components: {
    FloatingBall,
  },
};
</script>

<style>
.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.no-underline {
  color: white;
  text-decoration: none; /* Remove o sublinhado */
}

.no-underline:hover {
  color: #bdbdbd;
}

.space {
  margin-right: 10px; /* Adicione a quantidade de margem desejada */
}
</style>
