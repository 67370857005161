// require('dotenv/config');

const makeBaseUrl = (protocol, url, port) => {
    return `${protocol}://${url}:${port}`
}

export const getConfig = () => {
    const config = {
        http: {
            baseURL: makeBaseUrl(
                process.env.BASE_PROTOCOL,
                process.env.BASE_URL,
                process.env.BASE_PORT
            )
        },
        socket: {
            channel:{
                SET_CHANNEL: 'new_channel',
                CLOSE_CHANNEL: 'close_channel'
            },
            process:{
                GET_PROCESS: 'response_process',
                SEND_PROCESS: 'event_process',
                GET_PROCESS_STAGE: 'response_stage_process',
                SEND_PROCESS_STAGE: 'event_stage_process',
            },
            user: {
                GET_USER: 'response_user',
                SET_USER: 'event_user'   
            },
            client: {
                GET_CLIENT: 'response_client',
                SET_CLIENT: 'event_client'
            },
            notification: {
                GET_NOTIFICATION: 'response_notification',
                SET_NOTIFICATION: 'event_notification'
            },
            material: {
                GET_MATERIAL: 'response_material',
                SET_MATERIAL: 'event_material'
            },
            machine: {
                GET_MACHINE: 'response_machine',
                SET_MACHINE: 'event_machine'
            },
            chat: {
                GET_CHAT: 'response_chat',
                SET_CHAT: 'event_chat',
                GET_NOTIFICATION: 'response_notification_message',
                SET_NOTIFICATION: 'event_notification_message'
            },
        },
        end_point: {
            user: 'user',
            login: 'login',
            auth: 'auth',
            token: 'token',
            process: {
                processDesign: 'process-design',
                processPrinter: 'process-printer',
                processLecturer: 'process-lecturer',
                processStage: 'process-stage',
                processFinishing: 'process-finishing-process',
                process: 'process',
                comment: 'process-comments',
                image: 'process-images',
                processExist: 'process-exist',
                processMachine: 'process-machines',
                processPendding: 'process-pendding',
                processFinished: 'process-finished',
                processMonth: 'process-month',
                processClient:  'process-client',
                processComment: 'process-comment',
                processInBetween: 'process-all-between-date'
            },
            client: 'client',
            material: 'material',
            machine: 'machine',
            delivery: 'delivery',
            room: 'room',
            message: {
                message:'message', 
                messageViewLast: 'message-view-last',
                messageView: 'message-view'
            },
            stock: {
                $: 'stock',
                history: 'stock-history',
                historyInBetween: 'stock-history-in-between'
            },
            hitPoint: {
                $: 'point',
                inBetween: 'point_in_between',
                inDate: 'point-in-date',
                inUser: 'point-user',
                inVerifitedTodayUser: 'point-verifited-point-today-user'
            },
            lackMaterial: {
                $: 'lack-material',
                inBetween: 'lack-material-in-between'
            }
        },
    }

    return Object.assign({}, config)
}

export const getterENV = getConfig();