<template>
    <div class="container-fluid my-1">
        <div v-if="$_store.state.typeEventMachine.type === 'E'">
            <div class="ps-2 pe-2 mb-3" >
                <label for="name" class="form-label">Nome do Maquina</label>
                <input
                    v-model="machine"
                    type="text"
                    class="form-control"
                    id="name"
                    required
                    :placeholder="$_store.state.typeEventMachine.nameMachine"
                />
                <p class="p-error-label">{{ errorLabel?.machine || errorLabel?.error }}</p>
            </div>

            <div class="d-flex justify-content-center mb-2">
                <button 
                    class="btn btn-dark me-1 col-6" 
                    @click="sendEditMachine"
                    :disabled="!machine ? true : false"
                >
                Confirmar
                </button>
                <button 
                    id="closeButtonEdit"
                    class="btn btn-outline-danger ms-1 col-6" 
                    data-bs-dismiss="modal" 
                    aria-label="Close">
                    Cancelar
                </button>
            </div> 
        </div>
        <div v-else>
            <div class="d-flex justify-content-center mb-2">
                <button 
                    class="btn btn-dark me-1 col-6"
                    @click="sendDeleteMachine"
                >
                Confirmar
                </button>
                <button 
                    id="closeButton" 
                    class="btn btn-outline-danger ms-1 col-6" 
                    data-bs-dismiss="modal" 
                    aria-label="Close">
                    Cancelar
                </button>
            </div> 
        </div> 
    </div>
</template>

<script>
import { ref } from 'vue';
import store from "../../store";
import { editMachine, deleteMachine } from "../../http"
import { sendEventMachine } from "../../socket";

export default {
setup() {

  const  machine = ref('');
  const errorLabel = ref({
    machine: null,
    error: null
  })

  const sendEditMachine = async () => {
    if(machine.value){
        const payload = {id: store.state.typeEventMachine.machine, name: machine.value};
        await editMachine(store.state.typeEventMachine.machine, payload)
            .then((data)=>{
            if(!data?.status){
                errorLabel.value.error = data?.message;
                return;
            }
            
            store.dispatch('setMachineList', payload);
            sendEventMachine('graphic', payload);
            machine.value = '';
            document.getElementById("closeButtonEdit").click();

            });
    }else{
        errorLabel.value.machine = "Campo obrigatório.";
    }
  }

  const sendDeleteMachine = async () => {
    await deleteMachine(store.state.typeEventMachine.machine)
        .then(() => {
            const data = {id: store.state.typeEventMachine.machine, delete: true};
            store.dispatch('setMachineList', data);
            sendEventMachine('graphic', data);
            document.getElementById("closeButton").click();
    });

  }

  return {
      machine,
      sendEditMachine,
      sendDeleteMachine
  };
}
};
</script>

<style>
.p-error-label {
  font-size: 14px;
  color: red;
}
</style>
