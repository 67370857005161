<template>

  <nav class="navbar navbar-dark bg-dark navbar-expand-lg" v-if="user.id">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" >
            <router-link to="/" class="navbar-brand text-light logoNav">
              <img
                src="../assets/logo2.png"
                alt=""
                class=""
              />
            </router-link>
          </li>
          <li v-if="!user.type_user.includes('A')" class="nav-item ms-2">
            <router-link to="/ponto" class="nav-link text-white ponto">
              Ponto eletronico
            </router-link>
          </li>
            
          <li v-if="user.type_user.includes('A')" class="nav-item dropdawn">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item dropdown dropleft">
                  <a
                    class="nav-link dropdown-toggle text-white option"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Processos
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                      @click="navigateAndReload('/register-process')"
                        class="text-light dropdown-item clickable"
                        >Designer</a
                      >
                    </li>
                    <li>
                      <a
                        @click="navigateAndReload('/printer')"
                        class="dropdown-item clickable"
                        >Impressor
                      </a
                      >
                    </li>
                    <!-- <li>
                      <a
                      @click="navigateAndReload('/lecturer')"
                        class="dropdown-item clickable"
                        >Conferente</a
                      >
                    </li> -->
                    <li>
                      <a
                      @click="navigateAndReload('/delivery')"
                        class="dropdown-item clickable"
                        >Entrega</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="user.type_user.includes('A')" class="nav-item">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item dropdown dropleft">
                  <a
                    class="nav-link dropdown-toggle text-white option"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Usuários
                  </a>
                  <ul class="dropdown-menu">
                    <li v-if="user.type_user === 'A'">
                      <router-link
                        to="/generate-token"
                        class="text-light dropdown-item"
                        >Gerar Token de Acesso</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/list-users" class="dropdown-item"
                        >Lista de Usuários</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="user.type_user.includes('A')" class="nav-item">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item dropdown dropleft">
                  <a
                    class="nav-link dropdown-toggle text-white option"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Clientes
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                        to="/register-client"
                        class="text-light dropdown-item"
                        >Registar novo cliente</router-link
                      >
                    </li>
                    <li v-if="user.type_user.includes('A')">
                      <router-link to="/list-clients" class="dropdown-item"
                        >Lista de Clientes</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="user.type_user.includes('A')" class="nav-item">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item dropdown dropleft">
                  <a
                    class="nav-link dropdown-toggle text-white option"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                  Dados de Produção
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                        to="/material"
                        class="text-light dropdown-item"
                        >Material</router-link
                      >
                    </li>
                    <li v-if="user.type_user.includes('A')">
                      <router-link to="/machine" class="dropdown-item"
                        >Máquina</router-link
                      >
                    </li>
                    <li v-if="user.type_user.includes('A')">
                      <router-link to="/estoque" class="dropdown-item"
                        >Estoque</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="user.type_user == 'I'" class="nav-link text-white ">
            <router-link class="dropdown-item" to="/machine">Máquina</router-link>
          </li>
          <li v-if="user.type_user.includes('A')" class="nav-item">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item dropdown dropleft">
                  <a
                    class="nav-link dropdown-toggle text-white option"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Ferramentas
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a href="https://calcularprecos.netlify.app" target="_blank" class="dropdown-item">
                        Calculadora de Preços
                      </a>
                    </li>
                    <li v-if="user.type_user.includes('A')">
                      <a href="http://www.copycenterdigital.com.br:8082/" target="_blank" class="dropdown-item">
                        Gerador Cupom
                      </a>
                    </li>
                    <li v-if="user.type_user === 'A'">
                      <router-link to="/dashboard" class="dropdown-item">
                        Dashboard
                      </router-link>
                    </li>
                    <li v-if="user.type_user.includes('A')" class="navbar-nav">
                      <router-link to="/ponto" class="dropdown-item">
                        Ponto eletronico
                      </router-link>
                    </li>
                    <li v-if="user.type_user.includes('A')" class="navbar-nav">
                      <router-link to="/data_manipulation" class="dropdown-item">
                        Manipulação de Dados
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div class="d-flex align-items-center">
        <div class="userNav" v-if="user">
          <router-link to="/view-user" class="nav-link"
            >Bem-vindo - {{ user.name }}
          </router-link>
        </div>

        <div
          class="collapse navbar-collapse userFinish"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item dropdown dropleft me-5">
              <button
                class="nav-link dropdown-toggle btn-hover"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="m-1 fa-solid fa-circle-user text-dark"></i>
              </button>
              <ul class="dropdown-menu">
                <li v-if="user" class="">
                  <a class="dropdown-item text-center clickable" @click="exit()"
                    >Sair <i class="fa-solid fa-arrow-right-from-bracket"></i
                  ></a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<script>
import { computed } from "vue";
import { useRouter } from 'vue-router';
import store from "../store";

export default {
  name: "NavBar",
  props: {},
  setup() {
    const user = computed(() => store.state.userCurrent);
    const router = useRouter();

    const exit = () => {
      sessionStorage.clear();
      location.reload();
    };

    const navigateAndReload = (route) => {
      router.push(route).then()
    }

    return {
      exit,
      user,
      navigateAndReload,
    };
  },
};
</script>

<style scoped>
/*colocar o estilo da página ou componente*/
img {
  margin-left: 100px;
  width:134px;
}

@media screen and (max-width: 992px) {
  img {
    margin-left: 0;
    margin-top: 15px;
    width:100px;
  }
}

.userNav {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.userFinish {
  margin-right: 70px;
}

.userNav:hover {
  color: #bdbdbd;
  border-bottom: #f4f4f4 solid 2px !important; 
  border-radius: 4px;
}

.dropdown-menu {
  /* Define a largura mínima e a cor de fundo do dropdown */
  min-width: 100px;
  background-color: #212529;
}

.dropdown-item {
  color: white;
}

.dropdown-item:hover {
  background-color: #212529;
  color: #bdbdbd;
  font-weight: bold;
  border-bottom: #f4f4f4 solid 2px !important; 
  border-radius: 4px;
}
.clickable {
  cursor: pointer;
}

.option {
  color: white;
}

.option:hover{
  background-color: #212529; 
  color: #bdbdbd;
  font-weight: bold;
  border-bottom: #f4f4f4 solid 2px !important; 
  border-radius: 5px;
}

.btn-hover{
  color: white;
}

.btn-hover:hover{
  font-weight: bold;
  color: #737374; 
}

.ponto:hover {
  background-color: #212529;
  color: #bdbdbd;
  font-weight: bold;
  border-bottom: #f4f4f4 solid 2px !important; 
  border-radius: 4px;
}
</style>
