<template>
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered overlay-background2">
      <div class="modal-content overlay-background">
        <div class="m-2 mt-2 d-flex align-items-center justify-content-end flex-wrap">
          <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mb-5">
          <img height="auto" width="auto"  :src="selectedImg" class="text-white enlarged-image" alt="ops.. sem imagem">
        </div>
      </div>
    </div>
  </div>

  <div class="container" v-if="!off">
    <div class="alert alert-warning alert-dismissible fade show text-center" role="alert" style="margin-top:6rem;padding:5rem;">
        <b>Essa página está desligada!!</b>
    </div>
  </div>
  <div class="container" v-else>

    <h1 class="text-center mt-4">Serviços para conferência em aberto</h1>

    <div class="row custom-mt">
      <div class="col-sm-12 col-lg-12 mb-3">
        <router-link
          to="/"
          class="btn btn-outline-danger col-2 mb-3"
          @click="backHome"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-bar-left"
            viewBox="0 0 16 16"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
          </svg>
          Voltar
        </router-link>

        <div class="d-flex">
          <button
            :class="`btn btn-sm btn-outline-${status === 'A' ? 'danger' : 'dark'} d-flex align-items-center ms-auto mb-2 me-3`"
            @click="setStatus"
          >
            {{status === 'A' ? 'Atrasados' : 'Em dia'}}
          </button>

          <button
            v-if="filteredProcess?.length"
            class="btn btn-sm btn-outline-dark d-flex align-items-center mb-2 me-3"
            @click="opeBlockFilter"
          >
            <font-awesome-icon
              icon="arrow-up-wide-short"
              class="me-2"
              v-if="openFilter"
            />
            <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
            Filtrar
          </button>
        </div>
        

        <div
          class="d-flex justify-content-end align-items-center mt-2"
          v-if="openFilter"
        >
          <!-- client Search Field -->
          <div class="form-group me-3">
            <label for="clientPicker" class="text-dark me-2">Nome do cliente:</label>
            <input
              type="text"
              id="clientPicker"
              placeholder="Digite o cliente"
              class="form-control"
              v-model="nameProcess"
            />
          </div>
          <!-- Date Search Field -->
          <div class="form-group me-3">
            <label for="datePicker" class="text-dark me-2">Data:</label>
            <input
              type="date"
              id="datePicker"
              class="form-control"
              v-model="dateProcess"
            />
          </div>
          <!-- Status Search Field -->
          <div class="form-group">
            <label for="statusSelect" class="text-dark me-2">Status:</label>
            <select
              id="statusSelect"
              class="form-select"
              v-model="statusProcess"
            >
              <option value="" selected>Todos</option>
              <option value="N">Normal</option>
              <option value="U">Urgente</option>
            </select>
          </div>
        </div>
        <div v-show="!loading">
          <div v-if="statusProcess && !filteredProcess?.length">
            <h6 class="text-secondary custom-mt text-center">
              Desculpe, não encontramos resultados correspondentes à sua pesquisa.
              <br />
              Tente usar termos diferentes ou ajuste os filtros para obter
              melhores resultados.
            </h6>
          </div>
          <div v-else-if="!filteredProcess?.length" class="alert alert-light">
            <h5 class="custom-mt text-center mb-5">
              <font-awesome-icon icon="circle-exclamation" />
              Atualmente, não há serviços disponíveis.
            </h5>
          </div>
        </div> 
        <LoadingObjet class="text-center" v-show="loading" />
        <div v-show="!loading"> 
          <template v-for="item in filteredProcess" :key="item.id">
            <div class="card text-dark bg-white my-1 mb-5">
              <div
                class="text-center card-header fs-4 text fw-bold bg-dark text-white"
              >
                <font-awesome-icon
                  data-bs-toggle="modal" 
                  data-bs-target="#staticBackdrop"
                  icon="eye" 
                  class="me-1 clickable"
                  @click="showImage(item.id)"
                /> 
                Serviço N° {{ item.id + 1000 }}
              </div>
              <div class="card-body row">
                <div class="col-md-6">
                  <div class="card border-0">
                      <div class="card-body">
                      <span
                        :class="`mb-2 status badge bg-${
                          item.status == 'U' ? 'danger' : 'success'
                        } text-wrap`"
                        style="width: 10rem"
                        ><b>Status: </b> {{ typeStatus(item.status) }}</span
                      >

                      <br class="mb-3" />
                      <span class="produto">
                        <b>Cliente:</b> {{ findClient(item.client) }}
                      </span>

                      <br class="mb-2" />
                      <span class="designer"
                        ><b>Material: </b>
                        {{ getMaterial(item) }}</span
                      >

                      <br class="mb-2" />
                      <span class="designer"
                        ><b>Designer: </b> {{ getNameUser(item.designer) }}</span
                      >

                      <br class="mb-2" />
                      <span class="designer"
                        ><b>Data de Entrada: </b>
                        {{ convertDate(item.createdAt) }}</span
                      >
                      <br class="mb-2" />
                      <span class="designer"
                        ><b>Data de Atualização: </b>
                        {{ convertDate(item.updatedAt) }}</span
                      >

                      <br class="mb-2" />
                      <span>Observações:</span>
                      <div class="card" v-if="!openCommentsAtive">
                        <div
                          class="card-body"
                          v-if="comments[item.id]?.length > 0"
                        >
                          <template
                            v-for="comment in comments[item.id]"
                            :key="comment.id"
                          >
                            <span
                              ><b>{{ getNameUser(comment.user) }}</b> :
                              {{ comment.comment }}</span
                            ><br />
                          </template>
                        </div>
                        <div v-else class="card-body">
                          <p class="text-secondary">
                            Nenhuma observação no momento!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mt-4">
                  <div class="card-body">

                    <InputSelect
                      :objetcSelect="machines"
                      :setTitle="'Maquina'" 
                      :itemId="item.id"
                      :disabledClose="disabledClose()"
                      :setResult="item.machine"
                      @objectId="machineValue"
                    />

                    <p class="p-error-label">{{ msgError }}</p>

                    <div class="mt-3"></div>
                    <br class="mb-2"/>

                    <span class="impressor">
                      <b>Impressor: </b> {{ getNameUser(item.printer) }}
                    </span>

                    <label
                      for="comentarios"
                      class="mt-2 form-label d-flex justify-content-start"
                      >Comentários <small class="text-secondary">(opcional)</small></label
                    >
                    <textarea
                      id="comentarios"
                      v-model="commentsInp[item.id]"
                      class="form-control"
                      rows="3"
                      placeholder="Deixe seu comentário aqui ..."
                    ></textarea>

                    <p class="mt-4" v-if="item.stage === 'A'">
                      <b>{{ `Enviado para ${item.finishing}, fazer o acabamento.` }}</b>
                    </p>

                    <h6
                      class="mt-3"
                      v-if="item.stage === 'I'"
                    > 
                      Enviar para Acabamento:
                    </h6>
                    
                    <div v-if="item.stage === 'I'" class="d-flex flex-row col-12">
                      <div class="input-group">
                        <input class="form-control" placeholder="Nome do responsável" v-model="finishing[item.id]"/>
                        <div class="input-group-append">
                          <button class="btn btn-dark" :disabled="!finishing[item.id]?.length" @click="sendFinishingProcess(item.id)">
                            <font-awesome-icon icon="gears"/>
                            Acabamento
                          </button>
                        </div>
                      </div>
                    </div>
                    
                  </div>
            
                  <div class="d-flex justify-content-end me-3">
                    <button
                      class="btn btn-outline-dark"
                      @click="registerProcess(item.id)"
                      :disabled="
                        item.stage === 'P' ||
                        item.stage === 'I' ||
                        item.stage === 'A'
                          ? false
                          : true
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check2-circle"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"
                        />
                        <path
                          d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"
                        />
                      </svg>
                      Enviar para Entrega
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import store from "@/store";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";

import {
  getCommentsProcess,
  createProcessLecturer,
  stageProcess,
  finishingProcess
} from "@/http";

import { 
  convertDate, 
  formatFone, 
  getDateBrazil, 
  getImgBase64,
  getMaterialInList
} from "@/utils";

import { 
  sendEventStageProcess, 
  getReponseProcess, 
  getReponseStageProcess,
  getReponseMaterial,
  getReponseMachine,
  getReponseClients,
} from "@/socket";
import InputSelect from "@/components/InputSelect.vue";
import LoadingObjet from '@/components/LoadingObjet';
export default {
  name: "ExecuteProcesses",
  props: {},
 
  setup() {
    const user = computed(() => store.state.userCurrent);
    const _users = computed(() => store.state.users);
  
    const comments = ref({});
    const commentsInp = ref({});
    const machineType = ref({});
    const finishing = ref({});
    const msgError = ref(null);
    const clients = computed(() => store.state.clientsList);
    const status = ref('A');
    const materials = computed(() => store.state.materialListView);
    const machines = computed(() => store.state.machineList);

    const sortProcess = (arr) => {
      return arr?.sort((a, b) => {
        return b.id - a.id;
      });
    };

    const getCommetProcess = async (process) => {
      const responseComment = await getCommentsProcess(process);
      return responseComment;
    };

    const getNameUser = (id) => {
      const foundUser = _users.value.find((user) => user.id == id);
      return foundUser?.name;
    };

    const typeStatus = (type) => {
      switch (type) {
        case "U":
          return "Urgente";
        case "N":
          return "Normal";
      }
    };

    const registerProcess = async (id) => {

      const process = store.state.executeProcessesLecturer || [];
      const payload = {
        comment: commentsInp.value[id],
        member: user.value.id,
      };
      const reponseProcessPrinter = await createProcessLecturer(id, payload);

      if (reponseProcessPrinter?.status) {
        const processFind = process?.find(process => process.id == id);
        if(processFind){
          processFind.stage = "E"
          sendEventStageProcess("graphic", processFind);
        }
        await store.dispatch("setProcessListLecturerPrinterCurrent", {data: {stage: ["I", "A"]}, status: status.value, date: getDateBrazil()});
        return;
      }

    };

    const findClient = (id) => {
      const foundClient = clients?.value?.find((client) => client.id == id);
      return foundClient
        ? `${foundClient.name}${user.value.type_user === "A" ? (' - ' + formatFone(foundClient.fone)) : ''}`
        : "-";
    };

    const openFilter = ref(false);

    const opeBlockFilter = () => {
      openFilter.value = openFilter.value ? false : true;
    };

    const mergeClientData = (client) => {
      let processes = store.getters.getProcessesLecturer;
      

      if(!processes){
        return;
      }
      return processes?.map((item) => {
        const foundClient = client?.find((c) => c.id === item.client);
        const mergedData = { ...item, ...foundClient };

        mergedData.id = item.id;
        mergedData.createdAt = item.createdAt;
        mergedData.updatedAt = item.updatedAt;

        return mergedData;
      });
    };

    const dataProcesses = computed(() => {
      return mergeClientData(store.state.clientsList);
    });

    const statusProcess = ref("");
    const dateProcess = ref("");
    const nameProcess = ref("");
  
    const filteredProcess = computed(() => {
      try {
        const formatLabelDate = (date) => {
          const arrDate = date?.split("-");
          return date ? arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0] : "";
        };

        const _statusProcess = statusProcess.value;
        const _dateProcess = formatLabelDate(dateProcess.value);
        const _nameClient = nameProcess.value.toLowerCase();
        
        return dataProcesses?.value?.filter(
              (process) =>
                convertDate(process?.createdAt).includes(_dateProcess) &&
                process?.status?.includes(_statusProcess) &&
                process?.name?.toLowerCase().includes(_nameClient) 
            ) || [];
      } catch {
        return [];
      }
    });

    const updateStageProcess = async (id) => {

      await stageProcess(id, "A", machineType.value[id])
        .then(async () => {
          sendEventStageProcess("graphic", {id:id, stage: "A", machine: machineType.value[id]});

          await store.dispatch("setProcessListLecturerPrinterCurrent", {data: { stage: ["I", "A"] }, status: 'A', date: getDateBrazil()});
      });

    };

    const sendFinishingProcess = async (id) => {

      const payload = {
        finishing: finishing.value[id],
        member: user.value.id,
        stage:  "A",
        comment: commentsInp.value[id] 
      }

      await finishingProcess(id, payload)
        .then( async () => {
            sendEventStageProcess("graphic", {id:id, stage: "A"});
            const process = store.state.executeProcessesLecturer;
            for (const item of process) {
              let comment = await getCommetProcess(item.id);

              if (comment) {
                comments.value[item.id] = comment;
              }
            }
            await store.dispatch("setProcessListLecturerPrinterCurrent", {data: { stage: ["I", "A"] }, status: 'A', date: getDateBrazil()});
          }
        );

    }

    const selectedImg = ref('');
    const showImage = async (id) => {

      selectedImg.value = '';
      selectedImg.value = await getImgBase64(id);

    }

    const setStatus = () => {
      status.value = status.value === 'A' ? 'P':  'A';
    }

    const getMaterial = (item) => {
      return getMaterialInList(materials.value, item);
    }

    const machineValue = (event) => {
      machineType.value[event?.item] = event?.id 
    }

    const disabledClose = () => {
      return user.value.type_user === 'C' || (user.value.type_user.includes('A'))
    }
    const loading = ref(false);
    watch(status, async (newVal, oldVal) => {

      if (oldVal !== newVal) {
        loading.value = true;  
        const listProcesses = await store.dispatch("setProcessListLecturerPrinterCurrent", {data: { stage: ["I", "A"] }, status: newVal, date: getDateBrazil()});

        if(!listProcesses?.message){
          const processIds = listProcesses.map((item)=> item.material) || [];
          await store.dispatch('getMaterialListView', processIds);
        }

      }
      loading.value = false;  
    });

    onBeforeMount(async () => {
      loading.value = true;  
      await store.dispatch("setClientListCurrent");
      const listProcesses = await store.dispatch("setProcessListLecturerPrinterCurrent", {data: { stage: ["I", "A"] }, status: 'A', date: getDateBrazil()});
      
      if(!listProcesses?.message){
        const processIds = listProcesses.map((item)=> item.material) || [];
        await store.dispatch('getMaterialListView', processIds);
      }

      await store.dispatch("getUsers");
      await store.dispatch("setProcessListCurrent");

      const process = store.state.executeProcessesLecturer || [];

      for (const item of process) {
        let comment = await getCommetProcess(item.id);

        if (comment) {
          comments.value[item.id] = comment;
        }
      }

      await store.dispatch("setClientListCurrent");
      await store.dispatch('setMachineListCurrent');
      loading.value = false;  
    });

    onMounted(() =>{
      getReponseMaterial();
      getReponseMachine();
      getReponseClients();
      getReponseProcess();
      getReponseStageProcess();
    });

    return {
      loading,
      disabledClose,
      machineValue,
      machines,
      getMaterial,
      selectedImg,
      setStatus,
      status,
      sendFinishingProcess,
      finishing,
      showImage,
      filteredProcess,
      updateStageProcess,
      user,
      comments,
      sortProcess,
      getCommetProcess,
      getNameUser,
      typeStatus,
      commentsInp,
      machineType,
      registerProcess,
      msgError,
      opeBlockFilter,
      openFilter,
      statusProcess,
      dateProcess,
      convertDate,
      clients,
      findClient,
      nameProcess,
    };
  },
  components:{
    InputSelect,
    LoadingObjet
  }
};
</script>
<style scoped>
.custom-mt {
  margin-top: 50px;
}

.card-header {
  font-family: Arial, Helvetica, sans-serif;
}

p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.status {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  padding: auto;
}

.produto {
  font-size: 1rem;
}

.btn {
  font-weight: bold;
}

.p-error-label {
  font-size: 14px;
  color: red;
}

.clickable {
  cursor: pointer;
}

.clickable :hover {
  opacity:  0.75;
}

.overlay-background {
  background-color: rgba(0, 0, 0, 0.5); 
}
.overlay-background2 {
  background-color: rgba(0, 0, 0, 0); 
}

/* CSS para ajustar a largura e altura da imagem dentro do modal */
.modal-dialog {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.enlarged-image {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}
</style>
