<template>
  <div class="container custom-mt">
    <div class="card mx-auto border-0 shadow-lg" style="max-width: 500px">
      <div class="card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120"
          />
        </div>
      </div>
      <div class="card-body" v-if="!confirm" >
        <h4 class="text-center mb-4">
          <b>Editar Serviço N° {{ process.id + 1000 }}</b>
        </h4>

        <div class="text-white error-label text-center" v-if="timeError">
          <p class="">{{ errorLabel.messageError }}</p>
        </div>

        <div class="mb-4 ps-2 pe-2">
          <label for="nome" class="form-label">Cliente</label>

          <div>
            <div class="search-container">
              <span class="search-icon" v-if="!process.client && !searchTerm">
                <font-awesome-icon icon="magnifying-glass" />
              </span>
              <input
                v-model="searchTerm"
                @input="filterClients"
                :placeholder="getClientName(process.client)"
                class="form-control"
              />
              <span
                class="clear-icon"
                @click="clearSearch"
                v-if="process.client && $_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D'"
              >
                <font-awesome-icon icon="circle-xmark" />
              </span>
            </div>
            <select
              class="form-control"
              v-model="process.client"
              size="3"
              v-if="searchTerm"
            >
              <option
                v-for="client in filteredClients"
                :key="client.id"
                :value="client.id"
              >
                {{ `${client.name} - ${client.fone}` }}
              </option>
            </select>
          </div>

          <p class="p-error-label">{{ errorLabel.client }}</p>
        </div>
        
        <div class="mb-3 ps-2 pe-2">
          <label for="nome" class="form-label">Material</label>

          <div>
            <div class="search-container">
              <span
                class="search-icon"
                v-if="!process.material && !searchTerm"
              >
                <font-awesome-icon v-if="!searchTermMaterial" icon="magnifying-glass" />
              </span>

              <input
                v-model="searchTermMaterial"
                @input="filterMaterials"
                :placeholder="getMaterialName(process.material)"
                class="form-control"
              />
              <span
                class="clear-icon"
                @click="clearMaterial"
                v-if="process.material && ($_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D')"
              >
                <font-awesome-icon icon="circle-xmark" />
              </span>
            </div>
            <select
              class="form-control"
              v-model="process.material"
              size="3"
              v-if="searchTermMaterial"
            >
              <option
                v-for="material in filteredMaterials"
                :key="material.id"
                :value="material.id"
              >
                {{ `${material.name}` }}
              </option>
            </select>
          </div>
          <p class="p-error-label">{{ errorLabel.material }}</p>
        </div>

        <div class="mb-2 ps-2 pe-2 container-fluid">
          <label for="largura" class="form-label">Medidas do Material:</label>
          <div class="d-flex">

            <div class="row col me-1">
              <div class="col-12">
                <input
                  v-model="process.width"
                  type="number"
                  class="form-control mb-2"
                  id="largura"
                  placeholder="Largura (metros)"
                  style=""
                  :readonly=" ($_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D') ? false : true"
                />
                <p class="p-error-label">{{ errorLabel.width }}</p>
              </div>
            </div>

            <div class="row col">
              <div class="col-12">
                <input
                  v-model="process.height"
                  type="number"
                  class="form-control"
                  id="altura"
                  placeholder="Altura (metros)"
                  style=""
                  :readonly=" ($_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D') ? false : true"
                />
                <p class="p-error-label">{{ errorLabel.height }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class=" ps-2 pe-2">
          <label for="quantidade" class="form-label">Quantidade:</label>
          <input
            v-model="process.amount"
            type="number"
            class="form-control"
            id="quantidade"
            placeholder="Quantidade"
            style="width: 200px;"
            :readonly=" ($_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D') ? false : true"
          />
          <p class="p-error-label">{{ errorLabel.amount }}</p>
        </div>

        <div
          :class="` ps-2 pe-2 ${
            process.image ? 'd-flex align-items-center' : ''
          }`"
        >
          <div class="">
            <label for="formFileMultiple" class="form-label">Imagem</label>
            <input
              ref="fileInput"
              class="form-control"
              type="file"
              id="formFileMultiple"
              @change="handleFileChange"
              v-if="$_store.state.userCurrent.type_user !== 'I' "
            />
            <p class="p-error-label">{{ errorLabel.image }}</p>
          </div>

          <div
            v-if="process.image"
            class="ms-4 mt-auto d-flex align-items-center justify-content-end flex-wrap"
          >
            <img :src="process.image" alt="" height="60" width="60" />
          </div>
        </div>
        <p class=" ps-2 p-error-label">{{ errorLabel.image }}</p>

        <div class="mb-4 ps-2 pe-2" v-if=" ($_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D')">
          <label for="finishing" class="form-label">Responsável Acabamento</label>
          <p v-if="!process.finishing">-</p>
          <input
            v-if="process.finishing"
            v-model="process.finishing"
            type="text"
            class="form-control"
            id="finishing"
            required
            placeholder="Digite o responsavél pelo acabamento"
          />
        </div>

        <div class="mb-4 ps-2 pe-2" v-if=" ($_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D') || $_store.state.userCurrent.type_user === 'I'">
          <div v-if="!machineEdit"> 
            <label for="" class="form-label">Máquina</label>
            <p>
              {{ getNameMachine(process.machine) }} 
              <b class="text-primary clickable" @click="openMachineEdit"> editar <font-awesome-icon icon="pencil" /></b> 
            </p>
          </div>  

          <InputSelect
            v-if="machineEdit"
            :objetcSelect="machines"
            :setTitle="'Máquina'"
            :disabledClose="disabledClose($_store.state.userCurrent)"
            :setResult="process.machine"
            @objectId="machineValue"
          />
        </div>  

        <div :class="'ps-2 pe-3' + $_store.state.userCurrent.type_user !== 'A' ? 'mt-4 mb-2' : '' ">
          <label for="urgencia" class="form-label ms-2">Prioridade</label>
          <span v-if="$_store.state.userCurrent.type_user !== 'A'"><b>: Normal</b></span>
        </div> 
        <div class="mb-4 ps-2 pe-2 d-flex align-items-center" v-if=" ($_store.state.userCurrent.type_user.includes('A') || $_store.state.userCurrent.type_user === 'D')" >
          <div class="form-check me-4">
            <input
              v-model="process.status"
              class="form-check-input"
              type="radio"
              name="nomal"
              id="normal"
              value="N"
              checked
            />
            <label class="form-check-label" for="normal"> Normal </label>
          </div>
          <div class="form-check">
            <input
              v-model="process.status"
              class="form-check-input"
              type="radio"
              name="urgencia"
              id="urgente"
              value="U"
            />
            <label class="form-check-label" for="urgente"> Urgente </label>
          </div>
        </div>

        <div class="d-grid gap-2">
          <button class="btn btn-primary ms-2 me-2" @click="actionConfim" :disabled="ifModiflyProcess()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"
              />
            </svg>
            Confirmar
          </button>
          <button class="btn btn-outline-danger mb-4 ms-2 me-2" @click="back">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
            Voltar
          </button>
        </div>
      </div>
      <!-- alerta -->
      <div
        class="card text-center m-2"
        v-if="confirm"
        style="height: 280px"
      >
        <span class="mt-2">Para continuar, você precisa especificar o motivo desta edição:</span>
        <hr />
        <div class="ps-2 pe-2 mb-3">
            <label for="comment">Observação <small>(Obrigatório)</small></label>
            <textarea
              v-model="comment"
              class="form-control"
              id="comment"
              rows="3"
            >
            </textarea>
            
          </div>
        <div class="mt-1">
          <button class="btn btn-danger btn-dm me-2" @click="actionConfim">
            Cancelar
          </button>

          <button
            type="button"
            class="btn btn-primary btn-dm me-2"
            @click="confirmReason"
            :disabled="comment.length < 3"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import store from "../../store";
import { editProcess, getProcess, sendProcessComment } from "../../http";
import { getReponseClients, getReponseMaterial, sendEventProcess, getReponseMachine,} from "../../socket";
import { getImgBase64, getNameObject} from "../../utils";
import InputSelect from "@/components/InputSelect.vue";
import _ from "lodash"
export default {
  name: "EditProcesses",
  props: {},
  setup() {
    const route = useRoute();
    const processId = ref(route.params.id || null);
    const clients = computed(() => store.state.clientsList);
    const materials = computed(() => store.state.materialList);
    const machines = computed(() => store.state.machineList);
    const timeError = ref(false);
    const confirm = ref(false);
    const comment = ref('');
    const process = ref({});
    const processOrigin = ref({});
    const errorLabel = ref({
      client: null,
      description: null,
      messageError: null,
      image: null,
      material: null,
      amount: null,
      height: null,
      width: null,
    });

    const validateForm = () => {
      errorLabel.value.client = null;
      errorLabel.value.description = null;
      errorLabel.value.messageError = null;
      errorLabel.value.image = null;
      errorLabel.value.material = null;
      errorLabel.value.amount = null;
      errorLabel.value.height = null;
      errorLabel.value.width = null;

      if(!process.value?.material){
        errorLabel.value.material = "Campo obrigatório.";
      }

      if(!process.value?.image){
        errorLabel.value.image = "Campo obrigatório.";
      }

      if(!process.value?.amount){
        errorLabel.value.amount = "Campo obrigatório.";
      }

      if(!process.value?.height){
        errorLabel.value.height = "Campo obrigatório.";
      }

      if(!process.value?.width){
        errorLabel.value.width = "Campo obrigatório.";
      }

      if (!process.value?.client) {
        errorLabel.value.client = "Campo obrigatório.";
      }

      if (!process.value?.description) {
        errorLabel.value.description = "Campo obrigatório.";
      }

      if (errorLabel.value.client || 
          errorLabel.value.material ||
          errorLabel.value.amount ||
          errorLabel.value.height ||
          errorLabel.value.width
      ) {
        return true;
      }
      return false;
    };

    const actionConfim = () => {
      confirm.value = confirm.value ? false : true;
    }

    const confirmReason = async () => {
      if(comment.value){
        await sendProcessComment({comment: comment.value, member: store.state.userCurrent.id, process: process.value.id,})
        .then(async () => await editProcesss());
      }
    }
    
    
    const ifModiflyProcess = () => {
      if (_.isEqual(process.value, processOrigin.value)) {
        return true;
      }
      return false;
    }

    const editProcesss = async () => {

      if (validateForm()) {
        return;
      }

      const responseProcess = await editProcess(
        process.value.id,
        process.value
      );

      if (!responseProcess?.status) {
        errorLabel.value.messageError = responseProcess?.message;
        timeError.value = true;
        setTimeout(() => {
          timeError.value = false;
        }, 5000);
        return;
      }else{
        delete process.value.image;
        process.value.updated = true;
        process.value.materials = materials.value?.find(m => m.id == process.value.material);
        process.value['edit'] = 1;
        sendEventProcess("graphic", process.value);
      }

      location.reload();
    };

    const back = () => {
      store.commit("setOpenEditProcess");
    };

    const searchTerm = ref("");
    const searchTermMaterial = ref("");

    const filteredClients = computed(() => {
      const term = searchTerm.value.toLowerCase();
      return clients.value?.filter(
        (client) =>
          client.name.toLowerCase().includes(term) || client.fone.includes(term)
      );
    });

    const filteredMaterials = computed(() => {
      const term = searchTermMaterial.value?.toLowerCase();
      return materials.value?.filter(
        (material) =>
         material.name?.toLowerCase().includes(term)
      );
    });

    const getClientName = (clientId) => {
      if (!searchTerm.value && !process.value.client) {
        return "    Pesquisar cliente...";
      }

      const foundClient = clients.value.find(
        (client) => client.id === clientId
      );

      if (foundClient) {
        searchTerm.value = "";
        return foundClient ? foundClient.name + " - " + foundClient.fone : "";
      }
    };

    const getMaterialName = (materialId) => {
      if (!searchTermMaterial.value && !process.value.material) {
        return "    Pesquisar material...";
      }

      const foundMaterial= materials.value.find(
        (material) => material.id === materialId
      );

      if (foundMaterial) {
        searchTermMaterial.value = "";
        return foundMaterial ? foundMaterial.name : "";
      }
    };

    const clearSearch = () => {
      searchTerm.value = "";
      process.value.client = null;
    };

    const clearMaterial = () => {
      searchTermMaterial.value = "";
      process.value.material = null;
    };

    const filterClients = () => {};
    const filterMaterials = () => {};

    const fileInput = ref(null);
    const handleFileChange = (event) => {
      process.value.image = null;
      errorLabel.value.image = null;

      const fileInput = event.target;
      const file = fileInput.files[0];
      const LIMIT_IMAGE = 200; // kb
      const size = +file["size"] / 1024;

      if (size > LIMIT_IMAGE) {
        fileInput.value = null;
        errorLabel.value.image =
          "Imagem muito grande, tamanho máximo permitido 200KB";
        return;
      }

      if (file && size <= LIMIT_IMAGE) {
        const reader = new FileReader();

        reader.onload = () => {
          process.value.image = reader.result;
        };
        reader.readAsDataURL(file);
      }
    };

    const openCreateMaterial = () => {
      store.commit('setOpenCreateMaterial');
    }

    const disabledClose = (user) => {
      return user.type_user === 'C'
    }

    const machineValue = (event) => {
      process.value.machine = event
    }

    const getMachine = ({machine}) => {
      return process.value.machine || machine
    }

    const getNameMachine = (id) =>{
      return getNameObject(store.state.machineList, id)
    }

    const machineEdit = ref(false);

    const openMachineEdit = () => {
      machineEdit.value = machineEdit.value ? false : true ;
    }

    onBeforeMount(async () => {
      if (processId.value) {
        await getProcess(processId.value).then( async (data) => {
          process.value = data;
          process.value.image = await getImgBase64(processId.value);
        });  
      }

    processOrigin.value = {...process.value};
      await store.dispatch("setClientListCurrent");
      await store.dispatch("setMaterialListCurrent");
      await store.dispatch("setMachineListCurrent");
      getReponseMaterial();
      getReponseClients();
      getReponseMachine();
    });

    return {
      openMachineEdit,
      machineEdit,
      getNameMachine,
      getMachine,
      disabledClose,
      machineValue,  
      machines,
      openCreateMaterial,
      handleFileChange,
      fileInput,
      filterMaterials,
      clearMaterial,
      filteredMaterials,
      getMaterialName,
      searchTermMaterial,
      filteredClients,
      filterClients,
      searchTerm,
      clearSearch,
      getClientName,
      editProcesss,
      process,
      processOrigin,
      back,
      clients,
      errorLabel,
      timeError,
      actionConfim,
      confirm,
      confirmReason,
      comment,
      ifModiflyProcess,
    };
  },
  components:{
    InputSelect
  }
};
</script>

<style scoped>
h1 {
  font-weight: bold;
}
h1,
label,
button {
  font-family: Arial, Helvetica, sans-serif;
}

.custom-mt {
  margin-top: 10px;
  margin-bottom: 80px;
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-label {
  background-color: #be3f3f;
  border-radius: 5px;
  overflow: hidden;
}

.p-error-label {
  font-size: 14px;
  color: red;
}

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  color: #888; /* Cor do ícone de pesquisa (pode ser personalizado) */
}

.clear-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  color: #888; /* Cor do ícone (pode ser personalizado) */
}
.custom-link {
  font-size: 15px;
  color: gray;
  font-weight: bold;
  text-decoration: none; /* Remove sublinhado */
  transition: color 0.3s, background-color 0.3s; /* Transição de cor e fundo */
}

.custom-link:hover {
  color: #020202; /* Cor cinza no hover */
  font-weight: bold;
}


.clickable {
  cursor: pointer;
}
</style>
