<template>
  <div>
    <div class="row custom-mt">

      <div class="col-sm-12 col-lg-12 mb-4" v-if="user.type_user === 'D'">
        <div class="card text-dark bg-white">
          <div class="card-header fs-5 text fw-bold bg-white text-dark">
            Registrar Produtos
          </div>
          <div class="card-body">
            <p class="card-text">Registre os dados e os produtos que deram entrada para o serviço.</p>
            <div>
              <router-link to="/register-process" class="btn btn-dark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-box-arrow-in-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                  />
                </svg>
                Registrar
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-12 mb-4" v-if="user.type_user === 'I'">
        <div class="card text-dark bg-white">
          <div class="card-header fs-5 text fw-bold bg-white text-dark">
            Visualizar Produtos para Impressão
          </div>
          <div class="card-body">
            <p class="card-text">Visualizar os serviços para Impressão.</p>
            <div>
              <router-link to="/printer" class="btn btn-dark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-box-arrow-in-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                  />
                </svg>
                Acessar
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-lg-12 mb-4" v-if="user.type_user === 'C' ">
        <div class="card text-dark bg-white">
          <div class="card-header fs-5 text fw-bold bg-white text-dark">
            Conferir Serviços
          </div>
          <div class="card-body">
            <p class="card-text">Conferir os serviços para Finalização.</p>
            <div>
              <router-link to="/lecturer" class="btn btn-dark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-box-arrow-in-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                  />
                </svg>
                Acessar
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-4" v-if="user.type_user === 'D'">
      <button @click="selectedButton=1" :class="`btn ${selectedButton === 1 ? 'selectedButton' : ''}`">Processo Geral</button>
      <button @click="selectedButton=2" :class="`btn ${selectedButton === 2 ? 'selectedButton' : ''}`">Processo individual</button>
    </div>

    <ListProcess v-if="(selectedButton === 1 && (user.type_user === 'D')) || user.type_user === 'C' || user.type_user === 'I'" />
    <ListProcessDesigner v-if="selectedButton === 2 && (user.type_user === 'D')"/>

  </div>
</template>

<script>
import ListProcess from "./ListProcess.vue";
import ListProcessDesigner from "./ListProcessDesigner.vue";
import store from "../../store";
import { computed, ref } from "vue";

export default {
  name: "HomeProcesses",
  props: {},
  setup() {
    const user = computed(() => store.state.userCurrent);
    const selectedButton = ref(1);

    return {
      selectedButton,
      user,
    };
  },
  components: {
    ListProcess,
    ListProcessDesigner,
  },
};
</script>
<style scoped>
.custom-mt {
  margin-top: 50px;
}

.card-header {
  font-family: Arial, Helvetica, sans-serif;
}

p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.btn {
  font-weight: bold;
}

.selectedButton {
  border-bottom:  solid #000000;
}

</style>
