<template>
  <button class="btn btn-success w-100 mt-3 mb-4 fs-6" :disabled="(showModal ?  isDisabled : showModal )" @click="handleClick">{{ buttonName }}</button>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from 'vue';
import store from "@/store";

defineProps({
  buttonName: {
    type: String,
    default: 'Clique aqui'
  }
});

const showModal = computed(() => {return sessionStorage.getItem(`modalPoint${store.state.userCurrent.id}`) == 'true' ? true : false});

const buttonKey = 'buttonDisabledUntil' + store.state.userCurrent.id;
const disabledDuration = 15 * 60 * 1000;

const isDisabled = ref(false);

const updateButtonState = () => {
  const disabledUntil = localStorage.getItem(buttonKey);
  if (disabledUntil) {
    const now = Date.now();
    if (now < parseInt(disabledUntil, 10)) {
      isDisabled.value = true;
    } else {
      localStorage.removeItem(buttonKey);
      isDisabled.value = false;
    }
  }
};

const handleClick = () => {
  isDisabled.value = true;
  const disabledUntil = Date.now() + disabledDuration;
  localStorage.setItem(buttonKey, disabledUntil);
  setTimeout(() => {
    updateButtonState();
  }, disabledDuration);
};

onMounted(() => {
  updateButtonState();
});
</script>

<style>
/* Estilos do botão */
</style>
