<template>
  
  <div>
    <div class="container custom-mt">
      <div class="card mx-auto border-0 shadow-lg" style="max-width: 400px">
        <div class="card-header bg-transparent border-0">
          <div>
            <img
              src="../../assets/logo.png"
              alt="Logomarca"
              class="img-fluid mx-auto d-block mt-4"
              width="120
            "/>
          </div>
        </div>
        <div class="text-white error-label text-center" v-if="timeError">
          <p class="">{{ errorLabel.login }}</p>
        </div>
        <div class="card-body">
            <div class="mb-3 ps-2 pe-2">
              <label for="username" class="form-label">E-mail</label>
              <input
                type="text"
                v-model="login.email"
                class="form-control"
                id="username"
                placeholder="Digite seu E-mail"
                required
                @keydown.enter="loginAuth"
              />
              <p class="p-error-label">{{errorLabel.email}}</p>
            </div>
            <div class="mb-3 ps-2 pe-2 icon-container">
              <label for="password" class="form-label">Senha</label>
              <input
                :type="typeInput"
                v-model="login.password"
                class="form-control"
                id="password"
                placeholder="Digite sua senha"
                required
                @keydown.enter="loginAuth"
              />
              <span  class="hidder-eye-icon" @click="changeTypeInputPassword" v-if="typeInput === 'text'">
                <font-awesome-icon icon="eye-slash" />
              </span>
              <span  class="hidder-eye-icon" @click="changeTypeInputText" v-if="typeInput === 'password'">
                <font-awesome-icon icon="eye" />
              </span>
              <p class="p-error-label">{{errorLabel.password}}</p>
            </div>
            
            <div class="d-grid gap-2 ms-2 me-2">
              <button class="btn btn-dark" @click="loginAuth">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-box-arrow-in-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                  />
                </svg>
                Entrar
              </button>
            </div>
          <div class="d-grid gap-2">
            <button
              @click="goToSignup"
              class="btn btn-outline-danger mt-3 ms-2 me-2 mb-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-fill-add"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"
                />
                <path
                  d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"
                />
              </svg>
              Não tenho uma conta
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import store from '../../store';

export default {
  name: "AuthUser",
  props: {},
  setup() {
    const router = useRouter();

    const login = ref({
      email: null,
      password: null,
    });

    const errorLabel = ref({
      login: null,
      email: null,
      password: null
    });

    const timeError = ref(false);

    const  validateForm = () => {

      errorLabel.value.login = null;
      errorLabel.value.email  = null;
      errorLabel.value.password  = null;

      if(!login.value?.email){
        errorLabel.value.email = 'Campo obrigatório.';
      }

      if(!login.value?.password){
        errorLabel.value.password = 'Campo obrigatório.';
      }

      if(errorLabel.value.email ||
         errorLabel.value.password
      ){
        return true;
      }
      return false;

    }

    const loginAuth = async () => {

      if(validateForm()){
        return;
      }

      await store.dispatch("setUserCurrent", {email: login.value.email, password: login.value.password})
        .then( async (data) => {
          if(data?.message){
            errorLabel.value.login = data?.message;
            timeError.value = true;
            setTimeout(() => {
              timeError.value = false;
            }, 5000);
              return;
          }
          await store.dispatch("setShowModalStartCurrent");
      });
    }

    const goToSignup = () => {
      router.push('/register-user');
    }

    const typeInput = ref('password');

    const changeTypeInputText = () => {
      typeInput.value = 'text';   
    }

    const changeTypeInputPassword = () => {
      typeInput.value = 'password';   
    }

    onBeforeMount(() => {
      if(sessionStorage.getItem('reload')){
        sessionStorage.removeItem('reload');
        router.push('/')
          .then(() => {
            location.reload();
          })
      }

    });

    return {
      changeTypeInputText,
      changeTypeInputPassword,
      typeInput,
      login,
      errorLabel,
      loginAuth,
      goToSignup,
      timeError
    }
  },
};
</script>
<style scoped>
/*colocar o estilo da página ou componente*/

label,
button {
  font-family: Arial, Helvetica, sans-serif;
}

.custom-mt {
  margin-top: 150px; /* Ajuste a margem superior conforme necessário */
}
.error-label {
  background-color: #be3f3f;
  border-radius: 5px;
  overflow: hidden;
}

.p-error-label{
  font-size: 14px;
  color: red;
}

.hidder-eye-icon {
  position: absolute;
  right: 16px;
  top: 72%;
  cursor: pointer;
  transform: translateY(-50%);
  color: #000000; /* Cor do ícone (pode ser personalizado) */
}

.icon-container {
  position: relative;
}
</style>
