<template>
  
  <div class="container">
    <!-- Cabeçalho -->
    <div class="container mt-5 mb-5">
      <div class="card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120"
          />
        </div>
        <h4 class="text-center my-3">
          <font-awesome-icon icon="fa-hand-pointer" />
          Sistema de Ponto
        </h4>
        <hr />
      </div>
      <p class="text-center alert alert-success" v-if="timeCopy">{{msgOfPoint}}</p>
    </div>

    <!-- Botões e Filtros -->
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div class="d-grid gap-2 col-2 mx-start ms-2">
        <button
          class="btn btn-sm btn-danger fw-bold"
          type="button"
          @click="openModal = true"
        >
          Fechar ponto
        </button>
      </div>

      <p class="d-flex align-items-center ms-2 mb-2">
        {{ filteredStockHistory?.length > 0 ? filteredStockHistory?.length + ' resultado encontrado.' : '' }}
      </p>
      <button
        class="btn btn-sm btn-outline-dark d-flex align-items-center mb-2 me-3"
        @click="opeBlockFilter"
      >
        <font-awesome-icon
          icon="arrow-up-wide-short"
          class="me-2"
          v-if="openFilter"
        />
        <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
        Filtrar
      </button>
    </div>

    <!-- Filtros de pesquisa -->
    <div class="row mb-4 d-flex justify-content-end" v-if="openFilter">
      <div class="col-md-3">
        <label for="filtroData" class="form-label filtros">Data:</label>
        <input
          id="filtroData"
          type="date"
          class="form-control"
          value="2024-01-01"
          v-model="dateStart"
        />
      </div>
    </div>

    <LoadingObjet class="text-center" v-show="loading" />
    <div v-if="points.length < 1" class="alert alert-light mt-3">
        <h5 class="custom-mt-g text-center mb-5" v-show="!loading">
          <font-awesome-icon icon="circle-exclamation" />
          Atualmente, não há registros de Ponto Eletronico criado por você.
        </h5>
    </div>
    <!-- Tabela -->
    <div class="table-responsive mt-4 custom-table" v-if="!loading && points.length > 0">
      <table class="text-center table table-dark">
        <thead>
          <tr>
            <th>Data</th>
            <th>Entrada Manhã</th>
            <th>Saída Manhã</th>
            <th>Entrada Tarde</th>
            <th>Saída Tarde</th>
          </tr>
        </thead>
        <tbody>
              <template v-for="item in points" :key="item.id">
                  <tr class="align-items-center">
                    <td>{{ convertDate(item.createdAt).slice(0, 10) }}</td>
                    <td>{{ item.firstTime || '-' }}</td>
                    <td>{{ item.secondTime || '-' }}</td>
                    <td>{{ item.thirdTime || '-' }}</td>
                    <td>{{ item.fourthTime || '-' }}</td>
                  </tr>
              </template>
          </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div v-if="openModal" class="modal d-block" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

          <div class="modal-header mb-2">
            <h5 class="modal-title">Sistema de marcação de ponto</h5>
            <button type="button" class="btn-close" @click="openModal = false"></button>
          </div>

          <div class="modal-body text-center">
            <HitPoint @closeValue="closeValue"/>
          </div>

        </div>
      </div>
    </div>

    <div v-if="openModal" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { ref, watch, computed, onBeforeMount } from 'vue';
import HitPoint from "./hitPoint.vue"
import store from "../../store";
import {convertDate} from "../../utils";
import LoadingObjet from '@/components/LoadingObjet';

export default {
  
  setup() {
    const openFilter = ref(false);
    const openModal = ref(false);
    const dateStart = ref('');
    const filteredStockHistory = ref([]);
    const close = ref(false);
    const timeCopy = ref(false);
    const msgOfPoint = ref("");
    const loading = ref(false);
    const points =  computed(() => store.state.pointsList || []);

    const closeValue = (event) => {
      close.value = event.close;
      msgOfPoint.value = event.msg
    }

    const handleClockIn = () => {
      openModal.value = false;
    };

    watch(close, (newValue) => {
      if(newValue){
        timeCopy.value = true;
        setTimeout(() => {
        timeCopy.value = false;
        }, 4000);
        openModal.value = false
        close.value = false
      }
    });

    onBeforeMount(async () =>{
      loading.value = true;
      await store.dispatch('setPointUserCurrent')
        .then(() => loading.value = false);
    });

    return {
      closeValue,
      openFilter,
      openModal,
      dateStart,
      filteredStockHistory,
      handleClockIn,
      timeCopy,
      msgOfPoint,
      points,
      convertDate,
      loading
    };
  },
  components: {
    HitPoint,
    LoadingObjet
  },
};
</script>

<style scoped>
.custom-table {
  border-radius: 10px;
  overflow: hidden;
}
.modal-title {
  text-align: center;
}
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
