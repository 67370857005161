import { auth } from "../utils";
import store from '../store';

export const authMiddleware = async (to, from, next) => {

  if (!store.getters.getUser.id) {
    await auth();
  }
  
  if (store.getters.getUser.id) {
    next();
  } else {
    next('/'); 
  }
};

export const authAdmin = async (to, from, next) => {

  if (!store.getters.getUser.id) {
    await auth();
  }

  if (store.getters.getUser.id && store.getters.getUser.type_user.includes('A')) {
    next();
  } else {
    next('/'); 
  }
};


export const authAdminDesigner = async (to, from, next) => {

  if (!store.getters.getUser.id) {
    await auth();
  }

  if (store.getters.getUser.id && (store.getters.getUser.type_user === "A" || store.getters.getUser.type_user === "D")) {
    next();
  } else {
    next('/'); 
  }
};

export const authAdminPrinter = async (to, from, next) => {

  if (!store.getters.getUser.id) {
    await auth();
  }

  if (store.getters.getUser.id && (store.getters.getUser.type_user.includes("A") || store.getters.getUser.type_user === "I")) {
    next();
  } else {
    next('/'); 
  }
};

export const authAdminLecuter = async (to, from, next) => {
  if (!store.getters.getUser.id) {
    await auth();
  }

  if (store.getters.getUser.id && (store.getters.getUser.type_user.includes("A") || store.getters.getUser.type_user === "C")) {
    next();
  } else {
    next('/'); 
  }
};

export const authAdminPrinterLecuter = async (to, from, next) => {
  if (!store.getters.getUser.id) {
    await auth();
  }

  if (store.getters.getUser.id && (store.getters.getUser.type_user !== "D")) {
    next();
  } else {
    next('/'); 
  }
};


