<template>
    <div class="container text-center mt-5">
      <LoadingObjet class="text-center" v-show="loaded" />
      
      <div class="mb-5" v-if="!loaded">
        <h6>Quantidade de Serviço executado por funcionário na data: {{ dateConvert(dateFilter) }}</h6>
          <button
            class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb-2 me-3"
            @click="opeBlockFilter"
          >
            <font-awesome-icon
              icon="arrow-up-wide-short"
              class="me-2"
              v-if="openFilter"
            />
            <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
            Filtrar
          </button>
           <!-- Filtros de pesquisa -->
          <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
            <div class="col-4">
              <label for="filtroData" class="form-label filtros">Data:</label>
              <input
                id="filtroData"
                type="date"
                class="form-control"
                value="2024-01-01"
                v-model="dateFilter"
              />
            </div>
            <div class="col-4">
              <label for="filtroEtapa" class="form-label filtros">Tipo de Funcionário:</label>
              <select id="filtroEtapa" class="form-select" v-model="typeUserFilter">
                <option value="" selected>Todos</option>
                <option value="D">Designer</option>
                <option value="I">Impressor</option>
                <option value="C">Conferente</option>
              </select>
            </div>
          </div>

          <h6 v-if="!processesList?.length && chartDataDay?.labels.length == 0" 
            class="text-secondary text-center"
            style="margin-top: 25px;"
          >
            Não encontramos resultados correspondentes à essa data <b>{{ dateConvert(dateFilter) }}</b>.
            <br />
            Tente ajustar os filtros para obter melhores resultados.
          </h6>
          <Bar v-if="chartDataDay?.datasets.length > 0" :data="chartDataDay" :options="chartOptionsDay" style="max-height: 260px;"/>
          <h6 v-if="chartDataDay?.datasets.length == 0 && chartDataDay?.labels.length > 0" 
            class="text-secondary text-center"
            style="margin-top: 30px;"
          >
          Desculpe, não encontramos resultados correspondentes à sua pesquisa.
          <br />
          Tente usar termos diferentes ou ajuste os filtros para obter melhores
          resultados.
          </h6>
      </div>
    </div>
  </template>
  <script>
  import { ref, reactive, onMounted, defineComponent, watch } from 'vue';
  import { Bar } from 'vue-chartjs';
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
  import LoadingObjet from '@/components/LoadingObjet';
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
  
  export default defineComponent({
    name: 'BarChart',
    components: { Bar, LoadingObjet, },
    props: {
      processesList: {
        type: Array,
        default: () => []
      },
      users: {
        type: Array,
        default: () => []
      },
      date: {
        type: String,
      },
      type: {
        type: String,
      },
    },
    setup(props, {emit}) {
      const loaded = ref(false);
      const typeUserFilter = ref(props.type);
      const dateFilter = ref(props.date);
      const chartDataDay = reactive({
        labels: [],
        datasets: []
      });
  
      const chartOptionsDay = ref({
        // responsive: true,
        plugins: {}
      });
  

      const chartDayNow = async () => {
        try {
          const userCounts = {};
          const users = props.users;  
          for (const process of props.processesList) {
             const uniqueUserIds = new Set();

            uniqueUserIds.add(process.designer);

            if (process.printer && process.printer !== process.designer) {
                uniqueUserIds.add(process.printer);
            }

            if (!process.printer && process.productor) {
                process.printer = process.productor;
            }

            if (process.printer && process.printer !== process.designer && process.printer !== process.productor) {
                uniqueUserIds.add(process.printer);
            }

            if (process.productor && process.productor !== process.printer && process.productor !== process.designer) {
                uniqueUserIds.add(process.productor);
            }

            if (process.lecturer && process.lecturer !== process.designer && process.lecturer !== process.printer && process.lecturer !== process.productor) {
                uniqueUserIds.add(process.lecturer);
            }

            for (const userId of uniqueUserIds) {
                if (userCounts[userId]) {
                    userCounts[userId]++;
                } else {
                    userCounts[userId] = 1;
                }
            }

            if (process.lecturer && process.lecturer !== process.designer && process.lecturer !== process.printer) {
              addProcessUser(process.lecturer, userCounts);
            }
          }

          for (const [userId, count] of Object.entries(userCounts)) {
            const user = users.find(user => user.id === parseInt(userId));
            if (user) {
                chartDataDay.datasets.push({
                label: user.name, 
                backgroundColor: getRandomColor(),
                data: [count]
                });
            }
          }

          chartDataDay.labels.push('Funcionário');

        } catch (e) {
          return e;
        }

      }
  
      const addProcessUser = (userId, userCounts) => {
        if (userCounts[userId]) {
          userCounts[userId]++;
        } else {
          userCounts[userId] = 1;
        }
      }
  
      const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }
  
      const dateConvert = (date) => {
        if(date){
            return date.slice(8,10)  + "/" + date.slice(5,7) + "/" + date.slice(0,4);
        }
        return '-';
      }

      onMounted(async () => {
        loaded.value = true;
        if(props.processesList.length > 0 ){
          await chartDayNow();
        }
        loaded.value = false;
      });

      watch(dateFilter, (newVal, oldVal) => {
        loaded.value = true;
        if(newVal !== oldVal){
          emit('filters', {date: newVal, type:typeUserFilter.value});
        }
        loaded.value = false;
      });

      watch(typeUserFilter, (newVal, oldVal) => {
        loaded.value = true;
        if(newVal !== oldVal){
          emit('filters', {date: dateFilter.value, type: newVal});
        }
        loaded.value = false;
      });

      const openFilter = ref(false);
      const opeBlockFilter = () => {
        openFilter.value = openFilter.value ? false : true;
      };
      return { 
        dateConvert,
        typeUserFilter,
        dateFilter,
        opeBlockFilter,
        openFilter,
        loaded, 
        chartDataDay, 
        chartOptionsDay,
      };
    }
  });
  </script>
  
  <style scoped>
 
  </style>
  