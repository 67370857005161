<template>
    <div class="container text-center mt-5">
      <LoadingObjet class="text-center" v-show="loaded" />
      
      <div class="mb-5" v-if="!loaded">
        <h6>Quantidade de Serviço por etapa: {{ dateConvert(dateFilter) }}</h6>
          <button
            class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb-2 me-3"
            @click="opeBlockFilter"
          >
            <font-awesome-icon
              icon="arrow-up-wide-short"
              class="me-2"
              v-if="openFilter"
            />
            <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
            Filtrar
          </button>
           <!-- Filtros de pesquisa -->
          <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
            <div class="col-4">
              <label for="filtroData" class="form-label filtros">Data:</label>
              <input
                id="filtroData"
                type="date"
                class="form-control"
                value="2024-01-01"
                v-model="dateFilter"
              />
            </div>
          </div>

          <h6 v-if="dateFilter == dateNow && chartData?.datasets.length == 0" 
            class="text-secondary text-center"
            style="margin-top: 25px;"
          >
            Não encontramos resultados correspondentes à essa data <b>{{ dateConvert(dateFilter) }}</b>.
            <br />
            Tente ajustar os filtros para obter melhores resultados.
          </h6>
          <Pie v-if="chartData?.datasets?.length" :data="chartData" :options="chartOptionsDay" style="max-height: 260px;"/>
          <h6 v-if="chartData?.datasets.length == 0 && chartData?.labels.length > 0 && dateNow !== dateFilter" 
            class="text-secondary text-center"
            style="margin-top: 25px;"
          >
          Desculpe, não encontramos resultados correspondentes à sua pesquisa.
          <br />
          Tente usar termos diferentes ou ajuste os filtros para obter melhores
          resultados.
          </h6>
      </div>
    </div>
  </template>
  <script>
  import { ref, reactive, onMounted, defineComponent, watch } from 'vue';
  import { Pie } from 'vue-chartjs'
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
  import LoadingObjet from '@/components/LoadingObjet';
import { getDateBrazil } from '@/utils';
  
  ChartJS.register(ArcElement, Tooltip, Legend)
  
  export default defineComponent({
    name: 'BarChart',
    components: { Pie, LoadingObjet, },
    props: {
      processesList: {
        type: Array,
        default: () => []
      },
      date: {
        type: String,
      }
    },
    setup(props, {emit}) {
      const loaded = ref(false);
      const dateFilter = ref(props.date);
      const dateNow = ref(getDateBrazil())
      const chartData = reactive({
        labels: ['Entrada', 'Produção', 'Conferente', 'Acabamento', 'Aguardando Entrega', 'Concluído'],
        datasets: []
      });
  
      const chartOptions = ref({
        // responsive: true,
        plugins: {}
      });
  

      const chartDayNow = async () => {
        try {
          const typeStages = ["D","P","I","A","E","C"];
          const objCountStage = { "D": 0,"P": 0,"I": 0,"A": 0,"E": 0,"C": 0};

          for (const stage of typeStages){
            objCountStage[stage] = (props.processesList.filter(process => process?.stage === stage))?.length
          }

          const data = [];
          for (const stage of typeStages){
            data.push(objCountStage[stage])
          }

          // chartData.labels.push(['Entrada', 'Produção', 'Conferente', 'Acabamento', 'Aguardando Entrega', 'Concluído']);
          const payloadDatasets = {
            backgroundColor: ['#C0392B', '#F7DC6F', '#F1948A ', '#5DADE2', '#58D68D', '#566573'],
            data: props.processesList.length > 0 ? data : []
          }
          chartData.datasets.push(payloadDatasets);
        } catch (e) {
          return e;
        }

      }
  
      const totalService = (material) => {
        let count = 0;
        const processList = props.processesList;
        if (material && processList.length > 0) {
          for (const process of processList) {
            if (process.material === material) {
              count += 1;
            }
          }
        }
        return count;
      }
      
      const dateConvert = (date) => {
        if(date){
            return date.slice(8,10)  + "/" + date.slice(5,7) + "/" + date.slice(0,4);
        }
        return '-'
      }

      onMounted(async () => {
        loaded.value = true;
        if(props.processesList.length > 0 ){
          await chartDayNow();
        }
        
        loaded.value = false;
      });

      watch(dateFilter, (newVal, oldVal) => {
        if(newVal !== oldVal){
          emit('filters', {date: newVal});
        }
      });

      const openFilter = ref(false);
      const opeBlockFilter = () => {
        openFilter.value = openFilter.value ? false : true;
      };
      return { 
        dateConvert,
        dateFilter,
        opeBlockFilter,
        openFilter,
        totalService,
        loaded, 
        chartData, 
        chartOptions,
        dateNow
      };
    }
  });
  </script>
  
  <style scoped>
 
  </style>
  