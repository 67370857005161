<template>

  <p class="fs-4">Bem-vindo <b>{{ $_store.state.userCurrent.name }}</b></p>
  <h1 class="display-1 fw-bold">{{ currentTime }}</h1>
  <p class="fs-4 mb-4 fw-bold">{{currentDate()}}</p>
  <ButtonDisabled
      buttonName="Registrar Ponto"
      class="btn btn-success w-100 mt-3 mb-4 fs-6"
      @click="registerPoint($_store.state.userCurrent)"
  />

</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import {getDateBrazil, formatDate} from "../../utils";
import moment from 'moment';
import {createPoint} from "../../http";
import store from "../../store";
import ButtonDisabled from '../../components/ButtonDisabled.vue';

export default {
  components: {
    ButtonDisabled
  },
  setup(props, {emit}) {
    const currentTime = ref(moment().format('HH:mm:ss'));
    const employeeName = 'Nome do Funcionário'; // Substitua pelo nome real do funcionário
    const msgOfPoint = ref("");
    const timeCopy = ref(false);
    const close = ref(false)
    const updateClock = () => {
      currentTime.value = moment().format('HH:mm:ss');
    };

    const currentDate = () => {
        return formatDate(getDateBrazil());
    }

    const emitValue = () => {
        emit('closeValue', {close:close.value, msg: msgOfPoint.value});
    }

    onMounted(() => {
      const interval = setInterval(updateClock, 1000);
      onUnmounted(() => {
        clearInterval(interval);
      });
    });

    const registerPoint = async (user) => {
        await createPoint({user: user.id, hitPoint: currentTime.value})
            .then((data) => {

              sessionStorage.setItem(`modalPoint${store.state.userCurrent.id}`, true);
              if(data?.id){
                  msgOfPoint.value = "Ponto Registrado ás: " + data?.firstTime;
              }else if(data?.message){
                  msgOfPoint.value = data?.message;
              }
                close.value = true;
                emitValue();
        });
        await store.dispatch('setPointUserCurrent');
        // await store.dispatch("setShowModalStartCurrent");
    };

    return {
      currentTime,
      employeeName,
      getDateBrazil,
      currentDate,
      registerPoint,
      msgOfPoint,
      timeCopy
    };
  },
};
</script>

<style scoped>
.custom-table {
  border-radius: 10px;
  overflow: hidden;
}
.modal-title {
  text-align: center;
}
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
