<template>

  <div class="container custom-mt" v-if="!$_store.state.openE">
    <div class="card mx-auto border-0 shadow-lg" style="max-width: 600px">
      <div class="card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120"
          />
        </div>
      </div>
      <div class="card-body">
        <h4 class="text-center">Informações do usuário</h4>
        <hr />
        <div class="mb-4 ps-2 pe-2">
          <b class="">Nome do usuário: </b>{{user?.name}}
        </div>

        <div class="mb-4 ps-2 pe-2">
          <b class="">Função do usuário: </b>{{formatTypeUser(user?.type_user)}}
        </div>

        <div class="mb-4 ps-2 pe-2">
          <b class="">E-mail do usuário: </b>{{user?.email}}
        </div>

        <hr />

        <div class="row mb-3">
          <div class="col-md-6">
            <div class="">
              <div class="mb-4 ps-2 pe-2">
                <b class="">Cadastrado em: </b>
                <br>
                {{convertDate(user?.createdAt)}}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="">
              <div class="mb-4 ps-2 pe-2">
                <b class="">Última atualização em: </b>
                <br>
                {{convertDate(user?.updatedAt)}}
              </div>
            </div>
          </div>
        </div>

        <div>
          <router-link :to="`${$_store.state.pagePrevious || '/'}`" class="btn btn-outline-danger btn-sm me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
            Voltar
          </router-link>
          
          <button 
           v-if="!openConfirm && user.id === $_store.state.userCurrent.id"
           type="button" 
           class="btn btn-primary btn-sm me-2" 
           @click="openEdit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
              />
            </svg>
            Editar
          </button>

          <button 
            class="btn btn-danger btn-sm" 
            v-if="!openConfirm &&
              $_store.state.userCurrent.type_user === 'A' &&
              user.id !== $_store.state.userCurrent.id
            "
            @click="openConfirmDelete"
          >
            <font-awesome-icon icon="trash-can" />
            Apagar
          </button>
          <!-- alerta -->
          <div class="card text-center mt-2" v-if="openConfirm">
            <span class="mt-2">Tem certeza que deseja apagar este registro?</span>
            <hr>
            
            <div class="mb-3">
              <button 
                class="btn btn-danger btn-dm me-2"
                @click="openConfirmDelete"
              >
                Não
              </button>
              
              <button 
                type="button" 
                class="btn btn-primary btn-dm me-2" 
                @click="deleteRegister">
                Sim
              </button>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
  <EditUser v-if="$_store.state.openE"/>
</template>

<script>
import {  computed, onBeforeMount, ref } from 'vue';
import store from '../../store';
import { useRoute } from "vue-router";
import router from "@/router";
import EditUser from './EditUser.vue';
import { deleteUser } from "../../http";
import { convertDate } from "../../utils";

export default {
  name: "ViewUser",
  props: {},
  setup() {
    
    const route = useRoute();
    const userId = ref(route.params.id || null);
    const user = computed(() => userId.value ? store.state.userViewEdit : store.state.userCurrent);
    
    const openEdit = () => {
      store.commit("setOpenEdit");
    }

    const formatTypeUser = (type) => {
      const  typesUsers = {
        A: 'Administrador',
        D: 'Design',
        I: 'Impressor',
        C: 'Conferente'
      };
      return typesUsers[type];
    }

    const openConfirm = ref(false);

    const openConfirmDelete =  () => {
      openConfirm.value = openConfirm.value ?  false : true;
    }
    
    const  deleteRegister = async () => {
      await deleteUser(user.value.id)
        .then(()=>{
          const directPath = `${store.state.pagePrevious || "/"}`
          router.push(directPath);
      });
    }

    onBeforeMount(async () => {
      if (userId.value) {
        await store.dispatch("getUser", userId.value);
      }
    });

    return {
      convertDate,
      formatTypeUser,
      user,
      openEdit,
      openConfirm,
      openConfirmDelete,
      deleteRegister,
    }
  },
  components:{
    EditUser
  }
};
</script>
<style scoped>
/*colocar o estilo da página ou componente*/
label {
  font-family: Arial, Helvetica, sans-serif;
}

p {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  font-style: italic;
  font-size: large;
}

.custom-mt {
  margin-top:50px;
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
