<template>
    <div>
      <div class="container custom-mt">
        <div class="card mx-auto border-0 shadow-lg" style="max-width: 450px">
          <div class="card-header bg-transparent border-0"></div>
          <div>
            <img
              src="../../assets/logo.png"
              alt="Logomarca"
              class="img-fluid mx-auto d-block mt-4"
              width="120
              "
            />
          </div>
          <div class="card-body">
            <div class="" v-if="!token">
                <div class="mb-2 ps-2 pe-2">
                    <label for="funcao" class="form-label">Função do usuário:</label>
                    <select
                      v-model="typeRegister"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option 
                       value="" 
                       selected disabled
                       placeholder="tetes"
                       >
                        Selecione...
                     </option>
                      <option
                        value="D"
                      >
                        Designer
                      </option>
          
                      <option
                        value="I"
                      >
                        Impressor
                      </option>
          
                      <option
                        value="C"
                      >
                        Conferente
                      </option>
                    </select>
                    <p class="errorLabel">{{ errorLabel.typeRegister }}</p>
                  </div>
            </div>

            <div class="alert alert-light clickable" v-if="token" @click="copyToken(token)">
                <p class="text-center alert alert-success" v-if="timeCopy">copiado</p>
                <h6 class="my-4"><b>TOKEN:</b> {{token}} <span class="ml-5"><font-awesome-icon icon="copy" /></span></h6>
            </div>
            
            <div class="d-grid gap-2 mt-3">
              <button 
                v-if="!token" 
                class="btn btn-dark" 
                @click="sedToken">
                Gerar Token
              </button>

              <button v-if="token" 
                class="btn btn-outline-dark" 
                @click="outherToken">
                Gerar outro Token
              </button>
            </div>
            
            <div class="d-grid gap-2">
              <button
                class="btn btn-outline-danger mb-4 mb-2 mt-2"
                @click="back"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-bar-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                  />
                </svg>
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import { useRouter } from "vue-router";
  import { createToken } from "../../http"; 

  export default {
    name: "GenerateToken",
    props: {},
    setup() {
      const typeRegister = ref(null);
      const token = ref('');

      const errorLabel = ref({
        typeRegister: null,
      });

      const router = useRouter();
  
      const back = () => {
        router.push("/");
      };
  
      const sedToken = async () => {

        errorLabel.value.typeRegister = null; 
        if(!typeRegister.value){
            errorLabel.value.typeRegister = "Escolha uma função!";
            return
        }

        const reponseToken = await createToken(typeRegister.value);
        token.value = reponseToken.token;
        
      };

      const outherToken = () => {
        typeRegister.value = null;
        token.value = null;
      }
      const timeCopy = ref(false);

      const copyToken = (data) => {
        const input = document.createElement('input');
        input.value = data;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);

        timeCopy.value = true;
        setTimeout(() => {
          timeCopy.value = false;
        }, 3000);

      };

      return {
        errorLabel,
        typeRegister,
        back,
        sedToken,
        token,
        outherToken,
        copyToken,
        timeCopy,
      };
    },
  };
  </script>
  <style scoped>
  /*colocar o estilo da página ou componente*/
  
  label,
  button {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .custom-mt {
    margin-top: 150px;
    /* Ajuste a margem superior conforme necessário */
  }
  .errorLabel {
    font-size: 14px;
    color: red;
  }

  .clickable {
    cursor: pointer;
  }
  </style>
  