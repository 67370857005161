<template>
  <div class="container custom-mt">
    
    <div class="card mx-auto border-0 shadow-lg" style="max-width: 500px">
      <div class="card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120"
          />
        </div>
      </div>

      <div class="card-body">
        <h4 class="text-center mb-4"><b>Entrada</b></h4>

        <div class="text-white error-label text-center" v-if="timeError">
          <p class="">{{ errorLabel.messageError }}</p>
        </div>
        
        <div class="mb-4 ps-2 pe-2">
          <label for="nome" class="form-label">Cliente</label>

          <div>
            <div class="search-container">
              <span
                class="search-icon"
                v-if="!processPayload.client && !searchTerm"
              >
                <font-awesome-icon icon="magnifying-glass" />
              </span>
              <input
                v-model="searchTerm"
                @input="filterClients"
                :placeholder="getClientName(processPayload.client)"
                class="form-control"
              />
              <span
                class="clear-icon"
                @click="clearSearch"
                v-if="processPayload.client"
              >
                <font-awesome-icon icon="circle-xmark" />
              </span>
            </div>
            <select
              class="form-control"
              v-model="processPayload.client"
              size="3"
              v-if="searchTerm"
            >
              <option
                v-for="client in filteredClients"
                :key="client.id"
                :value="client.id"
              >
                {{ `${client.name}` }}
              </option>
            </select>
          </div>

          <p class="p-error-label">{{ errorLabel.client }}</p>
        </div>

        <div class="mb-3 ps-2 pe-2">
          <InputSelect  v-if="materials" :objetcSelect="materials" :setTitle="'Material'" @objectId="materialValue"/>
          <p class="p-error-label">{{ errorLabel.material }}</p>
        </div>

        <div class="mb-2 ps-2 pe-2 container-fluid">
          <label for="largura" class="form-label">Medidas do Material:</label>
          <div class="d-flex">

            <div class="row col me-1">
              <div class="col-12">
                <input
                  v-model="processPayload.width"
                  type="number"
                  class="form-control mb-2"
                  id="largura"
                  placeholder="Largura (metros)"
                  style=""
                />
                <p class="p-error-label">{{ errorLabel.width }}</p>
              </div>
            </div>

            <div class="row col">
              <div class="col-12">
                <input
                  v-model="processPayload.height"
                  type="number"
                  class="form-control"
                  id="altura"
                  placeholder="Altura (metros)"
                  style=""
                />
                <p class="p-error-label">{{ errorLabel.height }}</p>
              </div>
            </div>
          
          </div>
        </div>

        <div class="mb-4 ps-2 pe-2">
          <label for="quantidade" class="form-label">Quantidade:</label>
          <input
            v-model="processPayload.amount"
            type="number"
            class="form-control"
            id="quantidade"
            placeholder="Quantidade"
            style="width: 200px;"
          />
          <p class="p-error-label">{{ errorLabel.amount }}</p>
        </div>

        <div
          :class="`mb-4 ps-2 pe-2 ${
            processPayload.image ? 'd-flex align-items-center' : ''
          }`"
        >
          <div class="">
            <label for="formFileMultiple" class="form-label">Imagem</label>
            <input
              ref="fileInput"
              class="form-control"
              type="file"
              id="formFileMultiple"
              @change="handleFileChange"
            />
            <p class="p-error-label">{{ errorLabel.image }}</p>
          </div>
          <div
            v-if="processPayload.image"
            class="ms-4 mt-auto d-flex align-items-center justify-content-end flex-wrap"
          >
            <img :src="processPayload.image" alt="" height="60" width="60" />
          </div>
        </div>
        <div class="ps-2 pe-2">
          <label for="urgencia" class="form-label fw-bold">Prioridade</label>
        </div>

        <div class="mb-4 ps-2 pe-2 d-flex align-items-center">
          <div class="form-check me-4">
            <input
              v-model="processPayload.status"
              class="form-check-input"
              type="radio"
              name="nomal"
              id="normal"
              value="N"
              checked
            />
            <label class="form-check-label" for="normal"> Normal </label>
          </div>

          <div class="form-check">
            <input
              v-model="processPayload.status"
              class="form-check-input"
              type="radio"
              name="urgencia"
              id="urgente"
              value="U"
            />
            <label class="form-check-label" for="urgente"> Urgente </label>
          </div>
        </div>

        <div class="form-group my-3">
          <label for="comment">Comentário (opcional)</label>
          <textarea
            v-model="processPayload.comment"
            class="form-control"
            id="comment"
            rows="3"
          >
          </textarea>
        </div>

        <div class="d-grid gap-2">
          <button class="btn btn-dark ms-2 me-2" @click="registerProcess" v-if="!sendProcess">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-person-check"
              viewBox="0 0 16 16"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
                />
                <path
                  d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"
                />
              </svg>
            </svg>
            Registrar
          </button>
          <button class="btn btn-dark ms-2 me-2" type="button" disabled v-if="sendProcess">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Enviando...
          </button>
          <button
            class="btn btn-outline-danger mb-4 ms-2 me-2"
            @click="backHome"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </svg>
            Voltar
          </button>
        </div>
      </div>
    </div>
  

  </div>
  <!-- Modal -->
</template>

<script>
import { computed, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import store from "../../../store";
import { createProcessDesign } from "../../../http";
import { sendEventProcess, sendEventNotification } from "../../../socket";
import { getReponseMaterial, getReponseClients } from "../../../socket"
import InputSelect from "@/components/InputSelect.vue";

export default {
  name: "CreateProcesses",
  props: {},
  setup() {
    const router = useRouter();
    const clients = computed(() => store.state.clientsList);
    const materials = computed(() => store.state.materialList);
    const user = computed(() => store.state.userCurrent);
    const timeError = ref(false);
    const sendProcess = ref(false);

    const processPayload = ref({
      client: null,
      status: "N",
      comment: null,
      image: null,
      material: null,
      amount: null,
      height: null,
      width: null,
      member: user.value.id,
    });

    const errorLabel = ref({
      client: null,
      messageError: null,
      image: null,
      material: null,
      amount: null,
      height: null,
      width: null,
    });

    const validateForm = () => {

      errorLabel.value.client = null;
      errorLabel.value.description = null;
      errorLabel.value.messageError = null;
      errorLabel.value.image = null;
      errorLabel.value.material = null;
      errorLabel.value.amount = null;
      errorLabel.value.height = null;
      errorLabel.value.width = null;

      if(!processPayload.value?.image){
        errorLabel.value.image = "Campo obrigatório.";
      }

      if(!processPayload.value?.material){
        errorLabel.value.material = "Campo obrigatório.";
      }

      if(!processPayload.value?.amount){
        errorLabel.value.amount = "Campo obrigatório.";
      }

      if(!processPayload.value?.height){
        errorLabel.value.height = "Campo obrigatório.";
      }

      if(!processPayload.value?.width){
        errorLabel.value.width = "Campo obrigatório.";
      }

      if (!processPayload.value?.client) {
        errorLabel.value.client = "Campo obrigatório.";
      }

      if (errorLabel.value.client || 
          errorLabel.value.material ||
          errorLabel.value.amount ||
          errorLabel.value.height ||
          errorLabel.value.image ||
          errorLabel.value.width
        ) {
        return true;
      }
      return false;
    };

    const registerProcess = async () => {
      sendProcess.value = true;
      if (validateForm()) {
        sendProcess.value = false;
        return;
      }

      const responseProcess = await createProcessDesign(processPayload.value);

      if (responseProcess?.message) {
        errorLabel.value.messageError = responseProcess?.message;
        timeError.value = true;
        setTimeout(() => {
          timeError.value = false;
        }, 5000);
        return;
      } else {
        responseProcess.material = processPayload.value.material;
        responseProcess.amount = processPayload.value.amount;
        responseProcess.height = processPayload.value.height;
        responseProcess.width = processPayload.value.width;
        responseProcess.materials = materials.value?.find(mt => mt.id === responseProcess.material);

        sendEventNotification("graphic", true);
        sendEventProcess("graphic", responseProcess);
      }

      sessionStorage.setItem("reload", 1);
      sendProcess.value = false;
      router.push({ name: "home" });
    };

    const backHome = () => {
      router.push("/");
    };

    const searchTerm = ref("");

    const filteredClients = computed(() => {
      const term = searchTerm.value.toLowerCase();
      return clients.value?.filter(
        (client) =>
          client.name.toLowerCase().includes(term) || client.fone.includes(term)
      );
    });

    const getClientName = (clientId) => {
      if (!searchTerm.value && !processPayload.value.client) {
        return "    Pesquisar cliente...";
      }

      const foundClient = clients.value?.find(
        (client) => client.id === clientId
      );

      if (foundClient) {
        searchTerm.value = "";
        return foundClient ? foundClient.name : "";
      }
    };

    const clearSearch = () => {
      searchTerm.value = "";
      processPayload.value.client = null;
    };

    const filterClients = () => {};
    const filterMaterials = () => {};

    const fileInput = ref(null);
    const handleFileChange = (event) => {
      processPayload.value.image = null;
      errorLabel.value.image = null;

      const fileInput = event.target;
      const file = fileInput.files[0];
      const LIMIT_IMAGE = 200; // kb
      const size = +file["size"] / 1024;

      if (size > LIMIT_IMAGE) {
        fileInput.value = null;
        errorLabel.value.image =
          "Imagem muito grande, tamanho máximo permitido 200KB";
        return;
      }

      if (file && size <= LIMIT_IMAGE) {
        const reader = new FileReader();

        reader.onload = () => {
          processPayload.value.image = reader.result;
        };
        reader.readAsDataURL(file);
      }
    };

    const materialValue = (event) => {
      processPayload.value.material = event;
    }

    onBeforeMount(async () => {
      await store.dispatch("setClientListCurrent");
      await store.dispatch("setMaterialListCurrent");
      getReponseMaterial();
      getReponseClients();
    });

    return {
      materialValue,
      materials,
      fileInput,
      handleFileChange,
      filteredClients,
      filterClients,
      filterMaterials,
      searchTerm,
      clearSearch,
      getClientName,
      registerProcess,
      processPayload,
      backHome,
      clients,
      errorLabel,
      timeError,
      sendProcess,
    };
  },
  components:{
    InputSelect
  }

};
</script>

<style scoped>
h1 {
  font-weight: bold;
}
h1,
label,
button {
  font-family: Arial, Helvetica, sans-serif;
}

.custom-mt {
  margin-top: 10px;
  margin-bottom: 80px;
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-label {
  background-color: #be3f3f;
  border-radius: 5px;
  overflow: hidden;
}

.p-error-label {
  font-size: 14px;
  color: red;
}

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  color: #888; /* Cor do ícone de pesquisa (pode ser personalizado) */
}

.clear-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  color: #888; /* Cor do ícone (pode ser personalizado) */
}

.custom-link {
  font-size: 15px;
  color: gray;
  font-weight: bold;
  text-decoration: none; /* Remove sublinhado */
  transition: color 0.3s, background-color 0.3s; /* Transição de cor e fundo */
}

.custom-link:hover {
  color: #020202; /* Cor cinza no hover */
  font-weight: bold;
}

/* Adicione um espaçamento entre o label e o link */
.form-label {
  margin-right: 10px;
}

.clickable {
  cursor: pointer;
}
</style>
