<template>
  
    <div class="container">
      <div v-if="!$_store.state.existProcess" class="alert alert-light mt-3">
        <h5 class="custom-mt text-center mb-5">
          <font-awesome-icon icon="circle-exclamation" />
          Atualmente, não há registros disponiveís.
        </h5>
      </div>
      <div class="d-flex flex-row justify-content-between align-items-center">
        <p class="d-flex align-items-center ms-2 mb-2">
          {{filteredProcess?.length > 0 ? filteredProcess?.length  + ' resultado encontrado.' : ''}}
        </p>
        <button
            v-if="$_store.state.existProcess"
            class="btn btn-sm btn-outline-dark d-flex align-items-center mb-2 me-3"
            @click="opeBlockFilter"
        >
            <font-awesome-icon
                icon="arrow-up-wide-short"
                class="me-2"
                v-if="openFilter"
            />
            <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
            Filtrar
        </button>
    </div>
    
      <!-- Filtros de pesquisa -->
      <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
        <div class="col-md-2">
          <label for="filtroNome" class="form-label filtros"
            >Nome do cliente:</label
          >
          <input
            id="filtroNome"
            class="form-control"
            placeholder="Digite o cliente"
            v-model="nameProcess"
          />
        </div>
        <div class="col-md-2" v-if="user?.type_user === 'A'">
          <label for="filtroTelefone" class="form-label filtros">Telefone:</label>
          <input
            id="filtroTelefone"
            class="form-control"
            placeholder="(00) 00000-0000"
            v-mask="'(##) #####-####'"
            v-model="foneProcess"
          />
        </div>
        <div class="col-md-2">
          <label for="filtroData" class="form-label filtros">Data:</label>
          <input
            id="filtroData"
            type="date"
            class="form-control"
            value="2024-01-01"
            v-model="dateProcess"
          />
        </div>
        <div class="col-md-2">
          <label for="filtroStatus" class="form-label filtros">Status:</label>
          <select id="filtroStatus" class="form-select" v-model="statusProcess">
            <option value="" selected>Todos</option>
            <option value="N">Normal</option>
            <option value="U">Urgente</option>
          </select>
        </div>
  
        <div class="col-md-2">
          <label for="filtroEtapa" class="form-label filtros">Etapa:</label>
          <select id="filtroEtapa" class="form-select" v-model="stageProcess">
            <option value="" selected>Todos</option>
            <option value="D">Entrada</option>
            <option value="I">Conferente</option>
            <option value="P">Produção</option>
            <option value="A">Acabamento</option>
            <option value="C">Concluído</option>
          </select>
        </div>
      </div>
  
      <div class="table-responsive mb-5">
        <table
          className="table table-dark table-striped custom-table"
          v-if="processList?.length > 0"
        >
          <thead>
            <tr>
              <th scope="col">N°</th>
              <th scope="col">
                {{ user.type_user === "A" ? "Cliente/telefone" : "Cliente" }}
              </th>
              <th scope="col">Material</th>
              <th scope="col">Status</th>
              <th scope="col">Etapa</th>
              <th scope="col">Criação</th>
              <th scope="col">Ultima Atualização</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="item in sortProcess(filteredProcess || [])"
              :key="item.id"
            >
              <tr class="align-items-center">
                <td class="col-auto clickable" @click="viewProcesss(item.id)" data-bs-dismiss="modal" aria-label="Close">
                  {{ item.id + 1000 }}
                </td>
                <td class="col clickable" @click="viewProcesss(item.id)" data-bs-dismiss="modal" aria-label="Close">
                  {{ getClientName(item.client) }}
                </td>
                <td class="col clickable" @click="viewProcesss(item.id)" data-bs-dismiss="modal" aria-label="Close">
                  {{ getMaterial(item) }}
                </td>
                <td class="col clickable" @click="viewProcesss(item.id)" data-bs-dismiss="modal" aria-label="Close">
                  {{ typeStatus(item.status) }}
                </td>
                <td class="col clickable" @click="viewProcesss(item.id)" data-bs-dismiss="modal" aria-label="Close">
                  {{ stateFormat(item) }}
                </td>
                <td class="col clickable" @click="viewProcesss(item.id)" data-bs-dismiss="modal" aria-label="Close">
                  {{ convertDate(item.createdAt) }}
                </td>
                <td class="col clickable" @click="viewProcesss(item.id)" data-bs-dismiss="modal" aria-label="Close">
                  {{ convertDate(item.updatedAt) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  
    <div>
      <h6
        v-if="
          !filteredProcess?.length &&
          (nameProcess || statusProcess || foneProcess || stageProcess || dateProcess != dateAt)
        "
        class="text-secondary text-center mb-4"
      >
        Desculpe, não encontramos resultados correspondentes à sua pesquisa.
        <br />
        Tente usar termos diferentes ou ajuste os filtros para obter melhores
        resultados.
      </h6>
      <h6
        v-if="$_store.state.existProcess && !filteredProcess?.length && dateProcess == dateAt && !(nameProcess || statusProcess || foneProcess || stageProcess)"
        class="text-secondary custom-mt text-center"
      >
        Desculpe, não encontramos resultados correspondentes à essa data {{ formatLabelDate(dateProcess) }}.
        <br />
        Tente ajustar os filtros para obter melhores resultados.
      </h6>
    </div>
  </template>
  
<script>
  import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
  import store from "../../store";
  import router from "@/router";
  import { mask } from "vue-the-mask";
  import { getDateBrazil, convertDate, getMaterialInList } from "../../utils";
  import { getReponseProcess, getReponseStageProcess } from "../../socket";
  
  export default {
    props: {},
    directives: { mask },
    setup() {
      const processList = computed(
        () => store.state.processList?.slice().sort((a, b) => b.id - a.id) || []
      );
      const clients = computed(() => store.state.clientsList);
      const user = computed(() => store.state.userCurrent);
      const materials = computed(() => store.state.materialListView);
  
      const sortProcess = (arr = []) => {
        return arr?.sort((a, b) => {
          if (a.finished === 1 && b.finished !== 1) {
            return 1;
          }
          if (a.finished !== 1 && b.finished === 1) {
            return -1;
          }
  
          return b.id - a.id;
        });
      };
  
      const mergeClientData = (processes, clients) => {
        return processes?.map((item) => {
          const foundClient = clients.find((c) => c.id === item.client);
          const mergedData = { ...item, ...foundClient };
  
          mergedData.id = item.id;
          mergedData.createdAt = item.createdAt;
          mergedData.updatedAt = item.updatedAt;
  
          return mergedData;
        });
      };
  
      const dataProcesses = computed(() => {
        return mergeClientData(processList?.value, clients.value);
      });
  
      const stateFormat = ({ stage, finished }) => {
        if (finished == 1) {
          return "Concluído";
        }

        switch (stage) {
          case "D":
            return "Entrada";
          case "I":
            return "Conferente";
          case "C":
            return "Concluído";
          case "P":
            return "Produção";
          case "A":
            return "Acabamento";
          case "E":
            return "Aguardando Entrega"
        }
      };
  
      const typeStatus = (type) => {
        switch (type) {
          case "U":
            return "Urgente";
          case "N":
            return "Normal";
        }
      };
  
      const getClientName = (clientId) => {
        const foundClient = clients.value.find(
          (client) => client.id === clientId
        );
  
        const formatFone = (fone) => {
          return `(${fone.slice(0, 2)}) ${fone.slice(2, 7)}-${fone.slice(7)}`;
        };
  
        return foundClient
          ? foundClient.name +
              (user?.value?.type_user === "A"
                ? " - " + formatFone(foundClient.fone)
                : "")
          : "-";
      };
  
      const dateAt = ref(getDateBrazil())
      const nameProcess = ref("");
      const dateProcess = ref(dateAt.value);
      const foneProcess = ref("");
      const statusProcess = ref("");
      const stageProcess = ref("");
  
      const formatLabelDate = (date) => {
        const arrDate = date.split("-");
        return date ? arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0] : "";
      };
  
      const filteredProcess = computed(() => {
        const nameClient = nameProcess.value.toLowerCase();
        const foneClient = foneProcess.value.replace().replace(/[()\- ]/g, "");
        const _statusProcess = statusProcess.value;
        const _stageProcess = stageProcess.value;
        const _dateProcess = formatLabelDate(dateProcess.value);
  
        return dataProcesses.value?.filter(
          (process) =>
            process.name?.toLowerCase().includes(nameClient) &&
            process.fone?.includes(foneClient) &&
            convertDate(process.createdAt).includes(_dateProcess) &&
            process.status?.includes(_statusProcess) &&
            process.stage?.includes(_stageProcess)
        );
      });
  
      const viewProcesss = (id) => {
        router.push("/view-process/" + id);
      };
  
      const openFilter = ref(false);
      const opeBlockFilter = () => {
        openFilter.value = openFilter.value ? false : true;
      };
  
      const getMaterial = (item) => {
        return getMaterialInList(materials.value, item);
      }

      watch(dateProcess, async (newVal, oldVal) => {
        if (oldVal !== newVal) {
          const listProcesses = await store.dispatch("setMachineListHistory",{ date: newVal, machine: store.typeEventMachine?.machine} );
  
          if(!listProcesses?.message){
            const processIds = listProcesses?.map((item)=> item.material) || [];   
            await store.dispatch('getMaterialListView', processIds);
          }
  
        }
      });
  
      onBeforeMount(async () => {
        await store.dispatch("getExistProcess", false);
        await store.dispatch("setClientListCurrent");
      });
  
      onMounted(() => {
        getReponseProcess();
        getReponseStageProcess();
      });
  
      return {
        dateAt,
        getMaterial,
        user,
        opeBlockFilter,
        openFilter,
        sortProcess,
        filteredProcess,
        nameProcess,
        dateProcess,
        foneProcess,
        statusProcess,
        processList,
        convertDate,
        typeStatus,
        stateFormat,
        clients,
        getClientName,
        dataProcesses,
        viewProcesss,
        stageProcess,
        formatLabelDate,
      };
    },
  };
</script>
<style scoped>
  .custom-mt {
    margin-top: 70px;
  }
  
  .custom-table {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 80px;
  }
  
  .filtros {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .overlay-background {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .overlay-background2 {
  
    background-color: rgba(0, 0, 0, 0); 
  }
  
  /* CSS para ajustar a largura e altura da imagem dentro do modal */
  .modal-dialog {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
  }
  
  .modal-body {
    padding: 0;
  }
  
  .enlarged-image {
    width: 80%;
    height: 80%;
    object-fit: contain;
  
  }
  </style>
  