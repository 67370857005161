<template>
  <div>
    <div class="modal-backdrop" ></div>
    <div
      class="modal show"
      tabindex="-1"
      role="dialog"
      style="display: block;"
      aria-modal="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div :class="`${close ? 'alert-custom2' : ''} modal-header`"> 
            <h5 class="modal-title">Sistema de Ponto Eletronico <small class="text-secondary">(Obrigatório)</small></h5> 
            <button v-if="close" type="button" class="btn-close" aria-label="Close" @click="start"></button>
          </div>
          <div :class="`${close ? 'alert-custom' : ''} modal-body text-center`">
            <HitPoint @closeValue="closeValue" v-if="!close"/>
            <div v-if="close" >
              <h6 >Ponto Registrado com sucesso!, pode fechar o modal para seguir</h6>
              <button type="button" class="btn btn-secondary col-10 mt-4" id="closeBtnModalStart"  @click="start">Fechar</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import  HitPoint from "../pages/point/hitPoint.vue";
import { ref, watch } from 'vue';
import store from "@/store";

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
    }
  },
  setup(){
    const close = ref(false);

    const closeValue = (event) => {
      close.value = event.close;
    }
    
    const start = () => {
      window.location.reload();
    }

    watch(close, (newValue) => {
      if(newValue){
        sessionStorage.setItem(`modalPoint${store.state.userCurrent.id}`, 'true');
      }
    });
    return {
      close,
      closeValue,
      start
    }
  },
  components: {
    HitPoint
  }
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo meio opaco */
  z-index: 1040; /* Abaixo do modal */
}

.modal.show {
  display: block;
  z-index: 1050; /* Acima do backdrop */
}
.alert-custom{
  background-color: #F0FFF0;
  font-weight: bold; 
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.alert-custom2{
  background-color: #F0FFF0;
  font-weight: bold; 
}
</style>