<template>
    <div class="floating-chat-balloon position-floating clickable" v-if="$_store.state.userCurrent?.id" data-bs-toggle="modal" data-bs-target="#staticBack">
        <div class="chat-icon">
            <font-awesome-icon icon="comment-dots" class="text-dark"/>
            <span class="badge text-bg-danger count" >
                {{ $_store.state.countGeralMessage > 0 ? $_store.state.countGeralMessage : '' }}
            </span>
        </div>
    </div>
</template>

<script>
import { onBeforeMount } from 'vue';
import { getReponseNotificationMessage } from '@/socket'
export default {
    setup(){

        onBeforeMount( () => {
          getReponseNotificationMessage();
        })
        return  {

        }
    }
}
</script>

<style scoped>
.position-floating {
    margin-top: 20%;
}

.floating-chat-balloon {
    position: fixed;
    right: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}
.t{
    padding: 0px;
}

.count{
    font-size: .70rem; 
    position: absolute; 
    top: -8px; right: -8px;
}
.chat-icon {
    font-size: 24px;
    padding: 10px;
}

.chat-icon:hover {
    font-size: 34px;
    transition: font-size 0.3s; 
}

.count:hover{
    font-size: 1rem; 
    transition: font-size 0.3s; 
}

</style>
