<template>

  <div class="container custom-mt-g">

    <div class="container mt-5 mb-5">
      <div class="card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120"
          />
        </div>
        <h4 class="text-center my-3">
          <font-awesome-icon icon="database" />
          Manipulação de Dados
        </h4>
        <hr />
         <p class="text-center alert alert-success" v-if="timeMsg">Foram deletado com sucesso <b>{{countDelete}}</b> serviços!!</p>
      </div>
    </div>

    <div class="col-sm-12 col-lg-12 mb-4">
        <div class="card text-dark bg-white">
          <div class="card-header fs-5 text fw-bold bg-white text-dark">
            Remoção e Backup de serviços
          </div>
          <div class="card-body">
            
            <div class="row">

              <div id="div1" class="col-6">
                  <p class="card-text">Filtre os serviços a partir de uma data específica.</p>
                  <a 
                    class="btn btn-dark"
                    @click="setOpenFilter"
                    v-if="!openFilter"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-box-arrow-in-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                      />
                    </svg>
                    Iniciar
                  </a>
                  <a
                      class="btn btn-outline-danger"
                      @click="setOpenFilter"
                      v-else
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-bar-left"
                        viewBox="0 0 16 16"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-bar-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                          />
                        </svg>
                      </svg>
                      Cancelar
                    </a>  

                  <!--  Filtro -->
                  <div class="row mb-4 d-flex justify-content-start" v-if="openFilter"> 
                    <div class="col-md-4 mt-3">
                      <label for="filtroData" class="form-label filtros">Data Inicial:</label>
                      <input
                        id="filtroData"
                        type="date"
                        class="form-control"
                        value="2024-01-01"
                        v-model="dateStart"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <label for="filtroData" class="form-label filtros">Data Final:</label>
                      <input
                        id="filtroData"
                        type="date"
                        class="form-control"
                        value="2024-01-01"
                        v-model="dateEnd"
                      />
                    </div>
                    <div class="col-md-2">
                      <button 
                        v-if="(dateStart.length && dateEnd.length)"
                        class="btn btn-dark form-control mt-5"
                        @click="getProcesses"
                      >
                        Filtrar
                      </button>
                    </div>
                    
                  </div>

              </div>

              <div id="div2" class="col-6 text-center">
                  <LoadingObjet class="text-center mt-5" v-show="loadPoint" />
                  <p v-if="!loadPoint && dateStart.length && dateEnd.length && clickFilter">Foram encontrado {{processes.length}} resultado para data {{formatDate(dateStart) || '-'}} até {{formatDate(dateEnd) || '-'}}</p>  
                  <div v-if="!loadPoint && processes.length"> 
                    <button class="btn btn-outline-success me-3" @click="backupProcess">Backup</button>
                    <button class="btn btn-outline-danger" @click="deleteProcessForLote">Remover</button>
                  </div>
              </div>
            </div>
 
          </div>
        </div>
      </div>
    
  </div>
</template>

<script>
import { ref, computed, onBeforeMount} from 'vue';
import { formatDate, convertDate } from '@/utils';
import { getProcessesForDate, deleteProcess } from '@/http';
import LoadingObjet from '@/components/LoadingObjet';
import store from "@/store";

export default {
  setup() {
    const openFilter = ref(false);
    const dateEnd = ref('');
    const dateStart = ref('');
    const processes = ref([]);
    const loadProcess = ref(false);
    const clickFilter = ref(false);
    const users = computed(() => store.state.users);
    const machines = computed(() => store.state.machineList);
    const materials = computed(() => store.state.materialList);
    const clientList = computed(() => store.state.clientsList );
    const countDelete = ref(0);
    const timeMsg = ref(false);
    const columnHeaders = ref({
      "id": "ID",
      "client": "Cliente",
      "status": "Status",
      "stage": "Estágio",
      "finishing": "Acabamento",
      "designer": "Designer",
      "printer": "Impressora",
      "lecturer": "Conferente",
      "height": "Altura",
      "width": "Largura",
      "amount": "Quantidade",
      "material": "Material",
      "machine": "Máquina",
      "finished": "Concluído",
      "createdAt": "Criado Em",
      "updatedAt": "Atualizado Em",
    });

    const getNameUser = (id) => {
      const foundUser = users.value.find((user) => user.id == id);
      return foundUser?.name || "";
    };

    const getNameMaterial = (id) => {
      const foundUser = materials.value.find((user) => user.id == id);
      return foundUser?.name || "";
    };

    const getNameMachine = (id) => {
      const foundUser = machines.value.find((user) => user.id == id);
      return foundUser?.name || "";
    };

    const getNameClient = (id) => {
      const foundUser = clientList.value.find((user) => user.id == id);
      return foundUser?.name || "";
    };

    const transformJson = (data) => {
        return data.map(item => {
            const transformedItem = {
                ...item,
                client: getNameClient(item.client),
                status: item.status === 'N' ? 'Normal' : 'Urgente',
                stage: (() => {
                    switch(item.stage) {
                        case 'D': return 'Designer';
                        case 'I': return 'Impressor';
                        case 'C': return 'Conferente';
                        case 'P': return 'Produção';
                        case 'A': return 'Acabamento';
                        case 'E': return 'Entregue';
                        default: return item.stage;
                    }
                })(),
                designer: getNameUser(item.designer),
                printer: getNameUser(item.printer),
                lecturer: getNameUser(item.lecturer),
                material: getNameMaterial(item.material),
                machine: getNameMachine(item.machine),
                createdAt: convertDate(item.createdAt),
                updatedAt: convertDate(item.updatedAt),
                finished: item.finished == 1 ? 'Sim' : 'Não'
            };

            delete transformedItem.process;
            delete transformedItem.edit;
            delete transformedItem.delivery;

            return transformedItem;
        });
    }

    const convertToCSV = (objArray, headers) => {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = '';

      // Cabeçalhos personalizados
      for (const key in headers) {
        row += headers[key] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';

      // Dados
      for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const key in headers) {
          if (line !== '') line += ',';
          line += array[i][key] !== null && array[i][key] !== undefined ? array[i][key] : '';
        }
        str += line + '\r\n';
      }
      return str;
    };

    const backupProcess = () => {
      const csvData = convertToCSV(processes.value, columnHeaders.value);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', `backup-servico-data-${dateStart.value}-a-${dateEnd.value}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const deleteProcessForLote = async () => {
      loadProcess.value = true;

      for (let item of processes.value){
        await deleteProcess(item.id);
        countDelete.value += 1;
        timeMsg.value = true;
        setTimeout(() => {
          timeMsg.value = false;
        }, 4000);
      }

      dateStart.value = '';
      dateEnd.value = '';
      processes.value = [];
      loadProcess.value = false;
    }

    const getProcesses = async () => {
      loadProcess.value = true;
      const requestProcess = await getProcessesForDate(dateStart.value, dateEnd.value);
      if(!requestProcess?.message){
        processes.value = transformJson(requestProcess);
      }else{
        processes.value = [];
      }
      loadProcess.value = false;
      clickFilter.value = true;
    }

    const setOpenFilter = () => {
      openFilter.value = openFilter.value == true ? false : true;
    }

    onBeforeMount(async () =>{
      await store.dispatch('setMaterialListCurrent');
      await store.dispatch('setMachineListCurrent');
      await store.dispatch("setClientListCurrent");
      await store.dispatch("getUsers");
    });

    return {
      backupProcess,
      deleteProcessForLote,
      getProcesses,
      openFilter,
      setOpenFilter,
      dateStart,
      dateEnd,
      processes,
      formatDate,
      loadProcess,
      clickFilter,
      timeMsg,
      countDelete
    };
  },
  components: {
    LoadingObjet
  }
};
</script>

<style scoped>
/* Seu estilo aqui */
</style>
