<template>
  <div class="container text-center">
    <HomeListGeral v-if="user.type_user.includes('A')"/>
    <HomeProcesses v-if="user.type_user !== 'A'"/>
    <ModalStart v-if="(!showModal && user?.id)" :show="!showModal" @update:show="showModal = $event"/>
  </div>
</template>

<script>

import HomeProcesses from '../pages/processes/HomeProcesses.vue'
import HomeListGeral from './admin/Home.vue';
import store from '../store';
import ModalStart from '../components/ModalStart.vue';
import { computed } from 'vue';

export default {
  name: "HomeVue", 
  props: {}, 
  setup() {
    const user = computed(() => store.state.userCurrent);
    const showModal = computed(() => {return sessionStorage.getItem(`modalPoint${user.value.id}`) == 'true' ? true : false});

    return {
      user,
      showModal
    }
  },
  components:{
    HomeProcesses,
    HomeListGeral,
    ModalStart
  }
};
</script>
<style scoped>
/*colocar o estilo da página ou componente*/
</style>
