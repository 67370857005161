<template>
    <div class="container-fluid  text">
        <div class="ps-2 pe-2 mb-3">
            <label for="name" class="form-label"
              >Nome do Material</label
            >
            <input
              v-model="material"
              type="text"
              class="form-control"
              id="name"
              required
              placeholder="Nome do material"
            />
            <p class="p-error-label">{{ errorLabel?.material || errorLabel?.error }}</p>
        </div>

        <div class="d-flex justify-content-center mb-2">
            <button 
                class="btn btn-outline-dark me-1 col-6" 
                @click="registerMaterial"
                :disabled="!material ? true : false"
            >
              Cadastrar
            </button>
            <button 
              class="btn btn-outline-danger ms-1 col-6" 
              @click="openCreateMaterial"
              id="closeButtonAdd"
              data-bs-dismiss="modal" 
              aria-label="Close"
            >
              Cancelar
            </button>
        </div> 
          
    </div>
</template>

<script>
import { ref } from 'vue';
import store from "../../store";
import { createMaterial } from "../../http"
import { sendEventMaterial } from "../../socket";

export default {
setup() {

  const  material = ref('');
  const errorLabel = ref({
    material: null,
    error: null
  })
  const openCreateMaterial = () => {
    store.commit('setOpenCreateMaterial');
  }

  const registerMaterial = async () => {
    if(material.value){
      await createMaterial({name: material.value})
        .then((data)=>{
          if(data?.message){
            errorLabel.value.error = data?.message;
            return;
          }
          store.dispatch('setMaterialList', data);
          sendEventMaterial('graphic', data);
          material.value = '';
          document.getElementById("closeButtonAdd").click();
        });
    }else{
      errorLabel.value.material = "Campo obrigatório.";
    }
  }

  return {
      openCreateMaterial,
      material,
      registerMaterial
  };
}
};
</script>

<style>
.p-error-label {
  font-size: 14px;
  color: red;
}
</style>
