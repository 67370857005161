<template>

  <div>
    <div v-for="i in [1,2,3,4,5]" :key="i" style="display: inline-block; margin-right: 5px;">
      <div class="spinner-grow text-secondary " role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  

</template>
<script>
export default {
    
}
</script>