<template>
  <div>
    <div class="container custom-mt">
      <div class="card mx-auto border-0 shadow-lg" style="max-width: 400px">
        <div class="card-header bg-transparent border-0"></div>
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120
            "
          />
        </div>
        <div class="card-body">
          <div class="text-center">
            <label for="username" class="form-label"
              >Insira o token para continuar</label
            >
            <input
              type="text"
              v-model="token"
              class="form-control"
              id="username"
              placeholder=""
              required
              @keydown.enter="getToken"
            />
          </div>
          <p class="errorLabel">{{ errorLabel.token }}</p>
          <div class="d-grid gap-2 mt-3">
            <button class="btn btn-dark" @click="getToken">Resgatar</button>
          </div>

          <div class="d-grid gap-2">
            <button
              class="btn btn-outline-danger mb-4 mb-2 mt-2"
              @click="blackLogin"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
              Voltar ao Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import store from "../../store";
export default {
  name: "CheckTokenUser",
  props: {},
  setup() {
    const token = ref(null);
    const errorLabel = ref({
      token: null,
    });
    const router = useRouter();

    const blackLogin = () => {
      router.push("/");
    };

    const getToken = async () => {
      await store.dispatch("tokenRegister", token.value).then((data) => {
        if (!data?.permission) {
          errorLabel.value.token = data?.message;
        }
      });
    };

    return {
      errorLabel,
      token,
      blackLogin,
      getToken,
    };
  },
};
</script>
<style scoped>
/*colocar o estilo da página ou componente*/

label,
button {
  font-family: Arial, Helvetica, sans-serif;
}

.custom-mt {
  margin-top: 150px;
  /* Ajuste a margem superior conforme necessário */
}
.errorLabel {
  font-size: 14px;
  color: red;
}
</style>
