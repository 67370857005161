<template>
    <div class="container mt-4 mb-5">
      <div class="card mx-auto border-0 shadow-lg" style="max-width: 500px">
        <div class="card-header bg-transparent border-0 ms-2">
          <div>
            <img
              src="../../assets/logo.png"
              alt="Logomarca"
              class="img-fluid mx-auto d-block mt-4"
              width="120
                "
            />
          </div>
        </div>
        <div class="card-body">
          <div class="text-white error-label text-center" v-if="timeError">
            <p class="">{{ errorLabel.errorGeral }}</p>
          </div>
          <!-- <form> -->
          <div class="mb-2 ps-2 pe-2">
            <label for="nome" class="form-label">Nome</label>
            <input
              v-model="client.name"
              type="text"
              class="form-control"
              id="nome"
              required
            />
            <p class="p-error-label">{{ errorLabel.name }}</p>
          </div>
  
          <div class="mb-3 ps-2 pe-2">
            <label for="telefone" class="form-label">Telefone</label>
            <input
              v-mask="'(##) #####-####'"
              v-model="client.fone"
              type="text"
              class="form-control"
              id="telefone"
              required
              placeholder="(00) 00000-0000"
            />
            <p class="p-error-label">{{ errorLabel.fone }}</p>
          </div>

          <div class="d-grid gap-2">
            <button class="btn btn-primary ms-2 me-2" @click="editClients">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"
                />
              </svg>
              Confirmar
            </button>
            <button class="btn btn-outline-danger mb-4 ms-2 me-2" @click="back">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
              Voltar
            </button>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { onBeforeMount, ref } from "vue";
  import { useRoute } from "vue-router";
  import { getClient, editClient } from "../../http";
  import store from "../../store";

  export default {
    name: "EditClient",
    props: {},
    setup() {
      const route = useRoute();
      const clientId = ref(route.params.id || null);
      const client = ref({});
  
      const errorLabel = ref({
        name: null,
        email: null,
        newPassword: null,
        confirmPassword: null,
        errorGeral: null,
      });

      const back = () => {
        store.commit("setOpenEditClient");
      };
  
      const timeError = ref(false);
  
      const validateForm = () => {
        errorLabel.value.name = null;
        errorLabel.value.fone = null;
        errorLabel.value.errorGeral = null;
  
        if (
          !client.value?.name ||
          (client.value?.name.length < 3 && client.value?.name.length < 100)
        ) {
          errorLabel.value.name =
            "O nome, tem que ter no minimo 3 caractere e no máximo 100";
        }
  
        if (!client.value?.fone) {
          errorLabel.value.email = "Campo obrigatório.";
        }
  
        if (
          errorLabel.value.name ||
          errorLabel.value.email 
        ) {
          return true;
        }
        return false;
      };
  
      const editClients = async () => {

        if (validateForm()) {
          return;
        }
  
        const requestEdit = await editClient( client.value.id, client.value );
  
        if (!requestEdit?.status) {
          errorLabel.value.errorGeral = requestEdit?.message;
          timeError.value = true;
          setTimeout(() => {
            timeError.value = false;
          }, 5000);
          return;
        }
  
        location.reload();
      };
      
      onBeforeMount(async () => {
        if (clientId.value) {
            await getClient(clientId.value) 
            .then( (data) => {
                if(!data?.message){
                client.value = data;
                }
            })
        }
        });

      return {
        back,
        client,
        editClients,
        errorLabel,
        timeError,
      };
    },
  };
  </script>
  <style scoped>
  /*colocar o estilo da página ou componente*/
  h1 {
    font-weight: bold;
  }
  h1,
  label,
  button {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .custom-mt {
    margin-top: 40px;
  }
  
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .error-label {
    background-color: #be3f3f;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .p-error-label {
    font-size: 14px;
    color: red;
  }
  </style>
  