<template>

    <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalToggleLabel">{{titleModal($_store.state.typeEventMaterial.type, $_store.state.typeEventMaterial.material) }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <EditDeleteMaterial v-if="$_store.state.typeEventMaterial.type === 'E' || $_store.state.typeEventMaterial.type === 'D'"/>
              <MaterialCreate v-if="$_store.state.typeEventMaterial.type === 'A'"/>
            </div>
          </div>
        </div>
    </div>
    
    <div class="container mt-5" >    
        <div class="card-header bg-transparent border-0 ms-2">
            <div>
                <img
                    src="../../assets/logo.png"
                    alt="Logomarca"
                    class="img-fluid mx-auto d-block mt-4"
                    width="120"
                />
            </div>
            <h4 class="text-center my-3">
              <font-awesome-icon icon="print" />
                Materiais Registrados
            </h4>
            <hr />
        </div>
        
        <div v-if="materials?.length === 0" class="alert alert-light text-center" role="alert">
            <h5 class="custom-mt text-center mb-5" >
                <font-awesome-icon icon="circle-exclamation" />
                Atualmente, não há materiais disponíveis.
            </h5>
        </div>
        
        <div class="">
          <button
            class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb me-3"
            @click="opeBlockFilter"
            v-if="materials?.length > 0"
          >
              <font-awesome-icon
              icon="arrow-up-wide-short"
              class="me-2"
              v-if="openFilter"
              />
              <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
              Filtrar
          </button>
          <a
            class="custom-link d-flex justify-content-start mb-2 col-lg-2 col-sm-5 col-md-4"
            @click="setOpenAdd"
            data-bs-toggle="modal" 
            href="#exampleModalToggle" 
            role="button"
          >
            + Cadastre um novo material
          </a>
        </div>  
        <!-- Filtros de pesquisa -->
          <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
            <div class="col-md-3">
                <label for="filtroNomeMaterial" class="form-label filtros">Nome do material:</label>
                <input
                    id="filtroNomeMaterial"
                    class="form-control"
                    placeholder="Digite o material"
                    v-model="nameMaterial"
                />
            </div>
            <!-- Adicione mais filtros aqui, se necessário -->
          </div>
  
          <table
               v-if="materials?.length > 0"
              class="text-center table table-dark table-striped mb-5 custom-table"
          >
              <thead>
                  <tr>
                      <th>Código do Material</th>
                      <th>Nome do Material</th>
                      <th>Data de Criação</th>
                      <th v-if="$_store.state.userCurrent.type_user === 'A'">Editar</th>
                      <th v-if="$_store.state.userCurrent.type_user === 'A'">Remover</th>
                  </tr>
              </thead>
              <tbody>
                  <!--  @click="viewMaterial(item.id) -->
                  <template v-for="item in ordeName(filteredMaterials)" :key="item.id">
                      <tr class="align-items-center">
                            <td>{{ '00' + item.id }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ convertDate(item.createdAt) }}</td>
                            <td class="clickable text-primary" v-if="$_store.state.userCurrent.type_user === 'A'">
                                <font-awesome-icon 
                                    icon="pencil-alt"
                                    data-bs-toggle="modal" 
                                    href="#exampleModalToggle" 
                                    role="button"
                                    @click="setOpenEdit(item)"
                                />
                            </td>
                            <td class="clickable text-danger" v-if="$_store.state.userCurrent.type_user === 'A'">
                                <font-awesome-icon 
                                    icon="trash-can" 
                                    data-bs-toggle="modal" 
                                    href="#exampleModalToggle" 
                                    role="button" 
                                    @click="setOpenRemove(item.id)"
                                />
                            </td>
                      </tr>
                  </template>
              </tbody>
          </table>
  
          <div>
              <h6 class="text-secondary custom-mt text-center"
                  v-if="!filteredMaterials.length && nameMaterial"
              >
                  Desculpe, não encontramos resultados correspondentes à sua pesquisa.
                  <br />
                  Tente usar termos diferentes ou ajustar os filtros para obter melhores resultados.
              </h6>
          </div>
    </div>
  </template>
  
  
<script>
  import { computed, onBeforeMount, ref } from 'vue';
  import store from "../../store";
  import { convertDate, ordeName } from "../../utils";
  import EditDeleteMaterial from '../material/EditDeleteMaterial';
  import MaterialCreate from "../material/CreateMaterial.vue";

  export default {
    name: 'ListMaterial',
    setup(){
      
      const materials = computed(() => store.state.materialList);
      const nameMaterial = ref('');
  
      const filteredMaterials = computed(() => {
        const _nameMaterial = nameMaterial.value.toLowerCase();
  
        return materials.value?.filter(
          (material) =>
            material.name?.toLowerCase().includes(_nameMaterial)
        );
      });

      const setOpenAdd = () => {
        store.commit('setTypeEventMaterial', {type: 'A'});
      }

      const setOpenRemove = (id) => {
        store.commit('setTypeEventMaterial', {type: 'D', material: id});
      }

      const setOpenEdit = ({id, name}) => {
        store.commit('setTypeEventMaterial', {type: 'E', material: id, nameMaterial: name});
      }

      const openFilter = ref(false);
      const opeBlockFilter = () => {
        openFilter.value = openFilter.value ? false : true;
      };
      
      const titleModal = (type, id=0) => {
        switch(type){
          case 'A':
            return 'Cadastrar Material';
          case 'E':
            return 'Editar Material #' + id;
          case 'D': 
            return 'Remover Material  #' + id;
        }
      }
      onBeforeMount(async () =>{
          await store.dispatch('setMaterialListCurrent');
      });
  
      return {
        ordeName,
        titleModal,
        setOpenAdd,
        setOpenRemove,
        setOpenEdit,
        materials,
        opeBlockFilter,
        openFilter,
        filteredMaterials,
        convertDate,
        nameMaterial
      }
    },
    components: {
        EditDeleteMaterial,
        MaterialCreate,
    }
  }
  </script>
  <style scoped>
  .custom-mt {
    margin-top: 70px;
  }
  
  .custom-table {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 80px;
  }
  
  .barra-pesquisa {
    margin-top: 100px;
  }
  
  .filtros {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .custom-link {
    cursor: pointer;
    font-size: 15px;
    color: gray;
    font-weight: bold;
    text-decoration: none; /* Remove sublinhado */
    transition: color 0.3s, background-color 0.3s; /* Transição de cor e fundo */
  }
  
  .custom-link:hover {
    color: #020202; 
    font-weight: bold;
    border-bottom: #020202 solid 2px; 
    border-radius: 8px;
  }

  .clickable {
    cursor: pointer;
  }
  .clickable :hover {
    width: 23px;
    height: 23px;
    color: #fff;
  }
  </style>