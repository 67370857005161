<template>

  <div class="container custom-mt" v-if="!$_store.state.openEclient && ($_store.state.userCurrent.type_user.includes('A')  || $_store.state.userCurrent.type_user === 'D')">
    <div class="card mx-auto border-0 shadow-lg" style="max-width: 600px">
      <div class="card-header bg-transparent border-0 ms-2">
        <div>
          <img
            src="../../assets/logo.png"
            alt="Logomarca"
            class="img-fluid mx-auto d-block mt-4"
            width="120"
          />
        </div>
      </div>
      <div class="card-body">
        <h4 class="text-center">Informações do cliente</h4>
        <hr />
        <div class="mb-4 ps-2 pe-2">
          <b class="">Nome do cliente: </b>{{ client?.name }}
        </div>

        <div class="mb-4 ps-2 pe-2">
          <b class="">Telefone do cliente: </b>{{ formatFone(client?.fone) }}
        </div>

        <hr />

        <div class="row">
          <div class="col-md-6">
            <div class="">
              <div class="mb-4 ps-2 pe-2">
                <b class="">Cadastrado em: </b>
                <br />
                {{ convertDate(client?.createdAt) }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="">
              <div class="mb-4 ps-2 pe-2">
                <b class="">Última atualização em: </b>
                <br />
                {{ convertDate(client?.updatedAt) }}
              </div>
            </div>
          </div>
        </div>

        <div>
          <router-link
            :to="`${$_store.state.pagePrevious || '/'}`"
            class="btn btn-outline-danger btn-sm me-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
            Voltar
          </router-link>

          <button
            v-if="!openConfirm && $_store.state.userCurrent.type_user === 'A'"
            type="button"
            class="btn btn-primary btn-sm me-2"
            @click="openEdit"
          >
            <font-awesome-icon icon="pencil" />
            Editar
          </button>

          <button
            v-if="!openConfirm && $_store.state.userCurrent.type_user === 'A'"
            type="button"
            class="btn btn-danger btn-sm me-2"
            @click="openConfirmDelete"
          >
            <font-awesome-icon icon="trash-can" />
            Apagar
          </button>
          <!-- alerta -->
          <div class="card text-center mt-2" v-if="openConfirm">
            <span class="mt-2"
              >Tem certeza que deseja apagar este registro?</span
            >
            <hr />

            <div class="mb-3">
              <button
                class="btn btn-danger btn-dm me-2"
                @click="openConfirmDelete"
              >
                Não
              </button>

              <button
                type="button"
                class="btn btn-primary btn-dm me-2"
                @click="deleteRegister"
              >
                Sim
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditClient v-if="$_store.state.openEclient"/>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import { useRoute } from "vue-router";
import router from "@/router";
import { getClient, deleteClient} from "../../http";
import { formatFone, convertDate } from "../../utils";
import store from '../../store';
import EditClient from './EditClient.vue';

export default {
  name: "ViewClient", // nome da pagina ou componente
  props: {}, // não vai usar no momento
  setup() {
    const route = useRoute();
    const clientId = ref(route.params?.id || null);
    const client = ref({});

    const openEdit = () => {
      store.commit("setOpenEditClient");
    }
    const openConfirm = ref(false);

    const openConfirmDelete =  () => {
      openConfirm.value = openConfirm.value ?  false : true;
    }

    const  deleteRegister = async () => {
      await deleteClient(client.value.id)
        .then(()=>{
          const directPath = `${store.state.pagePrevious || "/"}`
          router.push(directPath);
      });
    }

    onBeforeMount(async () => {
      if (clientId.value) {
        await getClient(clientId.value) 
          .then( (data) => {
            if(!data?.message){
              client.value = data;
            }
          })
      }
    });

    return {
      client,
      convertDate,
      formatFone,
      openEdit,
      openConfirm,
      openConfirmDelete,
      deleteRegister,
    }
  },
  components:{
    EditClient
  }
};
</script>
<style scoped>
/*colocar o estilo da página ou componente*/
label {
  font-family: Arial, Helvetica, sans-serif;
}

p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-style: italic;
  font-size: large;
}

.custom-mt {
  margin-top: 100px;
  /* Ajuste a margem superior conforme necessário */
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
