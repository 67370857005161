<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-content">
      <div class="alert alert-warning alert-dismissible fade show" role="alert">
         Você precisa bater o ponto em {{ minutes }} minutos. Por favor, certifique-se de registrar seu horário corretamente.
        <button type="button" class="btn-close" aria-label="Close" @click="visible = false"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    minutes: {
      type: Number,
      required: true
    }
  },  
  name: 'CustomAlert',
  setup() {
    const visible = ref(true);
    const show = () => {
      visible.value = true;
    };

    return { visible, show };
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fundo opaco */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Abaixo do modal do Bootstrap que é 1060 */
}

.modal-content {
  padding: 20px;
  border-radius: 3px;
  width: 80%;  /* Largura maior */
  max-width: 600px; /* Largura máxima */
  height: 30%;  /* Altura maior */
  max-height: 400px; /* Altura máxima */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* Centralizar o texto */
}

.alert {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
