<template>
  <LoadingObjet v-show="load" class="text-center" style="margin-top: 150px;"/>
  <NotFoundVue v-if="!customerOrder?.length && !load"/>
  <!-- Logo -->
  <div v-if="customerOrder?.length && !load">
    <div>
      <img
        src="../../assets/logo.png"
        alt="Logo"
        class="img-fluid mx-auto d-block mt-4"
        width="120"
      />
    </div>

    <!-- My Orders -->
    <h4 class="text-center mt-4" v-if="customerOrder?.length">
      <font-awesome-icon icon="fa-solid fa-folder-open" /> Meus Pedidos
    </h4>
    <p class="text-center" v-if="customerOrder?.length" >
      Olá <strong>{{ customerOrder }}</strong
      >, Acompanhe seus pedidos abaixo.
    </p>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <!-- Added modal-lg class to increase modal size -->
        <div class="modal-content bg-dark">
          <div class="modal-header text-center">
            <h5 class="modal-title text-light ms-4" id="exampleModalLabel">
              Detalhe do Pedido
              <span class="fs-4 fw-bold"> #{{ orderSelected.id + 1000}}</span>
            </h5>
            <button
              type="button"
              class="btn-close bg-light"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-light">
            <div class="container-fluid">
              <div class="row">
                <!-- List -->
                <div class="col-md-7">
                  <div>
                    <h6 class="text-dark badge badge bg-light">Pedido:</h6>
                    <p>{{ getMaterial(orderSelected) }}</p>
                  </div>
                  <div>
                    <h6 class="text-dark badge badge bg-light">Data:</h6>
                    <p>{{convertDate(orderSelected.createdAt)}}</p>
                  </div>
                  <div>
                    <h6 class="text-dark badge badge bg-light">Imagem:</h6>
                    <div class="mb-4">
                      <img
                        :src="selectedImg"
                        alt="Image"
                        class="img-fluid image-size"
                      />
                    </div>
                  </div>
                </div>
                <!-- Timeline -->
                <div class="col-md-5">
                  <h6 class="text-dark badge bg-light mb-4">Progresso:</h6>
                  <div class="timeline mt-2 me-4">
                    <div
                      v-for="(step, index) in steps"
                      :key="step.id"
                      class="timeline-item"
                    >
                      <div class="timeline-content-wrapper">
                        <div
                          class="timeline-icon"
                          :style="{
                            backgroundColor: generateColor(step.id, index, steps.length),
                            fontSize: '16px',
                          }"
                        >
                          <!-- Reduced icon size -->
                          <i class="fas" :class="step.icon"></i>
                        </div>
                        <div class="timeline-content">
                          <p>{{ step.title }}</p>
                        </div>
                      </div>
                      <div
                        v-if="index !== steps.length - 1"
                        class="connector"
                      ></div>
                      <!-- Reduced margin between elements -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!load && !orders?.length" class="alert alert-light container mt-5">
      <h6
        class="text-secondary custom-mt text-center"
      >
        Atualmente, não há pedido pendente!
      </h6>
    </div>
    <!-- Table Start -->
    <div class="table-container" v-if="orders?.length">
      <table class="table table-dark text-center table-striped custom-table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Data</th>
            <th scope="col">Pedido</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(order, index) in sortProcess(orders)" :key="index">
            <td>
              <span class="badge badge-sm rounded-pill bg-secondary">{{
                order.id + 1000
              }}</span>
            </td>
            <td>{{ convertDate(order.createdAt) }}</td>
            <td>{{ getMaterial(order) }}</td>
            <td>{{ stateFormat(order) }}</td>
            <td>
              <a
                href="#"
                class="details-link"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                @click="showOrderDetails(order)"
                ><font-awesome-icon icon="fa-solid fa-table-list" />
                <strong> Detalhes</strong></a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { computed } from 'vue';
import { onBeforeMount, ref } from 'vue';
import { useRoute } from "vue-router";
import { convertDate, getMaterialInList, getImgBase64, getUUID } from "@/utils";
import { getClient } from '@/http';
import NotFoundVue from '../NotFound.vue';
import LoadingObjet from '@/components/LoadingObjet.vue';
export default {
  setup(){
    const route = useRoute();
    const clientId = ref(route.params.client || null);
    const selectedOrderId = ref(null);
    const orders = computed(() => store.state.listProcessesOrders || []);
    const orderSelected = ref({});
    const materials = computed(() => store.state.materialListView);
    const customerOrder = ref('')
    const load = ref(false)
    const sortProcess = (arr = []) => {
      return arr?.sort((a, b) => {
        if (a.finished === 1 && b.finished !== 1) {
          return 1;
        }
        if (a.finished !== 1 && b.finished === 1) {
          return -1;
        }

        return b.id - a.id;
      });
    };

    const stateFormat = ({ stage, finished }) => {
      if (finished == 1) {
        return "Concluído";
      }

      switch (stage) {
        case "D":
          return "Entrada";
        case "I":
          return "Conferente";
        case "C":
          return "Concluído";
        case "P":
          return "Produção";
        case "A":
          return "Acabamento";
        case "E":
          return "Aguardando Entrega";
      }
    };

    const getMaterial = (item) => {
      return getMaterialInList(materials.value, item);
    }

    const steps = ref([
      { id: 1, title: "Entrada", icon: "fa-sign-in-alt" },
      { id: 2, title: "Produção", icon: "fa-cog" },
      { id: 3, title: "Conferente", icon: "fa-check-square" },
      { id: 4, title: "Acabamento", icon: "fa-paint-brush" },
      { id: 5, title: "Aguardando_Entrega", icon: "fa-truck" },
      { id: 6, title: "Concluído", icon: "fa-check-circle" },
    ]);

    const generateColor = (id, index, totalSteps) => {
      const hue = (index / totalSteps) * 124;
      const order = orderSelected.value;
      const color = `hsl(${hue}, 100%, 50%)`;

      if (id === 1) {
        return color;
      }
      if (id === 2 && order.stage !== 'D' && (order.stage === 'P' || (order.printer || order.lecturer))) {
        return color;
      }
      if (id === 3 && order.stage !== 'D' &&(order.stage === 'I' || ((order.printer && order.stage !== 'P') || order.lecturer))) {
        return color;
      }
      if (id === 4 && order.stage !== 'D' && (order.stage === 'A' || ((order.printer && order?.finishing?.length > 0 ) || order.lecturer || order.stage === 'E'))) {
        return color;
      }
      if (id === 5 && order.stage !== 'D' && (order.stage === 'E' || (order.printer && order.lecturer))) {
        return color;
      }
      if (id === 6 && order.stage !== 'D' && order.stage === 'C') {
        return color;
      }
      return `hsl(${hue}, 0%, 50%)`;
    }

    const showOrderDetails = async (order) => {
      orderSelected.value = order;
      await renderImg(order.id);
    }
    
    const selectedImg = ref("");
    const renderImg = async (id) => {
      selectedImg.value = "";
      selectedImg.value = await getImgBase64(id);
    };

    onBeforeMount(async ()=>{
      load.value = true;
      let listProcesses = await store.dispatch("getListProcessesOrders", getUUID(clientId.value));

      if(!listProcesses?.message){
        const materialIds = listProcesses.map((item)=> item.material) || [];   
        await store.dispatch('getMaterialListView', materialIds);
      }
      await getClient(getUUID(clientId.value)).then((data)=>{customerOrder.value = data?.name || '' });
      load.value = false;
    });

    return{
      steps,
      customerOrder,
      orders,
      selectedOrderId,
      showOrderDetails,
      generateColor,
      stateFormat,
      convertDate,
      getMaterial,
      sortProcess,
      orderSelected,
      selectedImg,
      load
    }

  },
  components:{
    NotFoundVue,
    LoadingObjet,
  }
};

</script>

<style scoped>
.table-container {
  display: flex;
  justify-content: center;
}

.table {
  max-width: 50%;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 80px;
}

.timeline {
  position: relative;
  padding: 0;
  right: 45%;
}

.timeline:before {
  content: "";
  position: absolute;
  top: 5%;
  bottom: 0;
  left: 50%;
  width: 2px;
  background-color: #ccc;
  margin-left: -1px;
}

.timeline-item {
  position: relative;
  margin-bottom: 25px;
  text-align: center; /* Alinha o conteúdo no centro */
}

.timeline-content-wrapper {
  position: relative;
  display: inline-block; /* Para manter o conteúdo do timeline-item em linha */
}

.timeline-icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  z-index: 100;
  border: 2px double #cccccc; /* Adiciona uma borda ao ícone */
}

.timeline-content {
  font-weight: lighter;
  margin-left: 20px;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  top: calc(100% + -12.5px);
  left: 50%;
  transform: translateX(-50%);
  width: 200%;
}

.details-link {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.details-link:hover {
  color: #6c757d;
}

.image-size {
  max-width: 85%; 
  max-height: 170px; 
  margin-top: 10px;
}
</style>