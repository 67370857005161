<template>
<div class="modal fade" 
        id="exampleModalToggle" 
        aria-hidden="true" 
        aria-labelledby="exampleModalToggleLabel" 
        tabindex="-1"
        style="max-height: 700px;"
    >
        <div :class="'modal-dialog modal-dialog-xl'">
          <div class="modal-content">
            <div class="modal-header">
              <h5 
                class="modal-title" 
                id="exampleModalToggleLabel">
                <b>
                <span :class="'text-' + ($_store.state.typeEventPoint.type == 'E' ? 'success' : 'danger')">{{$_store.state.typeEventPoint.type == "E"? "Editar"  : "Remover"}}</span> 
                <span> Ponto</span> do
                <span style="border-bottom: 2px solid black;display: inline-block;">{{getNameUser($_store.state.typeEventPoint.point.user)}}</span> na data
                <span class="text-success">({{convertDate($_store.state.typeEventPoint.point.createdAt).slice(0, 10)}})</span>
                </b>
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <EditOrDeletePoint/>
            </div>
          </div>
        </div>
    </div>


  <div class="container-fluid">

    <div class="card-header bg-transparent border-0 ms-2" >
      <div>
        <img
          src="@/assets/logo.png"
          alt="Logomarca"
          class="img-fluid mx-auto d-block mt-4"
          width="120"
        />
      </div>
      <h4 class="text-center my-3">
        <font-awesome-icon icon="users" />
        Estatísticas de Produção
      </h4>
      <hr class="mb-4"/>
    </div>

    <button @click="showBarVue = !showBarVue" id='charbar' hidden></button>
    <button @click="showPieVue = !showPieVue" id='charpie' hidden></button>

    <LoadingObjet class="text-center" v-show="loaded" />

    <div class="container" v-if="!loaded">
      <div class="row">

        <div class="col-6">
          <LoadingObjet class="text-center mt-5" v-show="!showBarVue" />
          <ChartBar 
            v-if="showBarVue" 
            :processesList="processesList" 
            :date="dateFilter"
            :users="users"
            :type="typeUser"
            @filters="getValueFilter"
          />
        </div>

        <div class="col-6">
          <LoadingObjet class="text-center mt-5" v-show="!showPieVue" />
          <ChartDoughnut 
            v-if="showPieVue" 
            :processesList="processesListPie" 
            :date="dateFilterPie"
            @filters="getValueFilterPie"
          />
        </div>

        <div class="col-6 text-center mt-5">
          <h6>Uso de Materiais em: {{ dateConvert(dateFilterMaterial) }}</h6>
          <div class="d-flex justify-content-between align-items-center mb-2 me-3">
            <h6 class="m-0">Gasto total diário: <span class="fw-bold fs-5">{{ calGeralMateria() }}m²</span></h6>
            <button
              class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto"
              @click="opeBlockFilter"
            >
              <font-awesome-icon
                icon="arrow-up-wide-short"
                class="me-2"
                v-if="openFilter"
              />
              <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
              Filtrar
            </button>
          </div>
          
           <!-- Filtros de pesquisa -->
          <div class="row mb-3 d-flex justify-content-end" v-if="openFilter">
            <div class="col-md-4">
              <label for="filtroData" class="form-label filtros">Data Inicial:</label>
              <input
                id="filtroData"
                type="date"
                class="form-control"
                value="2024-01-01"
                v-model="dateFilterMaterialDateStart"
              />
            </div>
            <div class="col-md-4">
              <label for="filtroData" class="form-label filtros">Data Final:</label>
              <input
                id="filtroData"
                type="date"
                class="form-control"
                value="2024-01-01"
                v-model="dateFilterMaterialDateEnd"
              />
            </div>
          </div>  
          <h6 v-if="processListMaterial?.length == 0 && dateFilterMaterialDateStart && dateFilterMaterialDateEnd && !loadMaterial" 
            class="text-secondary text-center"
            style="margin-top: 25px;"
          >
            Não encontramos resultados correspondentes à essa data.
            <br />
            Tente ajustar os filtros para obter melhores resultados.
          </h6>

          <LoadingObjet class="text-center mt-5" v-show="loadMaterial" />
          <div class="table-responsive overflow-custom" style="height: 300px;" v-if="processListMaterial?.length && !loadMaterial">
            <table
              className="table table-dark table-striped custom-table"
              v-if="processListMaterial"
            >
              <thead>
                <tr>
                  <th scope="col">Material</th>
                  <th scope="col">Total de Serviço</th>
                  <th scope="col">Gasto diário m²</th>
                </tr>
              </thead>
              <tbody :style="'overflow-y: auto;'">
                <template
                  v-for="item in removeObjectsWithCommonMaterial(processListMaterial)"
                  :key="item.id"
                >
                  <tr class="align-items-center">
                    <td class="col-auto clickable">
                      {{ getMaterial(item.material) }}
                    </td>
                    <td class="col-auto clickable">
                      {{ totalService(item.material) }}
                    </td>
                    <td class="col-auto clickable">
                      {{ calMaterial(item.material) }} m²
                    </td> 
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          
        </div>
        <div class="col-6 text-center mt-5">
          <h6>Perca de materiais em: {{ dateConvert(dateFilterMaterial) }}</h6>
          <div class="d-flex justify-content-between align-items-center me-3">
          <h6 class="m-0">Perca total diária: <span class="fw-bold fs-5">{{subTotalOfLackMaterial()}}m²</span></h6>
          <button
            class="btn btn-sm btn-outline-dark d-flex justify-content-end align-items-center ms-auto mb-2 me-3"
            @click="opeBlockFilter2"
          >
            <font-awesome-icon
              icon="arrow-up-wide-short"
              class="me-2"
              v-if="openFilter2"
            />
            <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
            Filtrar
          </button>
          </div>
        
           <!-- Filtros de pesquisa -->
          <div class="row mb-3 d-flex justify-content-end" v-if="openFilter2">
            <div class="col-md-4">
          <label for="filtroData" class="form-label filtros">Data Inicial:</label>
          <input
            id="filtroData"
            type="date"
            class="form-control"
            v-model="dateFilterLackMaterialDateStart"
          />
        </div>
        <div class="col-md-4">
          <label for="filtroData" class="form-label filtros">Data Final:</label>
          <input
            id="filtroData"
            type="date"
            class="form-control"
            v-model="dateFilterLackMaterialDateEnd"
          />
        </div>
          </div>  
          <h6 v-if="lackMaterials?.length == 0 && dateFilterLackMaterialDateStart && dateFilterLackMaterialDateEnd  && !loadLack"
            class="text-secondary text-center"
            style="margin-top: 25px;"
          >
            Não encontramos resultados correspondentes à essa data.
            <br />
            Tente ajustar os filtros para obter melhores resultados.
          </h6>

          <LoadingObjet class="text-center mt-5" v-show="loadLack" />
          <div class="table-responsive mb-5 overflow-custom" style="height: 300px;" v-if="lackMaterials?.length && !loadLack">
            <table
              className="table table-dark table-striped custom-table"
              v-if="lackMaterials"
            >
              <thead>
                <tr>
                  <th scope="col">Material</th>
                  <th scope="col">Perca diária m²</th>
                  <th>Remover</th>
                </tr>
              </thead>
              <tbody :style="'overflow-y: auto;'">
                <template
                  v-for="item in lackMaterials"
                  :key="item.id"
                >
                  <tr class="align-items-center">
                    <td class="col-auto clickable">
                      {{item.nameMaterial}}
                    </td>
                    <td class="col-auto clickable">
                      {{(item.width * item.height).toFixed(2)}} m²
                    </td>
                    <td class="clickable text-danger" v-if="$_store.state.userCurrent.type_user === 'A'">
                        <font-awesome-icon
                            icon="trash-can" 
                            @click="setOpenRemove(item.id)"
                        />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <hr class="mt-4"/>
      </div>

        <!-- Botões e Filtros -->
      <div class="row justify-content-center mt-5 mb-5">
        <div class="col-12 text-center">
          <h6>Horário dos funcionários: {{ dateConvert(dateFilterPoints) }}</h6>
          <div class="d-flex flex-row justify-content-between align-items-center">
            <p class="d-flex align-items-center ms-2 mb-2">
              {{ filteredStockHistory?.length > 0 ? filteredStockHistory?.length + ' resultado encontrado.' : '' }}
            </p>
            <button
              class="btn btn-sm btn-outline-dark d-flex align-items-center mb-2 me-3"
              @click="opeBlockFilter"
            >
              <font-awesome-icon
                icon="arrow-up-wide-short"
                class="me-2"
                v-if="openFilter"
              />
              <font-awesome-icon icon="arrow-down-wide-short" class="me-2" v-else />
              Filtrar
            </button>
          </div>
        
          <!-- Filtros de pesquisa -->
          <div class="row mb-4 d-flex justify-content-end" v-if="openFilter">
            <div class="col-md-3">
              <label for="filtroNomeFuncionario" class="form-label filtros">Nome do Funcionário:</label>
              <input
                id="filtroNomeFuncionario"
                class="form-control"
                placeholder="Digite o Nome"
                v-model="nameFuncionario"
              />
            </div>
            <div class="col-md-3">
              <label for="filtroData" class="form-label filtros">Data:</label>
              <input
                id="filtroData"
                type="date"
                class="form-control"
                value="2024-01-01"
                v-model="dateFilterPoints"
              />
            </div>
          </div>

          <!-- Tabela -->
          <LoadingObjet class="text-center mt-5" v-show="loadPoint" />
          <h6 v-if="points?.length == 0 && dateFilterPoints && !loadPoint"
            class="text-secondary text-center mb-5"
            style="margin-top: 25px;"
          >
            Não encontramos resultados correspondentes à essa data.
            <br />
            Tente ajustar os filtros para obter melhores resultados.
          </h6>
          <div class="table-responsive mt-4 custom-table" v-if="!loadPoint && points.length > 0">
            <table class="text-center table table-dark">
              <thead>
                <tr>
                  <th>Funcionário</th>
                  <th>Data</th>
                  <th>Entrada Manhã</th>
                  <th>Saída Manhã</th>
                  <th>Entrada Tarde</th>
                  <th>Saída Tarde</th>
                  <th>Editar</th>
                  <th>Remover</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in points" :key="item.id">
                  <tr class="align-items-center">
                    <td>{{ item.name }}</td>
                    <td>{{ convertDate(item.createdAt).slice(0, 10) }}</td>
                    <td>{{ item.firstTime || '-'}}</td>
                    <td>{{ item.secondTime || '-'}}</td>
                    <td>{{ item.thirdTime || '-'}}</td>
                    <td>{{ item.fourthTime || '-'}}</td>
                    <td class="clickable text-primary" 
                      v-if="$_store.state.userCurrent.type_user === 'A'"
                      @click="setEditPoint(item)"
                    >
                      <font-awesome-icon 
                          icon="pencil-alt"
                          data-bs-toggle="modal" 
                          href="#exampleModalToggle" 
                          role="button"
                      />
                    </td>
                    <td class="clickable text-danger" 
                      v-if="$_store.state.userCurrent.type_user === 'A'" 
                      @click="setDeletePoint(item)"
                    >
                      <font-awesome-icon
                          icon="trash-can" 
                          data-bs-toggle="modal" 
                          href="#exampleModalToggle"
                          role="button"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import ChartBar from '@/components/charts/ChartBar.vue';
import ChartDoughnut from '@/components/charts/ChartPie.vue';
import LoadingObjet from '@/components/LoadingObjet';
import EditOrDeletePoint from "../point/EditOrDeletePoint.vue";
import { getDateBrazil, getNameObject, convertDate, typeUser as typeUserTable } from '@/utils';
import { getMaterials, getProcesses, getUsers, getAuths, getProcessesForDate, deleteLackMaterial } from '@/http';
import store from "@/store";

export default {
  components: {
    ChartBar, ChartDoughnut, LoadingObjet, EditOrDeletePoint
  },
  setup() {
    const processesList = ref([]);
    const processesListPie = ref([]);
    const processListMaterial = ref([]);
    const authList = ref([]);

    const dateFilter = ref(getDateBrazil());
    const dateFilterPie = ref(getDateBrazil());
    const dateFilterMaterial = ref(getDateBrazil());
    const dateFilterLogin = ref(getDateBrazil());
    const dateFilterPoints = ref(getDateBrazil());

    const dateFilterMaterialDateStart = ref(getDateBrazil());
    const dateFilterMaterialDateEnd = ref(getDateBrazil());
    const dateFilterLackMaterialDateStart = ref(getDateBrazil());
    const dateFilterLackMaterialDateEnd = ref(getDateBrazil());

    const showBarVue = ref(false);
    const showPieVue = ref(false);

    const users = ref([]);
    const usersLogin = ref([]);
    const typeUser = ref('');

    const materialList = ref([]);

    const dateNow = ref(getDateBrazil())
    const loaded = ref(false);
    const loadMaterial = ref(false);
    const loadLogin = ref(false);
    const loadLack = ref(false);
    const loadPoint = ref(false);
    const nameFuncionario = ref("");
    const lackMaterials = computed(() => store.state.lackMaterialList || []);

    const points = computed(() => {
      return store.state.pointsAllList.map(item => {
        return {
          ...item,
          name: getNameUser(item.user)
        };
      });
    });

    const startBar = (type) => {
      document.getElementById("char" + type).click();
    }

    const filterProcess = (processes, typeUser) => {

      if(processes?.length > 0 && typeUser){
        let type_execute = '';
        switch(typeUser){
          case "D":
            type_execute = "designer";
            break;
          case "I":
            type_execute = "printer";
            break;
          case "C":
            type_execute = "lecturer";
            break;
        }

        const userIdsSet = new Set(processes?.map((process) => process[type_execute]));
        const userIds = [...userIdsSet];
        const processesFilter = processes.filter((process) => userIds.includes(process[type_execute]));

        users.value = users.value?.filter((user) => userIds.includes(user.id));
        return processesFilter
      }
    }
    
    const getValueFilter = async (event) => {
      if(event){
        startBar('bar');
        processesList.value = await getProcesses(event?.date);
        users.value = await getUsers();
        if(event?.type?.length > 0){
          processesList.value = filterProcess(processesList.value , event?.type);
        }
        dateFilter.value = event.date;
        typeUser.value= event.type;
        startBar('bar');
      }

    }

    const getValueFilterPie = async (event) => {
      if(event?.date){
        startBar('pie');
        processesListPie.value = await getProcesses(event?.date);
        dateFilterPie.value = event.date;
        startBar('pie');
      }

    }

    const getMaterial = (item) => {
      return getNameObject(materialList.value, item);
    }

    const totalService = (material) => {
      let count = 0;
      if(material && processListMaterial.value?.length > 0){
        for (const process of processListMaterial.value){
          if(process.material == material){
            count += 1;
          }
        }
      }
      return count;
    }

    const roundToTwoDecimalPlaces = (num) =>{
          return Math.round(num * 100) / 100;
      }

    const calMaterial = (material) => {
      let total = 0;

      if(material && processListMaterial.value?.length > 0 ){
        for (const process of processListMaterial.value){
          if(process.material == material){
            total += (process?.height * process?.width) * process?.amount;
          }
        }
      }

      return roundToTwoDecimalPlaces(total);

    }

    const calGeralMateria = () => {
      if(processListMaterial.value?.length > 0 ){
        let total = 0;
        for (let process of processListMaterial.value){
          total += (process?.height * process?.width) * process?.amount;
        }
        return roundToTwoDecimalPlaces(total);

      }
      return '0'
    }

    const removeObjectsWithCommonMaterial = (objectsArray=[]) => {
        let seenMaterials = new Set();
        if(objectsArray.length > 0){
          let uniqueObjects = objectsArray?.filter(obj => {
            if (seenMaterials.has(obj.material)) {
              return false;
            }
            seenMaterials.add(obj.material);
            return true;
          });
        
          return uniqueObjects;
        }
    }
    const openFilter = ref(false);
    const opeBlockFilter = () => {
      openFilter.value = openFilter.value ? false : true;
    };

    const openFilter2 = ref(false);
    const opeBlockFilter2 = () => {
      openFilter2.value = openFilter2.value ? false : true;
    };

    const dateConvert = (date) => {
        if(date){
          return date.slice(8,10)  + "/" + date.slice(5,7) + "/" + date.slice(0,4);
        }
        
        return '-'
    }

    const getInfoUser = (id) => {
      if(id && usersLogin.value?.length > 0){
        return usersLogin.value?.find(user => user.id === id)
      }
      return {};
    }

    const namelogin = ref('');
    const typeuserLogin = ref('');

    const filteredLogin = computed(() => {
      const _nameUser = namelogin.value.toLowerCase();
      const _typeUser = typeuserLogin.value;

      return usersLogin.value?.filter(
        (user) =>
          user.name?.toLowerCase().includes(_nameUser) &&
          (_typeUser === '' || user.type_user === _typeUser)
      );
    });

    const subTotalOfLackMaterial = () => {
      let subTotal = 0;
      for(let lack of lackMaterials.value){
        subTotal += lack?.width * lack?.height
      }
      return subTotal > 0 ? subTotal.toFixed(2) : subTotal;
    }

    const setOpenRemove = async (id) => {
      await deleteLackMaterial(id)
        .then(async () => {
          await store.dispatch("setLackMaterialCurrent", {
            dateStart: dateFilterLackMaterialDateStart.value, 
            dateEnd: dateFilterLackMaterialDateEnd.value
          });
        });
    }

    const getNameUser = (id) => {
      return getNameObject(users.value, id);
    }

    const setEditPoint = (point) => {
      store.commit("setEditOrDeletePoint", {type: "E", point:point})
    }

    const setDeletePoint = (point) => {
      store.commit("setEditOrDeletePoint", {type: "D", point:point})
    }

    watch(dateFilterLogin, async (newVal, oldVal) => {
      loadLogin.value = true;
      if(oldVal != newVal){
        authList.value = await getAuths(newVal);
      }
      loadLogin.value = false;
    });

    watch(filteredLogin, async (filteredUsers) => {
      loadLogin.value = true;
      authList.value = await getAuths(dateFilterLogin.value);
      const newAuthList = [];
      const filteredUserIds = filteredUsers.map(user => user.id);

      for (const auth of authList.value) {
        if (filteredUserIds.includes(auth.user)) {
          newAuthList.push(auth);
        }
      }

      authList.value = newAuthList;
      loadLogin.value = false;
    });

    watch( dateFilterMaterial, async (newVal, oldVal) => {
      loadMaterial.value = true;
      if(newVal !== oldVal){
        processListMaterial.value = await getProcesses(newVal);
      }
      loadMaterial.value = false;
    });

    watch(dateFilterLackMaterialDateStart, async (newVal, oldVal) => {
      loadLack.value = true;
      if(newVal !== oldVal && dateFilterLackMaterialDateEnd.value){
        await store.dispatch("setLackMaterialCurrent", {dateStart: newVal, dateEnd: dateFilterLackMaterialDateEnd.value});
      }
      loadLack.value = false;
    });

    watch(dateFilterLackMaterialDateEnd, async (newVal, oldVal) => {
      loadLack.value = true;
      if(newVal !== oldVal && dateFilterLackMaterialDateStart.value){
        await store.dispatch("setLackMaterialCurrent", {dateStart: dateFilterLackMaterialDateStart.value, dateEnd: newVal});
      }
      loadLack.value = false;
    });

    watch(dateFilterMaterialDateStart, async (newVal, oldVal) => {
      loadMaterial.value = true;
      if(newVal !== oldVal && dateFilterMaterialDateEnd.value){
        processListMaterial.value = await getProcessesForDate( newVal, dateFilterMaterialDateEnd.value);
      }
      loadMaterial.value = false;
    });

    watch(dateFilterMaterialDateEnd, async (newVal, oldVal) => {
      loadMaterial.value = true;
      if(newVal !== oldVal && dateFilterMaterialDateStart.value){
        processListMaterial.value = await getProcessesForDate(dateFilterMaterialDateStart.value, newVal);
      }
      loadMaterial.value = false;
    });

    watch(dateFilterPoints, async (newVal, oldVal) => {
      loadPoint.value = true;
      if(newVal !== oldVal && !nameFuncionario.value ){
        await store.dispatch("setPointAllCurrent", newVal);
      }else if(newVal !== oldVal && nameFuncionario.value){
        await store.dispatch("setPointAllCurrent", newVal);

        const _nameEmploye = nameFuncionario.value.toLowerCase();
        const pointFilter = points.value?.filter(
          (point) => point.name?.toLowerCase().includes(_nameEmploye)
        );

        store.commit("setPointAll", pointFilter);
      }
      loadPoint.value = false;
    });

    watch(nameFuncionario, async (newVal, oldVal) => {
      loadPoint.value = true;
      if(newVal !== oldVal && dateFilterPoints.value){
        await store.dispatch("setPointAllCurrent", dateFilterPoints.value);
        
        const _nameEmploye = newVal.toLowerCase();
        const pointFilter = points.value?.filter(
          (point) => point.name?.toLowerCase().includes(_nameEmploye)
        );

        store.commit("setPointAll", pointFilter);
      }
      loadPoint.value = false;
    });

    onMounted(async () => {
      loaded.value = true;
      const requestProcess = await getProcesses(getDateBrazil())
        .then(res=>{
          if(res?.message) return []
          return res
        });
      
      const requestUsers = await getUsers();

      processesList.value = requestProcess;
      processesListPie.value =  requestProcess;
      processListMaterial.value = requestProcess;

      users.value = requestUsers;
      usersLogin.value = requestUsers;

      await getMaterials().then(response => (materialList.value = response));
      authList.value = await getAuths(getDateBrazil());

      const dates = {
        dateStart: getDateBrazil(), 
        dateEnd: getDateBrazil()
      };

      await store.dispatch("setLackMaterialCurrent", dates);
      await store.dispatch("setPointAllCurrent", getDateBrazil());
      
      startBar('bar');
      startBar('pie');
      loaded.value = false
    });

    return {
      dateFilterPoints,
      subTotalOfLackMaterial,
      opeBlockFilter2,
      openFilter2,
      loadLogin,
      loadPoint,
      loadMaterial,
      loaded,
      typeuserLogin,
      namelogin,
      filteredLogin,
      typeUserTable,
      loadLack,
      typeUser,
      getInfoUser,
      dateFilterLogin,
      convertDate,
      authList,
      dateNow,
      dateConvert,
      dateFilterMaterial,
      opeBlockFilter,
      openFilter,
      getValueFilterPie,
      dateFilterPie,
      processesListPie,
      users,
      getValueFilter,
      processesList,
      dateFilter,
      showBarVue,
      showPieVue,
      removeObjectsWithCommonMaterial,
      calMaterial,
      totalService,
      getMaterial,
      processListMaterial,
      calGeralMateria,
      lackMaterials,
      dateFilterLackMaterialDateStart,
      dateFilterLackMaterialDateEnd,
      dateFilterMaterialDateStart,
      dateFilterMaterialDateEnd,
      setOpenRemove,
      points,
      getNameUser,
      setDeletePoint,
      setEditPoint,
      nameFuncionario
    };
  }
};
</script>
<style scoped>
.custom-table {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 80px;
}

.overflow-custom::-webkit-scrollbar {
  width: 4px; 
  height: 2px;
}

.overflow-custom::-webkit-scrollbar-thumb {
  background-color: #060606; 
  border-radius: 4px; 
}

.overflow-custom::-webkit-scrollbar-track {
  background-color: #8c8a8a; 
}
</style>
