<template>
    <div class="text-center container custom-mt alert alert-light">         
      <img src="../assets/404.png" alt="" width="100" height="100" class=""/>
      <h2 class='text-center m-3'><b>Página Não Encontrada - 404</b></h2> 
    </div>

</template>

<script>
  export default {
  name: 'NotFound',
  props:{},
  components: {
  }
}
</script>
<style scoped>
.custom-mt {
    margin-top:180px;
  }
</style>